import { useHistory } from 'react-router-dom';
import React, { useEffect, useState, useContext } from 'react';

//Assets
// import FullColorBlackText from '../../../../assets/images/FullColorBlackText.svg'

//Services
import walletService from '../../../../../../services/wallet-service';
import optionService from '../../../../../../services/optionService';

//Mui Icons
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckIcon from '@mui/icons-material/Check';

//MuiComponents
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

//Our components
import UmpDatePicker from '../../../../external-dashboard/dash-benefits/ump-date-picker/UmpDatePicker';
import ExistingPaymentMethods from './ExistingPaymentMethods';
import BankInfoForm from './BankInfoForm';
// import LegalDrawer from "./LegalDrawer";
import { thisUserContext } from '../../../../../app-internal/nav-container/NavContainer';
import InfinityLoader from '../../../../../UH-loading-animation/InfinityLoader';

//Our styles
import enrollmentPaymentStyles from '../enrollmentPaymentStyles';
import { set } from 'date-fns';

//Component styles
const componentStyles = {
  titleContainer: {
    paddingLeft: '0px',
    marginTop: '35px',
    minHeight: '45px',
  },
  titleText: { fontSize: '20px', fontWeight: 600, lineHeight: '30px' },
  authStatementSx: {
    lineHeight: '1',
  },
  checkboxSx: {
    padding: '0px',
    marginLeft: '-15px',
    marginRight: '8px',
    color: 'primary.main',
  },
  divider: {
    margin: '0px auto 25px',
    color: 'background.default',
    opacity: 1,
  },
  bankSubmitError: {
    padding: '0px',
    marginBottom: '15px',
  },
};

export default function EnrollmentBankBlock(props) {
  const _thisUser = useContext(thisUserContext);

  //Schedule types

  const schedules = [
    { id: 1, name: 'Monthly' },
    { id: 2, name: 'Semi-Monthly' },
    //{ id: 3, name: 'Annual' }, - this is grandfathered in only, not selectable
  ];

  const accounts = [
    { id: 1, name: 'Checking' },
    { id: 2, name: 'Savings' },
  ];

  //get list of Bank account Types
  const [bankAccountTypes, setBankAccountTypes] = useState([]);
  let getBankAccountTypes = async () => {
    try {
      let accountTypes = await optionService.getBankAccountTypes();
      setBankAccountTypes(accountTypes);
    } catch (error) {
      console.error('error getting bank account types', error);
    }
  };

  useEffect(() => {
    getBankAccountTypes();
  }, []);

  //Local bank state
  const [bank, setBank] = useState(props?.bank);
  useEffect(() => {
    if (props?.bank) {
      setBank(props?.bank);
    } else {
      //default bank structure if no bank exists
      setBank({
        memberId: props?.fullMemberObj?.id,
        paymentFrequencyId: 1,
        bankAccountTypeId: null,
        accountNumber: null,
        routingNumber: null,
        paymentOne: 1, //default to 1st of the month
        paymentTwo: 15, //default to 15th of the month if semi-monthly payments
        bankName: '',
        description: '',
      });
    }
  }, [props?.bank, props?.fullMemberObj?.id]);

  const [routingError, setRoutingError] = useState({
    error: false,
    message: '',
  });

  const routingCheck = (routing) => {
    if (routing.length < 9) {
      setRoutingError({
        ...routingError,
        error: true,
        message: 'Please enter a 9-digit number.',
      });
      setRoutingBankInfo([]);
    } else if (
      routing.length == 9 &&
      routing[0] == routing[1] &&
      routing[1] == routing[2] &&
      routing[2] == routing[3] &&
      routing[3] == routing[4] &&
      routing[4] == routing[5] &&
      routing[5] == routing[6] &&
      routing[6] == routing[7] &&
      routing[7] == routing[8]
    ) {
      setRoutingError({
        ...routingError,
        error: true,
        message: 'Please enter a valid routing number.',
      });
      setRoutingBankInfo([]);
    } else {
      setRoutingError({
        ...routingError,
        error: false,
        message: '',
      });
      checkRoutingNumber();
    }
  };

  const bankHandler = (e) => {
    let newValue = e.target.value;
    let handledId = e.target.id.replace('Updater', '');

    //handle routing number - must have lenght of 9 & numbers only
    if (e.target.id != 'routingNumberUpdater') {
      setBank({
        ...bank,
        [handledId]: newValue,
      });
    } else {
      let inputValue = e.target.value;
      const regex = /^[0-3]+[0-9]*$/;
      if (inputValue === '' || regex.test(inputValue)) {
        let routingNumTruncated = inputValue.slice(0, 9);
        setBank({
          ...bank,
          [handledId]: routingNumTruncated,
        });
      }
    }
  };
  useEffect(() => {
    if (bank?.routingNumber) {
      routingCheck(bank?.routingNumber);
    }
    if (!bank?.routingNumber) {
      setRoutingBankInfo([]);
    }
  }, [bank?.routingNumber, bank?.id]);

  const bankScheduleHandler = (e) => {
    let value = e.target.value;
    if (value == 1) {
      setBank({
        ...bank,
        paymentFrequencyId: value,
      });
    } else {
      setBank({
        ...bank,
        paymentTwo: 15,
        paymentFrequencyId: value,
      });
    }
    props.setPaySchedule({
      ...props.paySchedule,
      paymentFrequencyId: value,
    });

    // let scheduleCheck = value == 2
    // props.setSemiMonthly(scheduleCheck)
  };

  const bankAccountHandler = (e) => {
    let value = e.target.value;
    setBank({
      ...bank,
      bankAccountTypeId: value,
    });
  };

  const payScheduleHandler = (date, oneOrTwo) => {
    let key = `payment${oneOrTwo}`;
    setBank({
      ...bank,
      [key]: date,
    });
  };

  const buildPayScheduleArray = (paymentDates) => {
    let builtArray = [];
    //if blet, only update 1st payment because only monthly allowed
    if (props?.bletGroupPlanId18) {
      let scheduledPaymentObj = {
        dayOfMonth: bank?.paymentOne,
        scheduledPaymentId: props?.paySchedule?.id,
      };
      builtArray.push(scheduledPaymentObj);
    } else {
      for (let date of paymentDates) {
        let payScheduleObj = {
          scheduledPaymentId: props.paySchedule?.id,
          dayOfMonth: date,
        };
        builtArray.push(payScheduleObj);
      }
    }

    return builtArray;
  };

  const [errorSubmitting, setErrorSubmitting] = useState(false);

  const submitBank = async (savingProgress) => {
    props.setLoading(true);
    let errorHit = false;
    // handleOpen();
    let paymentDates = [];
    if (bank?.paymentFrequencyId == 1) {
      paymentDates = [bank?.paymentOne];
    } else {
      paymentDates = [bank?.paymentOne, bank?.paymentTwo];
    }
    //create bankObj without id
    let bankObj = {
      memberId: props.fullMemberObj.id,
      paymentFrequencyId: parseInt(bank?.paymentFrequencyId),
      bankAccountTypeId: parseInt(bank?.bankAccountTypeId),
      accountNumber: bank?.accountNumber,
      routingNumber: bank?.routingNumber,
      paymentDates: paymentDates, //can be 1-26 or 10-20 if blet/metra
      bankName:
        routingBankInfo[0]?.status === 'success'
          ? routingBankInfo[0].data.name
          : '',
      description: bank?.description,
      isPrimary: true, //set whatever account selected to primary
      enrollmentId: parseInt(props.enrollmentResults.enrollmentId),
      openEnrollmentEnd: props.enrollmentResults.openEnrollmentEnd,
      amount: props.enrollmentResults.totalMonthlyPremium,
      transactionFee: props.enrollmentResults.transactionFee,
    };

    let builtPayScheduleArray;
    if (paymentDates.length > 0)
      builtPayScheduleArray = buildPayScheduleArray(paymentDates);

    let _paySchedule = {
      ...props?.paySchedule,
      paymentFrequencyId: bank?.paymentFrequencyId,
      bankAccountId: bank?.id,
      scheduledPaymentDates: builtPayScheduleArray,
      paymentAmount: props?.enrollmentResults?.totalMonthlyPremium,
    };

    //UPDATE BANK ACCOUNT
    //if a bank account id exists, update bank accountinfo and pay schedule, otherwise, add bank account
    if (bank?.id) {
      let _bank = { ...bankObj, id: bank?.id };
      _bank.paymentDates = paymentDates;
      //Member enroll side
      if (!props?.agentEnroller) {
        try {
          await walletService.updateBankAccount(_bank);
        } catch (error) {
          console.error('error updating bank account', error);
          errorHit = error;
        }

        //UPDATE PAY SCHEDULE if previous one associated with this enrollmentId exists
        if (_paySchedule?.id && _paySchedule?.id !== 0) {
          try {
            await walletService.updatePaymentSchedule(_paySchedule);
          } catch (error) {
            console.error('error updating pay schedule', error);
            errorHit = error;
          }
        } else {
          //ADD PAY SCHEDULE if bank exists but no schedule for it does
          try {
            await walletService.addPaymentSchedule(_paySchedule);
          } catch (error) {
            console.error('error adding pay schedule', error);
            errorHit = error;
          }
        }
      } else {
        //Agent side
        try {
          await walletService.updateBankAccountForMember(_bank);
        } catch (error) {
          console.error('error updating bank account for member', error);
          errorHit = error;
        }

        //UPDATE PAY SCHEDULE if previous one associated with this enrollmentId exists
        if (_paySchedule?.id && _paySchedule?.id !== 0) {
          try {
            await walletService.updatePaymentScheduleForMember(_paySchedule);
          } catch (error) {
            console.error('error updating pay schedule for member', error);
            errorHit = error;
          }
        } else {
          //ADD PAY SCHEDULE if bank exists but no schedule for it does
          try {
            await walletService.addPaymentScheduleForMember(_paySchedule);
          } catch (error) {
            console.error('error adding pay schedule for member', error);
            errorHit = error;
          }
        }
      }
    } else {
      //ADD BANK ACCOUNT  (this will automatically add a pay schedule)
      //Member Side
      if (!props?.agentEnroller) {
        try {
          await walletService.addBankAccount(bankObj);
        } catch (error) {
          console.error('error adding bank account', error);
          errorHit = error;
        }
      } else {
        //Agent Side
        try {
          await walletService.addBankAccountForMember(bankObj);
        } catch (error) {
          console.error('error adding bank account for member', error);
          errorHit = error;
        }
      }
    }

    //refresh bank
    await props.getBank();

    if (!errorHit && !savingProgress) {
      props.handleOpen();
    }
    if (errorHit) {
      setErrorSubmitting(errorHit);
    }
    props.setLoading(false);
  };

  //ADD NEW BANK ACCOUNT to list of already existing bank accounts
  const [showAddNewBankAccount, setShowAddNewBankAccount] = useState(false);
  //Hide this ADD new account form IF a bank w/ id (bank from db) is currently selected
  useEffect(() => {
    if (bank?.id) {
      setShowAddNewBankAccount(false);
    }
  }, [bank?.id]);

  const handleAddNewBankAccount = () => {
    setShowAddNewBankAccount(true);
    setBank({
      memberId: props.fullMemberObj.id,
      paymentFrequencyId: 1,
      bankAccountTypeId: 1,
      accountNumber: null,
      routingNumber: null,
      paymentOne: bank?.paymentOne ? bank?.paymentOne : 1, //keep selected schedule day if one exists
      paymentTwo: bank?.paymentTwo ? bank?.paymentTwo : 15, //keep selected schedule day if one exists
      bankName: '',
      description: '',
    });
  };

  //cancel adding new bank account before submitting
  const cancelAddNewBankAccount = () => {
    setShowAddNewBankAccount(false);
    setBank(props.bank); //reset to original primary bank acc
  };

  // Check Handlers

  const [achAuth, setAchAuth] = useState(false);
  const handleAchAuthChange = (e) => {
    setAchAuth((prev) => !prev);
  };

  const [tcAuth, setTcAuth] = useState(false);
  const handleTcAuthChange = (e) => {
    setTcAuth((prev) => !prev);
  };

  const handleVerbalAuthChange = (e) => {
    props.setVerbalAuth((prev) => !prev);
  };

  const [paperDateShow, setPaperDateShow] = useState(false);

  const handlePaperChange = (e) => {
    if (!props.paper.isMail) {
      props.setPaper({
        ...props.paper,
        isMail: true,
      });
      setPaperDateShow(true);
    } else {
      props.setPaper({
        ...props.paper,
        isMail: false,
      });
      setPaperDateShow(false);
    }
  };

  const paperDateHandler = (e) => {
    let newValue = e.target.value;
    props.setPaper({
      ...props.paper,
      signatureDate: newValue,
    });
  };

  // const [textAuth, setTextAuth] = useState(false)
  // const handleTextAuthChange = (e) => {
  //     setTextAuth(prev => !prev)
  // }

  const [disabled, setDisabled] = useState(true);

  const handleDisabled = () => {
    //these are the requirements for submitting enrollment AND bank account info - if adding new bank only, do not need to check these
    let completeEnrollmentRequirements = showAddNewBankAccount
      ? true
      : achAuth && tcAuth && props.verbalAuth;
    if (props.agentEnroller) {
      if (props.paper.isMail) {
        if (props.paper.signatureDate != '') {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      } else {
        if (
          completeEnrollmentRequirements &&
          // routingError.error === false && //commented out to allow agents to submit with invalid routing numbers
          bank?.routingNumber &&
          bank?.routingNumber != '' &&
          bank?.accountNumber &&
          bank?.accountNumber != '' &&
          //check confirmAccount is not empty IF dealing with add new bank account. If selected from list of existing accounts, do not need to confirm acct num
          (bank?.id && bank?.id > 0
            ? true
            : bank?.confirmAccount &&
              bank?.confirmAccount != '' &&
              bank?.accountNumber === bank?.confirmAccount)
        ) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
      }
    } else {
      if (
        achAuth &&
        tcAuth &&
        // routingError.error === false && //commented out to allow agents to submit with invalid routing numbers
        bank?.routingNumber &&
        bank?.routingNumber !== '' &&
        bank?.accountNumber &&
        bank?.accountNumber !== '' &&
        bank?.confirmAccount &&
        bank?.confirmAccount !== '' &&
        bank?.accountNumber === bank?.confirmAccount
      ) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    }
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const [firstPayment, setFirstPayment] = useState({
    month: '',
    year: '',
  });

  useEffect(() => {
    getFirstPaymentDate();
  }, [props?.enrollmentResults?.coverageEffectiveDate]);

  const getFirstPaymentDate = () => {
    // let fullDate = new Date(props?.enrollmentResults?.coverageEffectiveDate);
    let sliceDate = props?.enrollmentResults?.coverageEffectiveDate?.slice(
      0,
      10
    );
    let thisYear = Number(sliceDate?.slice(0, 4));
    let thisMonth = Number(sliceDate?.slice(5, 7)) - 1;
    // let thisMonth = fullDate.getMonth();
    // let thisYear = fullDate.getFullYear();
    setFirstPayment({
      ...firstPayment,
      month: months[thisMonth],
      year: thisYear,
    });
  };

  const dayFormatter = (number) => {
    if (number > 0) {
      let formattedNumber =
        parseInt(number) % 10 == 1 && parseInt(number) != 11
          ? number + 'st'
          : parseInt(number) % 10 == 2 && parseInt(number) != 12
          ? number + 'nd'
          : parseInt(number) % 10 == 3 && parseInt(number) != 13
          ? number + 'rd'
          : number + 'th';

      return formattedNumber;
    } else return 'Select A Date';
  };

  useEffect(() => {
    handleDisabled();
  }, [
    props.paper.isMail,
    props.paper.signatureDate,
    achAuth,
    tcAuth,
    props.verbalAuth,
    bank?.accountNumber,
    bank?.routingNumber,
    bank?.confirmAccount,
    routingError.error,
  ]);
  // useEffect(() => {
  //     handleDisabled()
  // }, [achAuth, tcAuth, verbalAuth, bank?.account, bank?.routing])

  const saveBankProgress = async () => {
    let savingProgress = true;
    await submitBank(savingProgress);
    props.setSave(false);
    props.setOpenEnrollment(false);
  };
  useEffect(() => {
    if (props.save) saveBankProgress();
  }, [props.save]);

  const [routingBankInfo, setRoutingBankInfo] = useState([]);
  const checkRoutingNumber = async (e) => {
    try {
      let _bankInfo = await walletService.getBankFromRouting(
        bank?.routingNumber
      );
      setRoutingBankInfo(_bankInfo);
    } catch (error) {
      console.error('error getting bank info from routing', error);
      setRoutingError({
        error: true,
        message: 'Please enter a valid routing number.',
      });
      setRoutingBankInfo([{ status: 'fail' }]);
    }
  };

  //Verify bank accounts match - check with both account num and confirm account num field changes
  const [matchingAccountError, setMatchingAccountError] = useState(false);
  const checkMatchingAccount = () => {
    //make sure something in the bank account input exists first before setting error
    if (
      bank?.accountNumber &&
      bank?.accountNumber !== '' &&
      bank?.accountNumber !== bank?.confirmAccount
    ) {
      setMatchingAccountError(true);
    } else {
      setMatchingAccountError(false);
    }
  };

  useEffect(() => {
    checkMatchingAccount();
  }, [bank?.accountNumber, bank?.confirmAccount]);

  return (
    <div
      className="row"
      style={{
        margin: 'auto',
        paddingBottom: '20px',
      }}
    >
      <Box sx={componentStyles.titleContainer}>
        <Typography variant="subtitle2" sx={componentStyles.titleText}>
          Payment Schedule
        </Typography>
      </Box>

      <Divider sx={componentStyles.divider} />

      <div
        className="flex-row"
        style={{
          justifyContent: 'flex-start',
          padding: '0px',
          textAlign: 'left',
          marginBottom: '15px',
        }}
      >
        <div className={bank?.paymentFrequencyId == 1 ? 'col-6' : 'col-12'}>
          <TextField
            fullWidth
            select
            sx={{
              width: `${bank?.paymentFrequencyId == 1 ? '95%' : '100%'}`,
              ...enrollmentPaymentStyles.selectInput,
              // '& fieldset': { borderRadius: '8px' },
              // '& fieldset > legend': { display: 'none' },
              // '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195ffb' },
            }}
            InputLabelProps={{ sx: { color: 'primary.main' } }}
            variant="outlined"
            label="Frequency"
            //only allow monthly payments if blet org (or meta which is part of blet)
            value={
              props?.bletGroupPlanId18 ? 1 : bank?.paymentFrequencyId || '1'
            }
            onChange={bankScheduleHandler}
            disabled={props?.bletGroupPlanId18}
          >
            {schedules.map((schedule) => (
              <MenuItem key={schedule.id} value={schedule.id}>
                {schedule.name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        {bank?.paymentFrequencyId == 1 && (
          <div className="col-6">
            <UmpDatePicker
              fullMemberObj={props?.fullMemberObj}
              bank={bank}
              oneOrTwo="One"
              payScheduleHandler={payScheduleHandler}
              schedule={bank?.paymentFrequencyId}
              dayFormatter={dayFormatter}
              bletGroupPlanId18={props?.bletGroupPlanId18}
            />
          </div>
        )}
      </div>

      {bank?.paymentFrequencyId == 2 && (
        <div
          className="flex-row"
          style={{
            justifyContent: 'flex-start',
            padding: '0px',
            textAlign: 'left',
            marginBottom: '15px',
          }}
        >
          <div className="col-6" style={{ paddingRight: '8px' }}>
            <UmpDatePicker
              fullMemberObj={props?.fullMemberObj}
              bank={bank}
              otherVal={bank?.paymentTwo}
              oneOrTwo="One"
              payScheduleHandler={payScheduleHandler}
              schedule={bank?.paymentFrequencyId}
              dayFormatter={dayFormatter}
              bletGroupPlanId18={props?.bletGroupPlanId18}
            />
          </div>
          <div className="col-6" style={{ paddingLeft: '8px' }}>
            <UmpDatePicker
              fullMemberObj={props?.fullMemberObj}
              bank={bank}
              otherVal={bank?.paymentOne}
              oneOrTwo="Two"
              payScheduleHandler={payScheduleHandler}
              schedule={bank?.paymentFrequencyId}
              dayFormatter={dayFormatter}
              bletGroupPlanId18={props?.bletGroupPlanId18}
            />
          </div>
        </div>
      )}

      {/* Display Payment schedule w/ amounts */}

      <div style={{ marginTop: '25px' }}>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col">
            <Typography variant="body1">First Payment</Typography>
            <Typography
              variant="body2"
              color="text.lightGray"
              fontWeight="500"
              opacity="80%"
            >
              {dayFormatter(bank?.paymentOne)} of the month
            </Typography>
          </div>
          <div className="col" style={{ textAlign: '-webkit-right' }}>
            <Typography variant="body2" fontWeight="600" opacity="80%">
              {/* add an additional $1 if two payments per month */}
              {bank?.paymentFrequencyId == 2
                ? props.UsdFormatterDec.format(
                    Math.ceil((props?.runningTotal / 2) * 100) / 100 + 1
                  )
                : props.UsdFormatterDec.format(props.runningTotal + 1)}
            </Typography>
            <Tooltip
              enterTouchDelay="50"
              title="$1.00 transaction fee is applied to each payment"
            >
              <Typography
                variant="caption"
                color="text.lightGray"
                fontWeight="500"
                opacity="80%"
              >
                Includes{' '}
                {props.UsdFormatterDec.format(props.results?.transactionFee)}{' '}
                fee
              </Typography>
            </Tooltip>
          </div>
        </div>
        {bank?.paymentFrequencyId == 2 && (
          <div className="row" style={{ marginBottom: '20px' }}>
            <div className="col">
              <Typography variant="body1">Second Payment</Typography>
              <Typography
                variant="body2"
                color="text.lightGray"
                fontWeight="500"
              >
                {dayFormatter(bank?.paymentTwo)} of the month
              </Typography>
            </div>
            <div className="col" style={{ textAlign: '-webkit-right' }}>
              <Typography variant="body2" fontWeight="600" opacity="80%">
                {props.UsdFormatterDec.format(
                  Math.floor((props?.runningTotal / 2) * 100) / 100 + 1
                )}
              </Typography>
              <Tooltip
                enterTouchDelay="50"
                title="$1.00 transaction fee is applied to each payment"
              >
                <Typography
                  variant="caption"
                  color="text.lightGray"
                  fontWeight="500"
                  opacity="80%"
                >
                  Includes{' '}
                  {props.UsdFormatterDec.format(props.results?.transactionFee)}{' '}
                  fee
                </Typography>
              </Tooltip>
            </div>
          </div>
        )}

        {/* Monthly Total - large font */}
        <div
          className="flex-row"
          style={{
            textAlign: 'center',
            marginBottom: '30px',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: '25px',
              lineHeight: '25px',
              fontFamily: 'Archivo',
            }}
            color="primary.main"
          >
            {
              // props.semiMonthly
              bank?.paymentFrequencyId == 2
                ? props.UsdFormatterDec.format(
                    props.runningTotal +
                      (props?.paySchedule?.totalTransactionFee
                        ? props?.paySchedule?.totalTransactionFee
                        : 2)
                  )
                : props.UsdFormatterDec.format(
                    props.runningTotal +
                      (props?.paySchedule?.totalTransactionFee
                        ? props?.paySchedule?.totalTransactionFee
                        : 1)
                  )
            }{' '}
            Monthly Total
          </Typography>
        </div>
        <div className="flex-row" stype={{ justifyContent: 'center' }}>
          <Alert severity="info" style={{ margin: 'auto', fontWeight: '600' }}>
            Your first payment will be on {firstPayment && firstPayment.month}
            &nbsp;{dayFormatter(bank?.paymentOne)},&nbsp;
            {firstPayment && firstPayment.year}
          </Alert>
        </div>
      </div>

      {/*---------- Payment Method------------ */}
      <Box sx={componentStyles.titleContainer}>
        <Typography variant="subtitle2" sx={componentStyles.titleText}>
          Payment Method
        </Typography>
      </Box>

      <Divider sx={componentStyles.divider} />

      {errorSubmitting && (
        <Box sx={componentStyles.bankSubmitError}>
          <Alert severity="error" sx={{ margin: '0 auto', fontWeight: '600' }}>
            Error submitting bank account. Please refresh and try again.
            <br />
            If the problem persists, please contact support. <br />
            {props.agentEnroller &&
              'Please make note of the banking information now, as it will be lost when refreshing'}
            <br />
            {errorSubmitting.message && (
              <Box sx={{ marginTop: '10px' }}>{errorSubmitting.message}</Box>
            )}
          </Alert>
        </Box>
      )}

      {/* display add bank form if no bank exists, else (for agent enrollment only) - display primary bank w/ option to select between multiple banks and add new bank account - sent in local state of bank and account to update and can compare to bank account held in parent*/}
      {props?.bank?.id > 0 && !showAddNewBankAccount && props?.agentEnroller ? (
        <ExistingPaymentMethods
          bank={bank}
          setBank={setBank}
          handleAddNewBankAccount={handleAddNewBankAccount}
          bankArr={props?.bankArr}
          bankAccountTypes={bankAccountTypes}
        />
      ) : (
        <BankInfoForm
          bank={bank}
          bankHandler={bankHandler}
          bankAccountHandler={bankAccountHandler}
          bankAccountTypes={bankAccountTypes}
          routingBankInfo={routingBankInfo}
          routingError={routingError}
          matchingAccountError={matchingAccountError}
          checkMatchingAccount={checkMatchingAccount}
          cancelAddNewBankAccount={cancelAddNewBankAccount}
          showAddNewBankAccount={showAddNewBankAccount}
          setShowAddNewBankAccount={setShowAddNewBankAccount}
          disabled={disabled}
          getBank={props.getBank}
        />
      )}

      {/*---------- End Payment Method Section----------- */}

      {/* Routing num confirm alert */}

      <Fade
        in={
          routingBankInfo?.length > 0 && routingBankInfo[0]?.status != undefined
        }
        mountOnEnter
        unmountOnExit
      >
        <div
          className="flex-row"
          style={{ padding: '10px 17px', justifyContent: 'center' }}
        >
          {routingBankInfo[0]?.status == 'success' && (
            <Alert
              severity="success"
              sx={{
                border: '2px solid #4E9A51',
                color: '#4E9A51',
                alignItems: 'center',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 500,
                textAlign: 'center',
              }}
              icon={<CheckIcon />}
            >
              {routingBankInfo[0]?.data?.name} Succesfully Connected
            </Alert>
          )}
          {routingBankInfo[0]?.status == 'fail' && (
            <Alert
              severity="warning"
              sx={{
                alignItems: 'center',
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: 500,
                textAlign: 'center',
              }}
              // icon={<CheckIcon />}
            >
              <span style={{ display: 'inline-block' }}>No Bank found</span>
            </Alert>
          )}
        </div>
      </Fade>
      {props.agentEnroller && (
        <>
          <div className="flex-row" style={{ marginTop: '20px' }}>
            <div style={{ alignItems: 'flex-start' }}>
              <Checkbox
                onChange={(e) => handlePaperChange(e)}
                value={props.paper.isMail}
                checked={props.paper.isMail}
                sx={componentStyles.checkboxSx}
                icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                checkedIcon={
                  <CheckBoxIcon sx={{ height: '19px', color: '#195ffb' }} />
                }
                // inputProps={{
                //     'data-amount':lifeADDPlan?.spouseOptions[props.spouseSlider].premiumAmount,
                //     'data-person':"spouse"
                // }}
                // disabled={props.checkboxes.member !== true}
              />
            </div>

            <div
              className="col"
              style={{
                fontWeight: 500,
                letterSpacing: '.15px',
                // lineHeight: "24px",
                fontSize: '15px',
                textAlign: 'left',
                marginLeft: '20px',
              }}
            >
              <Typography variant="body3" sx={componentStyles.authStatementSx}>
                This is a mailed in paper application with a physical signature
                from the member, processed by {_thisUser.thisUser.firstName}{' '}
                {_thisUser.thisUser.lastName}.
              </Typography>
            </div>
          </div>
          {paperDateShow && (
            <div className="flex-row">
              <TextField
                sx={{
                  margin: 'auto',
                  marginTop: '20px',
                  ...enrollmentPaymentStyles.selectInput,
                }}
                id="paperDate"
                onChange={paperDateHandler}
                required
                label="Signature Date"
                value={props.paper.signatureDate}
                placeholder="Signature Date"
                InputLabelProps={{
                  sx: { color: 'primary.main' },
                  shrink: true,
                }}
                type={'date'}
              />
            </div>
          )}
        </>
      )}
      <div
        className="flex-row"
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        <div style={{ alignItems: 'flex-start' }}>
          <Checkbox
            disabled={props.paper.isMail ? true : false}
            onChange={(e) => handleAchAuthChange(e)}
            value={achAuth}
            checked={achAuth}
            sx={componentStyles.checkboxSx}
            icon={
              props.paper.isMail ? (
                <IndeterminateCheckBoxIcon sx={{ height: '19px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />
              )
            }
            checkedIcon={
              <CheckBoxIcon sx={{ height: '19px', color: '#195ffb' }} />
            }
            inputProps={{
              'data-uhtest': 'iAuthorizeOrganizationToProcess_checkbox',
            }}
          />
        </div>

        {/* I auth statement */}

        <div
          className="col"
          style={{
            fontWeight: 500,
            letterSpacing: '.15px',
            // lineHeight: "20px",
            fontSize: '15px',
            textAlign: 'left',
            marginLeft: '20px',
          }}
        >
          <Typography
            variant="body3"
            sx={componentStyles.authStatementSx}
            color={props.paper.isMail ? 'text.gray' : 'text.primary'}
          >
            {props.agentEnroller
              ? `I, ${_thisUser.thisUser.firstName} ${_thisUser.thisUser.lastName}, received verbal authorization for this organization to process`
              : `I authorize this organization to process`}
            &nbsp;
            <Link
              sx={{ cursor: 'pointer' }}
              value="ach"
              onClick={(e) => props.handleOpenLegal(e)}
            >
              ACH Debit Entries
            </Link>
            &nbsp;
            {props.agentEnroller
              ? `on behalf of  ${props?.fullMemberObj?.firstName} ${props?.fullMemberObj?.lastName}.`
              : 'to my account.'}
          </Typography>
        </div>
      </div>

      <div className="flex-row" style={{ marginBottom: '20px' }}>
        <div style={{ alignItems: 'flex-start' }}>
          <Checkbox
            disabled={props.paper.isMail ? true : false}
            onChange={(e) => handleTcAuthChange(e)}
            value={tcAuth}
            checked={tcAuth}
            sx={componentStyles.checkboxSx}
            icon={
              props.paper.isMail ? (
                <IndeterminateCheckBoxIcon sx={{ height: '19px' }} />
              ) : (
                <CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />
              )
            }
            checkedIcon={
              <CheckBoxIcon sx={{ height: '19px', color: '#195ffb' }} />
            }
            inputProps={{
              'data-uhtest': 'iAgreeToTerms_checkbox',
            }}
          />
        </div>

        <div
          className="col"
          style={{
            fontWeight: 500,
            letterSpacing: '.15px',
            // lineHeight: "24px",
            fontSize: '15px',
            textAlign: 'left',
            marginLeft: '20px',
          }}
        >
          <Typography
            sx={componentStyles.authStatementSx}
            variant="body3"
            color={props.paper.isMail ? 'text.gray' : 'text.primary'}
          >
            {props.agentEnroller
              ? `I, ${_thisUser.thisUser.firstName} ${_thisUser.thisUser.lastName}, received verbal authorization to the `
              : 'I agree to the'}
            &nbsp;
            <Link
              sx={{ cursor: 'pointer' }}
              value="termsandconditions"
              onClick={(e) => props.handleOpenLegal(e)}
            >
              Terms and Conditions
            </Link>
            &nbsp;
            {props.agentEnroller
              ? `of this policy on behalf of ${props?.fullMemberObj?.firstName} ${props?.fullMemberObj?.lastName}.`
              : 'of this policy.'}
          </Typography>
        </div>
      </div>

      {props.agentEnroller && (
        <div className="flex-row" style={{ marginBottom: '20px' }}>
          <div style={{ alignItems: 'flex-start' }}>
            <Checkbox
              disabled={props.paper.isMail ? true : false}
              onChange={(e) => handleVerbalAuthChange(e)}
              value={props.verbalAuth}
              checked={props.verbalAuth}
              sx={componentStyles.checkboxSx}
              icon={
                props.paper.isMail ? (
                  <IndeterminateCheckBoxIcon sx={{ height: '19px' }} />
                ) : (
                  <CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />
                )
              }
              checkedIcon={
                <CheckBoxIcon sx={{ height: '19px', color: '#195ffb' }} />
              }
              inputProps={{
                  'data-uhtest': 'iRecievedVerbalConfirmationToSign_Checkbox',
              }}
            />
          </div>

          <div
            className="col"
            style={{
              fontWeight: 500,
              letterSpacing: '.15px',
              lineHeight: '24px',
              fontSize: '15px',
              textAlign: 'left',
              marginLeft: '20px',
            }}
          >
            <Typography
              variant="body3"
              sx={componentStyles.authStatementSx}
              color={props.paper.isMail ? 'text.gray' : 'text.primary'}
            >
              I, {_thisUser.thisUser.firstName} {_thisUser.thisUser.lastName},
              received &nbsp;
              <Link
                sx={{ cursor: 'pointer' }}
                value="verbalconfirmation"
                onClick={(e) => props.handleOpenLegal(e)}
              >
                Verbal Confirmation
              </Link>
              &nbsp; from {props?.fullMemberObj?.firstName}{' '}
              {props?.fullMemberObj?.lastName} to sign this agreement on their
              behalf.
            </Typography>
          </div>
        </div>
      )}

      {/* <div className='flex-row' style={{ marginBottom: '20px' }}>
                <div style={{ alignItems: 'flex-start' }}>
                    <Checkbox
                        onChange={(e) => handleTextAuthChange(e)} 
                        value={textAuth} checked={textAuth}
                        sx={{ padding: '0px', marginLeft: '-15px', marginRight: '8px' }}
                        icon={<CheckBoxOutlineBlankIcon sx={{ height: '19px' }} />}
                        checkedIcon={<CheckBoxIcon sx={{ height: '19px', color: '#195ffb' }} />}
                    // inputProps={{
                    //     'data-amount':lifeADDPlan?.spouseOptions[props.spouseSlider].premiumAmount,
                    //     'data-person':"spouse"
                    // }}
                    // disabled={props.checkboxes.member !== true}
                    />
                </div>


                <div className='col' style={{ fontWeight: 500, letterSpacing: '.15px', lineHeight: '24px', fontSize: "15px", textAlign: 'left', marginLeft: '20px' }}>
                    I opt in to Text Notifications regarding my policy
                </div>
            </div> */}

      <Button
        // disabled={bank?.account === bank?.confirmAccount ? false : true}
        onClick={() => submitBank()}
        fullWidth
        className="col-4"
        variant="contained"
        sx={{ display: 'block', margin: 'auto', marginTop: '10px' }}
        disabled={disabled || showAddNewBankAccount}
      >
        Submit
      </Button>
    </div>
  );
}
