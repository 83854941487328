const acDrawerSharedStyles = {
  componentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
  },
  bodyContainer: {
    padding: '10px 26px',
  },
  infoContainer: {
    width: '100%',
    padding: '10px 16px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    margin: '0 auto',
  },
  infoRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '10px 0px',
  },
  inputContainer: {
    width: '100%',
    margin: '30px 0px 20px 0px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
};

export default acDrawerSharedStyles;
