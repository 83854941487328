import React from 'react';

//MuiComponents
import Slider from '@mui/material/Slider';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';

//Mui Icons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

//Our icons

//Our Components
import RecommendedBenefitDisplay from '../../../RecommendedBenefitDisplay';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../../enrollmentPlanStyles';
import externalEnrollmentSharedStyles from '../../../../externalEnrollmentSharedStyles';

export default function EnrollmentCiMember(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let criticalIllnessPlan = results?.criticalIllnessPlan;
  let memberOptions = props.memberOptions;
  let dataUHTest = props.dataUHTest;

  const memberValuetext = (value) =>
    `${UsdFormatter.format(memberOptions[value]?.benefitAmount)}`;

  const autoCompleteHandler = (e) => {
    let tagName = e.target.tagName.toLowerCase();
    if (tagName == 'li') {
      let value = e.target.innerHTML.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < memberOptions.length; i++) {
        let benefitAmount = memberOptions[i]?.benefitAmount;
        if (benefitAmount == value) {
          props.setMemberSlider(i);
        }
      }
    } else if (tagName == 'input') {
      let value = e.target.value.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < memberOptions.length; i++) {
        let benefitAmount = memberOptions[i]?.benefitAmount;
        if (benefitAmount == value) {
          props.setMemberSlider(i);
        }
      }
    }
  };

  let maxCoverage = memberOptions[memberOptions.length - 1]?.benefitAmount;

  //display value logic for dropdown select
  let benefitSelectionValue = () => {
    if (
      memberOptions[props.memberSlider]?.benefitAmount === maxCoverage &&
      maxCoverage !== props?.currRec?.benefitAmount
    ) {
      //last but not rec amount
      return `${UsdFormatter.format(
        memberOptions[props.memberSlider]?.benefitAmount
      )} (max)`;
    } else if (
      memberOptions[props.memberSlider]?.benefitAmount === maxCoverage &&
      maxCoverage === props?.currRec?.benefitAmount
    ) {
      //last and rec amount
      return `${UsdFormatter.format(
        memberOptions[props.memberSlider]?.benefitAmount
      )} (recommended)`;
    } else if (
      memberOptions[props.memberSlider]?.benefitAmount ===
        props?.currRec?.benefitAmount &&
      props?.currRec?.benefitAmount !== maxCoverage
    ) {
      //rec amount but not last
      return `${UsdFormatter.format(
        memberOptions[props.memberSlider]?.benefitAmount
      )} (recommended)`;
    } else {
      return UsdFormatter.format(
        memberOptions[props.memberSlider]?.benefitAmount
      );
    }
  };

  return (
    <>
      <Box sx={enrollmentPlanStyles.componentContentContainer}>
        {/* Radio Button and Option Row */}
        <Box sx={enrollmentPlanStyles.optionRow}>
          <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
            <Radio
              sx={enrollmentPlanStyles.planSelectRadioButton}
              value={props.checkboxes.member}
              checked={
                props.checkboxes.member &&
                props?.checkboxes?.member !== 'truthy' &&
                props?.checkboxes?.member !== 'decline' &&
                !props?.memberLocalDecline
              }
              // onChange={props?.ciSelectionHandler}
              onClick={(e) => {
                props?.ciSelectionHandler(e, true);
              }}
              name={'0'}
              icon={
                <RadioButtonIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(false)}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(false)}
                />
              }
              inputProps={{
                'data-amount':
                  memberOptions[props.memberSlider]?.memberPremiumAmount,
                'data-person': 'member',
                'data-uhtest': dataUHTest,
                'data-coverage':
                  memberOptions[props.memberSlider]?.benefitAmount,
                'data-optionId':
                  memberOptions[props.memberSlider]?.memberOptionId,
              }}
            />

            <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
              <Typography
                sx={enrollmentPlanStyles.singleOptionText(
                  props.checkboxes.member &&
                    props?.checkboxes?.member !== 'decline'
                )}
              >
                Member (
                {UsdFormatter.format(
                  memberOptions[props.memberSlider]?.benefitAmount
                )}
                )
              </Typography>
            </Box>
          </Box>

          {/* premium amount */}
          <Box
            sx={enrollmentPlanStyles.singleOptionText(
              props.checkboxes.member && props?.checkboxes?.member !== 'decline'
            )}
          >
            {props.checkboxes.member === true && '+'}
            {!props.dummyDisplay &&
              UsdFormatterDec.format(
                memberOptions[props.memberSlider]?.memberPremiumAmount
              )}
            {props.dummyDisplay && '$0.00 '}
          </Box>
        </Box>

        <RecommendedBenefitDisplay currRec={props?.currRec} />

        <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            autoComplete
            options={memberOptions.map((option, i) => {
              let isLast = i == memberOptions.length - 1;
              if (
                isLast &&
                props?.currRec?.benefitAmount !== option?.benefitAmount
              ) {
                // last but not rec amount
                return `${UsdFormatter.format(option.benefitAmount)} (max)`;
              } else if (
                isLast &&
                props?.currRec?.benefitAmount === option?.benefitAmount
              ) {
                //last and = rec amount
                return `${UsdFormatter.format(
                  option.benefitAmount
                )} (recommended)`;
              } else if (
                !isLast &&
                props?.currRec?.benefitAmount === option?.benefitAmount
              ) {
                //not last and = rec amount
                return `${UsdFormatter.format(
                  option.benefitAmount
                )} (recommended)`;
              } else {
                return UsdFormatter.format(option.benefitAmount);
              }
            })}
            value={benefitSelectionValue() || 0}
            onChange={autoCompleteHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={enrollmentPlanStyles.inputLabelProps}
                label="Coverage Amount"
                sx={enrollmentPlanStyles.activeBlueDropdownField(false)}
              />
            )}
            componentsProps={{
              popupIndicator: { sx: { color: 'primary.main' } },
            }}
          />
        </Box>

        {/* Slider  */}
        <Box sx={enrollmentPlanStyles.incSliderSection}>
          {/* Slider min */}
          <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
            {`$${memberOptions[0]?.benefitAmount / 1000}K`}
          </Box>

          {/* Slider container */}
          <Box sx={enrollmentPlanStyles.incSliderContainer}>
            <Slider
              onChange={props.memberSliderChange}
              onChangeCommitted={(e) =>
                props.handleSliderComplete(e, props.memberSlider, 'member')
              }
              disabled={props.checkboxes.member !== true}
              value={props.memberSlider}
              step={1}
              min={0}
              max={memberOptions.length - 1}
              sx={enrollmentPlanStyles.incSlider}
              valueLabelDisplay="auto"
              valueLabelFormat={memberValuetext}
            />
          </Box>

          {/* Slider max */}
          <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
            {`$${
              memberOptions[memberOptions.length - 1]?.benefitAmount / 1000
            }K`}
          </Box>
        </Box>

        {/* NOT NOW */}
        <Box
          sx={{
            ...enrollmentPlanStyles.declineOptionRow,
            marginBottom: '20px',
          }}
        >
          <Radio
            value={props?.declineOption}
            checked={props?.declineOption === props?.selections?.ci[0]}
            name={'0'}
            sx={enrollmentPlanStyles.planSelectRadioButton}
            icon={
              <RadioButtonIcon
                sx={enrollmentPlanStyles.radioButtonIcon(false)}
              />
            }
            checkedIcon={
              <RadioButtonCheckedIcon
                sx={enrollmentPlanStyles.radioButtonIcon(false)}
              />
            }
            onClick={(e) => {
              // handleExpandClick()
              props?.handleCiDeclineSelection(e, 'member');
            }}
            inputProps={{
              'data-amount': 0,
              'data-person': 'member',
              'data-uhtest': 'MemberCIFalse_radio',
              'data-coverage': 0,
              'data-optionId': props?.declineOption,
            }}
          />
          <Box
            sx={enrollmentPlanStyles.declineOptionText(
              props?.memberLocalDecline
            )}
          >
            Decline Member Critical Illness Coverage
          </Box>
          <Tooltip
            title={
              // criticalIllnessPlan?.infoTipText ||
              'If you decline coverage, you may not be able to enroll in the future.'
            }
            placement="bottom-end"
            arrow
            enterTouchDelay={0}
          >
            <Box sx={enrollmentPlanStyles.notNowToolTip}>
              <InfoOutlinedIcon
                sx={externalEnrollmentSharedStyles.grayInfoTip}
              />
            </Box>
          </Tooltip>
        </Box>
      </Box>

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
