import { useEffect, useState, useCallback } from 'react';
import {
  formatUTCToShortDate,
  formatUTCYYYYMMDD,
  todayDateObj,
  todayDateShort,
} from '../../../utils';
import walletService from '../../../../services/wallet-service';

//MuiIcons
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

//Mui Components
import { Alert, Chip, Button } from '@mui/material';
import Box from '@mui/material/Box';

//Our Components
import ButtonInfinityLoader from '../../../UH-loading-animation/ButtonInfinityLoader';

//component styles
const componentStyles = {
  alertChip: (paymentType) => ({
    height: '24px',
    color:
      paymentType === 'oneTime' ? '#0288D1!important' : '#1B5E20!important',
  }),
  chipBody: (paymentType) => ({
    minWidth: '200px',
    backgroundColor:
      paymentType === 'oneTime'
        ? 'background.onMedicalLeaveChip'
        : 'background.activeWorking',
    color:
      paymentType === 'oneTime'
        ? 'primary.onMedicalLeaveChip'
        : 'primary.activeChip',
    fontFamily: 'Archivo',
    margin: '10px 0px 0px auto',
    fontWeight: '500',
    alignItems: 'center',
    fontSize: '13px',
    alignSelf: 'flex-end',
  }),
  resetScheduleAlert: {
    padding: '6px 20px',
    position: 'relative',
    fontFamily: 'Archivo',
    fontWeight: '400',
    lineHeight: '20px',
    margin: '10px 0px 0px auto',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'flex-start',
    maxWidth: '450px',
    borderRadius: '4px',
  },
  otpStuckWarning: (isPlansTab) => ({
    padding: '6px 20px',
    position: 'relative',
    fontFamily: 'Archivo',
    fontWeight: '400',
    lineHeight: '20px',
    margin: isPlansTab ? '10px 0px 0px 0px' : '10px 0px 0px auto',
    fontSize: '14px',
    maxWidth: '275px',
    borderRadius: '4px',
    alignSelf: isPlansTab ? 'flex-start' : 'flex-end',
  }),
  componentBody: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-end',
    // maxWidth: '450px',
  },
};

export default function NextPaymentChip(props) {
  const currentDate = todayDateObj;
  const [loading, setLoading] = useState(false);

  // Function to check for stuck one-time payments
  const checkStuckOneTimePayment = useCallback(() => {
    let otpStuckInPast = false;

    //nextoccurrence is in future of today BUT calculated next occurrence is in the past = otp stuck in past
    if (
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate <
        todayDateShort &&
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate !==
        props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate &&
      props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate >
        todayDateShort
    ) {
      otpStuckInPast = true;
    } else if (
      //both next occurrence AND calculated next occurrence is in the past = both otp and pay schedule stuck in past
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate <
        todayDateShort &&
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate !==
        props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate &&
      props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate <
        todayDateShort
    ) {
      otpStuckInPast = true;
    } else {
      otpStuckInPast = false;
    }
    return otpStuckInPast;
  }, [props?.currentCombinedPaymentInfo]); // Added dependencies here

  // Check for upcoming payment in future (either active schedule or one-time payment)

  const [otpStuckInPast, setOtpStuckInPast] = useState(false);
  useEffect(() => {
    if (
      (props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate,
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate)
    ) {
      let _otpStuckInPast = checkStuckOneTimePayment();
      if (_otpStuckInPast) {
        setOtpStuckInPast(_otpStuckInPast);
      } else {
        setOtpStuckInPast(false);
      }
    }
  }, [
    props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate,
    props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate,
  ]);

  const handleReset = async () => {
    setLoading(true);
    try {
      let result = await walletService.resetPaymentSchedule(
        props?.currentCombinedPaymentInfo.activePaymentSchedule
      );
      if (result) {
        props.getPaymentSchedule();
        setLoading(false);
      }
    } catch (error) {
      console.log('resetting payment schedule');
      setLoading(false);
    }
  };

  // Check for upcoming payment in future (either active schedule or one-time payment)
  const [chipType, setChipType] = useState(null);
  const checkChipType = () => {
    let chipType;
    if (
      (props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate &&
        props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate >=
          todayDateShort &&
        props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate >=
          todayDateShort) ||
      props?.displayNextPaymentChip?.oneTimePaymentsExist
    ) {
      // Compare day from calculatedNextOccurrence to active schedule payment date(s)
      // If schedule exists and is enabled and these dates don't match OR future one-time payment exists before next scheduled payment, then it is a one time payment
      if (
        props?.currentCombinedPaymentInfo?.futureOneTimePayment ||
        props?.displayNextPaymentChip?.oneTimePaymentsExist
      ) {
        let futureOneTimePaymentShort = props?.currentCombinedPaymentInfo
          ?.futureOneTimePayment?.occurrence
          ? formatUTCYYYYMMDD(
              props?.currentCombinedPaymentInfo?.futureOneTimePayment
                ?.occurrence
            )
          : null;
        //next scheduled payment date we build in memberProfile - need to use this in case nextOccurrence from db is stuck in past
        let nextScheduledPaymentDateShort = props?.currentCombinedPaymentInfo
          ?.nextScheduledPaymentDate
          ? formatUTCYYYYMMDD(
              props?.currentCombinedPaymentInfo?.nextScheduledPaymentDate
            )
          : null;
        // check if future one time payment is before next scheduled payment or future one time payment exists but payment schedule possibly does not (related to a disabled schedule)
        futureOneTimePaymentShort <= nextScheduledPaymentDateShort ||
        (!nextScheduledPaymentDateShort && futureOneTimePaymentShort)
          ? (chipType = 'oneTime')
          : (chipType = 'nextScheduled');
      } else {
        chipType = 'nextScheduled';
      }
    } else {
      // Check if the calculatedNextOccurrence is in the past
      if (
        props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate <
          props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate &&
        props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate >
          todayDateShort
      ) {
        // Next occurrence in past but calculated next occurrence is in the past = OTP stuck in past
        // We want to show the regular next scheduled payment chip but also show the warning for OTP stuck
        chipType = 'nextScheduled';
      } else if (
        // Next occurrence in past AND calculated next occurrence is in the past = both OTP and pay schedule stuck in past
        props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate !==
          props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate &&
        props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate <
          todayDateShort
      ) {
        chipType = 'error';
      } else {
        chipType = 'error';
      }
    }
    return chipType;
  };

  useEffect(() => {
    //check for existing and enabled active one time payment schedule OR future one time payment
    if (
      props?.currentCombinedPaymentInfo?.calculatedNextOccurrenceShortDate ||
      props?.currentCombinedPaymentInfo?.nextOccurrenceShortDate ||
      props?.displayNextPaymentChip?.oneTimePaymentsExist
    ) {
      let _chipType = checkChipType();
      setChipType(_chipType);
    } else {
      //reset chipType if no data (likely means schedule is disabled)
      setChipType(null);
    }
  }, [
    props?.currentCombinedPaymentInfo,
    props?.currentCombinedPaymentInfo?.nextOccurrenceFromActiveSchedule,
    props?.displayNextPaymentChip?.oneTimePaymentsExist,
  ]);

  return (
    <Box sx={componentStyles.componentBody}>
      <Box sx={{ height: '10px' }} />
      {otpStuckInPast && (
        <Alert
          severity="warning"
          icon={
            <WarningAmberIcon sx={{ alignSelf: 'top', fontSize: 'inherit' }} />
          }
          sx={componentStyles.otpStuckWarning(props?.isPlansTab)}
        >
          One-time payment error
        </Alert>
      )}
      {chipType === 'nextScheduled' && (
        <Chip
          icon={<AccessTimeIcon sx={componentStyles.alertChip(chipType)} />}
          sx={componentStyles.chipBody(chipType)}
          label={`Next Scheduled Payment ${
            props?.currentCombinedPaymentInfo?.nextOccurrenceFromActiveSchedule
              ? formatUTCToShortDate(
                  props?.currentCombinedPaymentInfo
                    ?.nextOccurrenceFromActiveSchedule
                )
              : ''
          }`}
        />
      )}
      {chipType === 'oneTime' && (
        <Chip
          icon={<AccessTimeIcon sx={componentStyles.alertChip(chipType)} />}
          sx={componentStyles.chipBody(chipType)}
          label={`Upcoming One-Time Payment ${
            props?.currentCombinedPaymentInfo?.futureOneTimePayment?.occurrence
              ? formatUTCToShortDate(
                  props?.currentCombinedPaymentInfo?.futureOneTimePayment
                    ?.occurrence
                )
              : ''
          }`}
        />
      )}
      {chipType === 'error' && (
        <Alert
          severity="error"
          icon={
            <WarningAmberIcon sx={{ alignSelf: 'top', fontSize: 'inherit' }} />
          }
          sx={componentStyles.resetScheduleAlert}
          action={
            <Button
              onClick={handleReset}
              size="small"
              sx={{ fontSize: '13px', color: 'inherit' }}
              disabled={loading}
            >
              {loading ? (
                <ButtonInfinityLoader buttonTitle={'RESET'} />
              ) : (
                'RESET'
              )}
            </Button>
          }
        >
          Schedule failed to run the payment on{' '}
          {props?.currentCombinedPaymentInfo?.nextOccurrenceFromActiveSchedule
            ? formatUTCToShortDate(
                props?.currentCombinedPaymentInfo
                  ?.nextOccurrenceFromActiveSchedule
              )
            : ''}
          . Please select reset to enable the payment schedule{' '}
          {props?.currentCombinedPaymentInfo?.nextScheduledPaymentDate
            ? `for ${formatUTCToShortDate(
                props?.currentCombinedPaymentInfo?.nextScheduledPaymentDate
              )}.`
            : '.'}
        </Alert>
      )}
    </Box>
  );
}
