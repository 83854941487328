import React, { useEffect, useState, useContext } from 'react';

//Services

// Assets

//MuiIcons

//MuiComponents
import Button from '@mui/material/Button';

import { AgentColorModeContext } from '../../../../App';

export default function SettingsPersonalization(props) {
  const colorMode = useContext(AgentColorModeContext);

  return (
    <>
      {/* ACTUAL CONTENT */}

      <div
        className="container"
        style={{
          height: 'calc(100vh - 222px)',
          backgroundColor: 'FFFFFF',
          margin: 20,
          fontFamily: 'Archivo',
        }}
      >
        <div className="row" style={{ height: 'auto' }}>
          <div className={`col-md-3`} style={{ alignSelf: 'center' }}>
            <h6>Color Scheme:</h6>
          </div>
          <div className={`col-md-9`}>
            <Button onClick={() => colorMode.ToggleColorMode()}>
              Set to {colorMode.colorMode === 'light' ? 'Dark' : 'Light'} Mode
            </Button>
            <span style={{ fontSize: '13px', fontWeight: '600' }}>*Beta</span>
          </div>
        </div>
      </div>
    </>
  );
}
