import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//Services
import accountService from '../../../../services/account-service';

// Assets

//MuiIcons

//MuiComponents
import Button from '@mui/material/Button';
import { InputLabel, TextField } from '@mui/material';
import FlexSnackbar from '../../../flex-snackbar/FlexSnackbar';

export default function SettingsProfile(props) {
  // let history = useHistory()
  // const [thisUser,setThisUser] = useState()
  //  let getThisUser = async () => {
  //      let _thisUser = await memberService.getMe()
  //      setThisUser(_thisUser)
  //      // console.log('thisUser ',_thisUser)
  //  }
  //  useEffect(() => {
  //      getThisUser()
  //  },[])
  // let thisUser = props.thisUser;
  const [disableSave, setDisableSave] = useState(true);
  const [companyInfo, setCompanyInfo] = useState({
    name: props.company.name,
    subDomain: props.company.subDomain,
    street1: props.company.street1,
    street2: props.company.street2,
    city: props.company.city,
    state: props.company.state,
    zipCode: props.company.zipCode,
    phone: props.company.phone,
    fax: props.company.fax,
  });

  useEffect(() => {}, []);

  // Error / Success Messages
  const [message, setMessage] = useState({});

  useEffect(() => {
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setMessage({
        ...message,
        messageDisplay: false,
        autoHideDuration: '',
        severity: '',
        backgroundColor: '',
        message: '',
      });
    }, 5000);

    return () => {
      clearTimeout(timeId);
    };
  }, [message.messageDisplay]);

  const handleMessageClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setMessage({
      ...message,
      messageDisplay: false,
      autoHideDuration: '',
      severity: '',
      backgroundColor: '',
      message: '',
    });
  };

  let handleInfoChange = (e, property) => {
    let value = e.target.value;
    setCompanyInfo({
      ...companyInfo,
      [property]: value,
    });
    setDisableSave(false);
  };

  let handleSave = async (e) => {
    try {
      await accountService.updateAccount(props.company.id, companyInfo);
    } catch (err) {
      console.error(err);
      setMessage({
        ...message,
        messageDisplay: true,
        autoHideDuration: 1500,
        backgroundColor: '',
        severity: 'error',
        message: err.response.data.message,
      });
    }
    setDisableSave(true);
    setMessage({
      ...message,
      messageDisplay: true,
      autoHideDuration: 1500,
      backgroundColor: '',
      severity: 'success',
      message: 'Successfully Updated',
    });
  };

  return (
    <>
      {/* ACTUAL CONTENT */}

      <div
        className="container"
        style={{
          height: 'calc(100vh - 219px)',
          backgroundColor: 'FFFFFF',
          margin: 20,
        }}
      >
        <div className="row" style={{ height: 'auto' }}>
          <div className="col-md-12" style={{ marginBottom: '40px' }}>
            <h5>Company Information</h5>
            <p>Update information about your company here.</p>
          </div>

          <div className={`col-md-3`}>
            <h6>Name</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="Company Name"
              size="small"
              required
              value={companyInfo?.name}
              error={companyInfo?.name == ''}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'name')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
          </div>

          <div className={`col-md-3`}>
            <h6>Address</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="Address Line 1"
              size="small"
              value={companyInfo?.street1}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'street1')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <br />
            <TextField
              variant="outlined"
              label="Address Line 2"
              size="small"
              value={companyInfo?.street2}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'street2')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <br />
            <TextField
              variant="outlined"
              label="city"
              size="small"
              value={companyInfo?.city}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'city')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <TextField
              variant="outlined"
              label="State"
              size="small"
              value={companyInfo?.state}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'state')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <TextField
              variant="outlined"
              label="Zip Code"
              size="small"
              value={companyInfo?.zipCode}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'zipCode')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
          </div>

          <div className={`col-md-3`}>
            <h6>Contact</h6>
          </div>
          <div className={`col-md-9`}>
            <TextField
              variant="outlined"
              label="Phone"
              size="small"
              value={companyInfo?.phone}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'phone')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <br />
            <TextField
              variant="outlined"
              label="Fax"
              size="small"
              value={companyInfo?.fax}
              sx={{ marginBottom: '40px', marginRight: '10px' }}
              onChange={(e) => handleInfoChange(e, 'fax')}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root.Mui-focused > fieldset': {
                    border: '2px solid #195FFB',
                  },
                },
              }}
            />
            <br />
            {/* <TextField variant='outlined' label='Email' size='small'
                     value={companyInfo?.email}
                     sx={{ marginBottom: '40px', marginRight: '10px' }}
                     onChange={e => handleInfoChange(e, 'email')}
                     InputProps={{
                        sx: {
                           '&.MuiOutlinedInput-root.Mui-focused > fieldset': { border: '2px solid #195FFB' },
                        },
                     }}
                  /> */}
          </div>
          <div className={`col-md-3`}>
            <h6>Domain</h6>
          </div>
          <div className={`col-md-9`}>
            <p>www.{companyInfo?.subDomain}.unionhub.com</p>
          </div>

          <div
            style={{
              position: 'fixed',
              bottom: 40,
              right: 200,
              textAlign: 'right',
            }}
          >
            <Button
              fullWidth
              size="small"
              variant="contained"
              disabled={disableSave}
              onClick={(e) => handleSave()}
              style={{
                fontSize: 16,
                fontWeight: 400,
                padding: 20,
                height: '20px',
                width: 'auto',
                borderColor: '#195FFB',
                borderRadius: '33px',
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
        <FlexSnackbar
          displayAlert={message.messageDisplay}
          setDisplayAlert={setMessage}
          severity={message.severity}
          text={message.message}
          autoHideDuration={message.autoHideDuration}
        />
      </div>
    </>
  );
}
