import React, { useEffect, useState } from 'react';

//Assets

//Muicons
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

//MuiComponents
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import { Collapse, Link, ListItem, TextField } from '@mui/material';
import {
  formatCurrency,
  formatDateObjMMDDYYYYDisplay,
  formatUTCDateToObjWithTimeZone,
} from '../../../../utils';

export default function PortalPlanSummary(props) {
  const [expanded, setExpanded] = useState(false);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  const [election, setElection] = useState({});
  useEffect(() => {
    if (
      props.plan &&
      props.plan?.spouseSelectedOptionId > 0 &&
      props?.plan?.dependentSelectedOptionId > 0
    ) {
      //check for ci/ multi option plan
      let selectedMemberOption = props.plan.options.find(
        (x) => x.memberOptionId === props.plan.selectedOptionId
      );
      let selectedSpouseOption = props.plan.options.find(
        (x) => x.memberAndSpouseOptionId === props.plan.spouseSelectedOptionId
      );
      let selectedDependentOption = props.plan.options.find(
        (x) =>
          x.memberAndDependentsOptionId === props.plan.dependentSelectedOptionId
      );
      setElection({
        member: selectedMemberOption,
        spouse: selectedSpouseOption,
        dependent: selectedDependentOption,
      });
    } else if (props.plan && props.plan?.options?.length > 0) {
      let selectedOption = props.plan.options.find((x) => x.isSelected);
      setElection(selectedOption);
    } else {
      let memberElection = props.plan.memberOptions.find((x) => x.isSelected);
      let spouseElection = props.plan.spouseOptions.find((x) => x.isSelected);
      let dependentElection = props.plan.dependentOptions.find(
        (x) => x.isSelected
      );
      setElection({
        member: memberElection,
        spouse: spouseElection,
        dependent: dependentElection,
      });
    }
  }, [props.plan, props.plan?.options]);

  return (
    <>
      <Box
        sx={{ width: '100%', padding: '0px 14px' }}
        onClick={handleExpandSection}
      >
        <Typography variant="h6">{props.title}</Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '8px 16px',
          backgroundColor: 'white',
          border: '1px #E0E0E0 solid',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        }}
      >
        <Box className="col-9">
          <Typography
            component={'div'}
            variant="body2"
            className="col-12"
            sx={{
              letterSpacing: '.17px',
              fontSize: '16px',
              color: 'text.secondary',
              lineHeight: '20px',
            }}
          >
            {election?.name != null ? (
              <>
                {election?.name}
                {election.name.includes('%') &&
                  election?.benefitAmount &&
                  ` (Max ${formatCurrency(election.benefitAmount)})`}
                <br></br>
                <Typography
                  // component={'span'}
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    color: 'text.secondary',
                    letterSpacing: '.4px',
                    fontFamily: 'Archivo',
                  }}
                >
                  Effective:{' '}
                  {props?.plan?.effectiveDate?.split &&
                    formatDateObjMMDDYYYYDisplay(
                      formatUTCDateToObjWithTimeZone(props.plan?.effectiveDate)
                    )}
                </Typography>
              </>
            ) : (
              <>
                <span>
                  Member:{' '}
                  {formatCurrency(
                    election?.member?.coverageAmount ||
                      election?.member?.benefitAmount ||
                      0
                  )}{' '}
                  <br></br>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'text.secondary',
                      letterSpacing: '.4px',
                      fontFamily: 'Archivo',
                    }}
                  >
                    Effective:{' '}
                    {props?.plan?.effectiveDate?.split &&
                      formatDateObjMMDDYYYYDisplay(
                        formatUTCDateToObjWithTimeZone(
                          props.plan?.effectiveDate
                        )
                      )}
                  </Typography>
                  <br></br>
                </span>
                <span>
                  Spouse:{' '}
                  {formatCurrency(
                    election?.spouse?.coverageAmount ||
                      election?.spouse?.benefitAmount ||
                      0
                  )}{' '}
                  <br></br>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'text.secondary',
                      letterSpacing: '.4px',
                      fontFamily: 'Archivo',
                    }}
                  >
                    Effective:{' '}
                    {props?.plan?.effectiveDate?.split &&
                      formatDateObjMMDDYYYYDisplay(
                        formatUTCDateToObjWithTimeZone(
                          props.plan?.effectiveDate
                        )
                      )}
                  </Typography>
                  <br></br>
                </span>
                <span>
                  Dependent(s):{' '}
                  {formatCurrency(
                    election?.dependent?.coverageAmount ||
                      election?.dependent?.benefitAmount ||
                      0
                  )}{' '}
                  <br></br>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'text.secondary',
                      letterSpacing: '.4px',
                      fontFamily: 'Archivo',
                    }}
                  >
                    Effective:{' '}
                    {props?.plan?.effectiveDate?.split &&
                      formatDateObjMMDDYYYYDisplay(
                        formatUTCDateToObjWithTimeZone(
                          props.plan?.effectiveDate
                        )
                      )}
                  </Typography>
                  <br></br>
                </span>
              </>
            )}
          </Typography>
          <Button
            sx={{
              color: 'primary.main',
              fontSize: '14px',
              fontWeight: 500,
              letterSpacing: '.17px',
              fontFamily: 'Archivo',
              paddingLeft: '0px',
            }}
            disableRipple
            variant="text"
            onClick={handleExpandSection}
          >
            {expanded ? 'SEE LESS' : 'SEE MORE'}
          </Button>
        </Box>
        <Box
          className="col-3"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            // width: '100%',
            marginTop: '6px',
          }}
        >
          <Typography
            sx={{
              color: 'primary.text',
              fontWeight: 600,
              fontSize: '15px',
              letterSpacing: '.17px',
              fontFamily: 'Archivo',
              fontWeight: 700,
            }}
          >
            {formatCurrency(
              props.plan?.totalPremiumAmount ||
                election?.memberPremiumAmount ||
                0
            )}
          </Typography>
        </Box>
        <Collapse
          // sx={enrollmentPlanStyles.collapseSection}
          in={expanded}
          // unmountOnExit
          // mountOnEnter
        >
          <Box
            sx={{
              fontFamily: 'Archivo',
              fontWeight: '500',
              fontSize: '15px',
              lineHeight: '25px',
              textAlign: 'left',
              color: 'text.secondary',
            }}
          >
            <style>
              {`
                  ul {
                     padding-left: 1.3rem;
                     padding-right: 2.3rem;
                  }
                  li {
                     lineheight: 18px;
                     margin: 5px 0px;
                  }
                  `}
            </style>

            <span
              style={{ color: 'text.secondary' }}
              dangerouslySetInnerHTML={{ __html: props.plan?.helpText }}
            ></span>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '16px',
            }}
          >
            {/* <Typography
              component={'span'}
              sx={{
                color: 'primary.text',
                fontWeight: 600,
                fontSize: '15px',
                letterSpacing: '.17px',
                fontFamily: 'Archivo',
                fontWeight: 700,
              }}
            >
              {formatCurrency(
                props.plan?.totalPremiumAmount ||
                  election?.memberPremiumAmount ||
                  0
              )}
            </Typography> */}
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
