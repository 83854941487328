import Settings from '../settings';
import authService, { authHeader } from './auth-service';
import { baseService } from './baseService';

class internalWalletService extends baseService {
  /* ACH Object looks like below
   * {
   *      "paymentFrequencyId": 1,
   *      "accountTypeId": 1,
   *      "AccountNumber": "123456789",
   *      "RoutingNumber": "123456789",
   *      "PaymentDates": [10,25]
   * }
   *
   * // For payment frequency: 1 is monthly, 2 is semimonthly, 3 is annually
   * // For account Type: 1 is Checking, 2 is Savings
   *
   */

  getAch(memberId) {
    const url = Settings.API_URL + 'wallet/bankaccount/' + memberId;
    return this.get(url);
  }
  // updateAch(achObject) {
  //     const url = Settings.API_URL + "wallet/bankaccount";
  //     return this.put(url, achObject);
  // }
  // deleteAch(Id) {
  //     const url = Settings.API_URL + "wallet/bankaccount/delete/" + Id;
  //     return this.delete(url)
  // }
  // addAch(achObject) {
  //     const url = Settings.API_URL + "wallet/bankaccount";
  //     return this.post(url, achObject);
  // }

  //Payment Schedule Member
  //requires EnrollmentId
  getPaymentSchedule(enrollmentId) {
    const url = Settings.API_URL + 'wallet/payment/getschedule';
    return this.get(url, enrollmentId);
  }

  //requires EnrollmentId
  getEnrollmentScheduledPayment(enrollmentId) {
    const url =
      Settings.API_URL + `wallet/payment/getEnrollmentSchedule/${enrollmentId}`;
    return this.get(url);
  }

  //ALL payment schedules, not enrollmentId specific
  getAllPaymentSchedules() {
    const url = Settings.API_URL + 'wallet/payment/getallschedules';
    return this.get(url);
  }

  updatePaymentSchedule(achObject) {
    const url = Settings.API_URL + 'wallet/payment/updatescheduledpayments';
    return this.put(url, achObject);
  }

  addPaymentSchedule(scheduleObject) {
    const url = Settings.API_URL + 'wallet/payment/postschedule';
    return this.post(url, scheduleObject);
  }

  //Payment Schedule Agent
  //requires EnrollmentId
  getPaymentScheduleForMember(memberId, enrollmentId) {
    // optional enrollmentId to get single schedule associated with enrollment
    const url =
      Settings.API_URL +
      `wallet/member/getschedule/${memberId}/${enrollmentId}`;
    return this.get(url);
  }

  //get ALL schedules for member, not enrollmentId specific
  getAllPaymentSchedulesForMember(memberId) {
    const url = Settings.API_URL + 'wallet/member/getallschedules/' + memberId;
    return this.get(url);
  }

  //this can connnect to reset button
  updatePaymentScheduleForMember(achObject) {
    const url = Settings.API_URL + 'wallet/member/updatescheduledpayment';
    return this.put(url, achObject);
  }

  addPaymentScheduleForMember(scheduleObject) {
    const url = Settings.API_URL + 'wallet/member/postscheduledpayment';
    return this.post(url, scheduleObject);
  }

  //Bank Account Member
  getBankAccount() {
    const url = Settings.API_URL + 'wallet/bankaccount';
    return this.get(url);
  }

  addBankAccount(bankAccount) {
    const url = Settings.API_URL + 'wallet/bankaccount';
    return this.post(url, bankAccount);
  }

  updateBankAccount(bankAccount) {
    const url = Settings.API_URL + 'wallet/bankaccount';
    return this.put(url, bankAccount);
  }

  //Bank Account Agent
  //get last 4 of bank account - used in member profile
  getBankAccountsForMember(memberId) {
    const url =
      Settings.API_URL + 'wallet/member/' + memberId + '/bankaccounts';
    return this.get(url);
  }
  //full bank account - used in member profile
  getFullBankAccountForMember(memberId) {
    const url =
      Settings.API_URL + 'wallet/member/' + memberId + '/fullbankaccounts';
    return this.get(url);
  }

  //full bank account during enrollment for enrollmentStateId=1(in progress)
  getBankAccountForEnrollmentForMember(memberId) {
    const url =
      Settings.API_URL + 'wallet/member/' + memberId + '/enrollmentbank';
    return this.get(url);
  }

  addBankAccountForMember(bankAccount) {
    const url = Settings.API_URL + 'wallet/member/bankaccount';
    return this.post(url, bankAccount);
  }

  updateBankAccountForMember(bankAccount) {
    const url = Settings.API_URL + 'wallet/member/bankaccount';
    return this.put(url, bankAccount);
  }

  deleteBankAccount(bankAccountId) {
    const url = Settings.API_URL + 'wallet/bankaccount/delete/' + bankAccountId;
    return this.post(url);
  }

  unblockBankAccount(bankAccountId) {
    const url =
      Settings.API_URL + 'wallet/bankaccount/unblock/' + bankAccountId;
    return this.put(url);
  }

  //Prenote
  getFailedPrenote(memberId) {
    const url =
      Settings.API_URL + 'wallet/prenote/getfailedaccount/' + memberId;
    return this.get(url);
  }

  insertBankAccountForPrenote(bankAccount) {
    const url = Settings.API_URL + 'wallet/prenote/insertnewaccount';
    return this.post(url, bankAccount);
  }

  getBankFromRouting(routingNumber) {
    const url = Settings.API_URL + 'wallet/routing/' + routingNumber;
    return this.get(url);
  }

  //Add the resetPaymentSchedule method in wallet-service.js:
  resetPaymentSchedule(scheduleObject) {
    const url = Settings.API_URL + `wallet/member/updatenextoccurrence`;
    return this.put(url, scheduleObject);
  }
}

const walletService = new internalWalletService();

export default walletService;
