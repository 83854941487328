import React, { useEffect, useState } from 'react';

//Mui X
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';

//MUI Components
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';

//MUI Icons
import WorkIcon from '@mui/icons-material/Work';
import WorkOffIcon from '@mui/icons-material/WorkOff';
import CloseIcon from '@mui/icons-material/Close';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

//Utils
import {
  year,
  month,
  day,
  formatPhoneNumber,
  formatDateObjYYYYMMDD,
  formatUTCDateToObjWithTimeZone,
} from '../../../utils';

//services
import optionService from '../../../../services/optionService';
import memberService from '../../../../services/member-service';
import { Icon } from '@mui/material';

export default function PortalPersonalSummary(props) {
  const [workStatuses, setWorkStatuses] = useState([]);
  const getWorkStatuses = async () => {
    let _statuses = await optionService.getWorkStatus();
    if (_statuses) {
      setWorkStatuses(_statuses);
    }
  };
  const [genderOptions, setGenderOptions] = useState([]);
  const getGenderOptions = async () => {
    let _gender = await optionService.getGenderTypes();
    if (_gender) {
      setGenderOptions(_gender);
    }
  };
  const [languageTypes, setLanguageTypes] = useState([]);
  const getLanguageTypes = async () => {
    let _language = await optionService.getLanguageTypes();
    if (_language) {
      setLanguageTypes(_language);
    }
  };

  useEffect(() => {
    if (workStatuses?.length === 0) getWorkStatuses();
    if (genderOptions?.length === 0) getGenderOptions();
    if (languageTypes?.length === 0) getLanguageTypes();
  }, []);

  let preferredPhone =
    props?.thisMemberPhone?.length > 0 &&
    props?.thisMemberPhone.find((phone) => phone.isPreferred);

  let workIcon = (workStatusId) => {
    if (workStatusId === 1) {
      return <WorkIcon />;
    } else {
      return <WorkOffIcon />;
    }
  };

  const [memberInfo, setMemberInfo] = useState(props.thisMember);
  useEffect(() => {
    let _member = props.thisMember;
    if (_member.dob?.split) {
      _member.dob = formatUTCDateToObjWithTimeZone(_member.dob);
    }
    setMemberInfo(_member);
  }, [props.thisMember]);

  const [memberEdit, setMemberEdit] = useState(false);

  const handleMemberUpdate = (e) => {
    let key;
    let value;
    if (!e?.target) {
      // if (e < dateMinObj) return;
      key = 'returnToWork';
      value = e;
    } else {
      key = e.target.name;
      value = e.target.value;
    }
    setMemberInfo({ ...memberInfo, [key]: value });
  };

  const handleMemberSave = async () => {
    if (memberInfo?.workStatusId != 1 && !returnToWorkDrawerOpen) {
      setReturnToWorkDrawerOpen(true);
      return;
    }
    setReturnToWorkDrawerOpen(false);
    try {
      await memberService.updateMember(memberInfo);
    } catch (error) {
      console.error(error);
    }
    await props.getThisMember();
    setUpdated(true);
  };

  const handleEditCancel = async () => {
    setMemberEdit(false);
    setMemberInfo(props.thisMember);
  };

  const handleDobChange = (e) => {
    let inputVal = e;
    // inputVal = format(new Date(e), 'yyyy-MM-dd')
    // let dateWithinRange = ((new Date(inputVal).getFullYear() >= new Date(dateMin).getFullYear()) && (new Date(inputVal).getFullYear() <= new Date(dateMax).getFullYear()))

    // if (dateWithinRange) {
    props.setUserInfo({
      ...props.userInfo,
      dob: inputVal,
    });
    // }
    // checkAllow();
  };

  const [returnToWorkDrawerOpen, setReturnToWorkDrawerOpen] = useState(false);

  let dateMin = `${year - 100}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);

  const [updated, setUpdated] = useState(false);
  useEffect(() => {
    if (updated) {
      setTimeout(() => {
        setUpdated(false);
      }, 1500);
    }
  }, [updated]);

  return (
    <Box className="flex-row" sx={{ flexWrap: 'wrap' }}>
      <Box
        className="col-6"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between',
        }}
      >
        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          label="Name"
          value={
            props.thisMember?.firstName + ' ' + props.thisMember?.lastName || ''
          }
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          fullWidth
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
        />
        {/* <Typography variant="caption">
          {props.thisMember?.firstName + ' ' + props.thisMember?.lastName}
        </Typography> */}

        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          label="Phone Number"
          value={formatPhoneNumber(preferredPhone.number) || ''}
          fullWidth
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
        />

        <Box className="col-12">
          <TextField
            disabled={!memberEdit}
            select
            className="col-12"
            variant="standard"
            label="Gender"
            name="gender"
            InputProps={{
              disableUnderline: !memberEdit && true,
            }}
            value={memberInfo?.gender || ''}
            sx={{
              marginBottom: '10px',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
                // fontSize: '12px',
                // lineHeight: '12px',
              },
              '& .MuiInputLabel-root.Mui-disabled': {
                // WebkitTextFillColor: '#000000',
                color: 'text.secondary',
                // fontSize: '12px',
                // lineHeight: '12px',
                // height: '12px',
              },
              '& .MuiSelect-icon.Mui-disabled': {
                display: 'none',
              },
            }}
            fullWidth
            onChange={handleMemberUpdate}
          >
            {genderOptions.map((gender) => (
              <MenuItem key={gender.id} value={gender.id}>
                {gender.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="col-12">
          <TextField
            disabled={!memberEdit}
            select
            className="col-12"
            variant="standard"
            label="Language Preference"
            name="languageTypeId"
            InputProps={{
              disableUnderline: !memberEdit && true,
            }}
            value={memberInfo?.languageTypeId || 1}
            sx={{
              marginBottom: '10px',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
                // fontSize: '12px',
                // lineHeight: '12px',
              },
              '& .MuiInputLabel-root.Mui-disabled': {
                // WebkitTextFillColor: '#000000',
                color: 'text.secondary',
                // fontSize: '12px',
                // lineHeight: '12px',
                // height: '12px',
              },
              '& .MuiSelect-icon.Mui-disabled': {
                display: 'none',
              },
            }}
            fullWidth
            onChange={handleMemberUpdate}
          >
            {languageTypes.map((lang) => (
              <MenuItem key={lang.id} value={lang.id}>
                {lang.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Box
        className="col-6"
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignContent: 'space-between',
        }}
      >
        {/* <TextField
          disabled
          className="col-12"
          variant="standard"
          label="Name"
          value={memberInfo.dob || ''}
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          fullWidth
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            fullWidth
            disabled={!memberEdit}
            disableFuture
            label="Date of Birth"
            openTo="year"
            views={['year', 'month', 'day']}
            format="MM/dd/yyyy"
            minDate={dateMinObj}
            maxDate={dateMaxObj}
            value={new Date(memberInfo.dob) || null}
            onChange={handleDobChange}
            // onAccept={handleDobBlur}
            // onError={(error) => setDobError(error)}
            sx={{ width: '100%' }}
            slotProps={{
              textField: {
                // helperText: dobErrorMessage,
                disabled: !memberEdit,
                className: 'col-12',
                variant: 'standard',
                sx: {
                  '& .MuiInputBase-input.Mui-disabled': {
                    WebkitTextFillColor: '#000000',
                    // fontSize: '12px',
                    // lineHeight: '12px',
                  },
                  '& .MuiInputLabel-root.Mui-disabled': {
                    // WebkitTextFillColor: '#000000',
                    color: 'text.secondary',
                    // fontSize: '12px',
                    // lineHeight: '12px',
                    // height: '12px',
                  },
                },
                fullWidth: true,
                InputProps: {
                  disableUnderline: !memberEdit && true,
                },
              },
              openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
            }}
          />
        </LocalizationProvider>
        {/* <Typography variant="caption">
          {props.thisMember?.firstName + ' ' + props.thisMember?.lastName}
        </Typography> */}

        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          label="Address"
          value={memberInfo.street1 || ''}
          fullWidth
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
        />
        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          // label="Address"
          value={memberInfo.street2 || ''}
          fullWidth
          sx={{
            marginBottom: '4px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
          inputProps={{ style: { paddingTop: '0px' } }}
        />

        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          label="Email Address"
          value={memberInfo.email || ''}
          fullWidth
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
        />
        <TextField
          disabled={!memberEdit}
          className="col-12"
          variant="standard"
          // label="Address"
          value={memberInfo.secondEmail || ''}
          fullWidth
          sx={{
            marginBottom: '4px',
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: '#000000',
              // fontSize: '12px',
              // lineHeight: '12px',
            },
            '& .MuiInputLabel-root.Mui-disabled': {
              // WebkitTextFillColor: '#000000',
              color: 'text.secondary',
              // fontSize: '12px',
              // lineHeight: '12px',
              // height: '12px',
            },
          }}
          InputProps={{
            disableUnderline: !memberEdit && true,
          }}
          inputProps={{ style: { paddingTop: '0px' } }}
        />

        <Box className="col-12">
          <TextField
            disabled={!memberEdit}
            select
            className="col-12"
            variant="standard"
            label="Working Status"
            name="workStatusId"
            InputProps={{
              // startAdornment: (
              //   <InputAdornment position="start">
              //     {workIcon(memberInfo?.workStatusId)}
              //   </InputAdornment>
              // ),
              disableUnderline: !memberEdit && true,
            }}
            value={memberInfo?.workStatusId || ''}
            sx={{
              marginBottom: '10px',
              '& .MuiInputBase-input.Mui-disabled': {
                WebkitTextFillColor: '#000000',
                // fontSize: '12px',
                // lineHeight: '12px',
              },
              '& .MuiInputLabel-root.Mui-disabled': {
                // WebkitTextFillColor: '#000000',
                color: 'text.secondary',
                // fontSize: '12px',
                // lineHeight: '12px',
                // height: '12px',
              },
              '& .MuiSelect-icon.Mui-disabled': {
                display: 'none',
              },
            }}
            fullWidth
            onChange={handleMemberUpdate}
          >
            {workStatuses.map((status) => (
              <MenuItem key={status.id} value={status.id}>
                {status.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      {props.thisMember?.workStatusId != 1 && (
        <Box
          className="flex-row"
          sx={{
            justifyContent: 'space-between',
            fontSize: '14px',
            marginBottom: '10px',
            marginTop: '10px',
            color: 'text.secondary',
          }}
        >
          <Typography variant="body1">
            Estimated Return Date:{' '}
            {(props.thisMember?.returnToWork &&
              formatDateObjYYYYMMDD(
                formatUTCDateToObjWithTimeZone(props.thisMember?.returnToWork)
              )) ||
              'Not Set'}
          </Typography>

          <Fade in={updated}>
            <Typography
              variant="body1"
              sx={{
                color: 'color.recommendation',
                // display: 'flex',
                // alignItems: 'center',
              }}
            >
              <Icon
                sx={{ fontSize: '24px', display: 'contents' }}
                // fontSize="24px"
                children={<CheckRoundedIcon />}
              ></Icon>
              Updated
            </Typography>
          </Fade>
        </Box>
      )}
      {/* commented out edit/save/cancel button for mvp */}
      {/* <Box
        className="col-12"
        sx={{ display: 'flex', justifyContent: 'flex-end' }}
      >
        {memberEdit ? (
          <Box
            className="col-12"
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <Button
              sx={{
                // borderRadius: '8px',
                // background: 'var(--Light-Primary-Main, #195FFB)',
                // boxShadow:
                //   '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
                fontFamily: 'Archivo',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '22px',
                letterSpacing: '.46px',
              }}
              // disabled={
              //   memberInfo?.workStatusId != 1 &&
              //   memberInfo?.returnToWork == null
              // }
              variant="outlined"
              onClick={handleEditCancel}
            >
              Cancel
            </Button>
            <Button
              sx={{
                // borderRadius: '8px',
                // background: 'var(--Light-Primary-Main, #195FFB)',
                // boxShadow:
                //   '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
                fontFamily: 'Archivo',
                fontWeight: 500,
                fontSize: '13px',
                lineHeight: '22px',
                letterSpacing: '.46px',
              }}
              // disabled={
              //   memberInfo?.workStatusId != 1 &&
              //   memberInfo?.returnToWork == null
              // }
              // disabled={true}
              variant="contained"
              size="small"
              onClick={handleMemberSave}
            >
              Save
            </Button>
          </Box>
        ) : (
          <Button
            sx={{
              // borderRadius: '8px',
              // background: 'var(--Light-Primary-Main, #195FFB)',
              // boxShadow:
              //   '0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);',
              fontFamily: 'Archivo',
              fontWeight: 500,
              fontSize: '13px',
              lineHeight: '22px',
              letterSpacing: '.46px',
            }}
            // disabled={
            //   memberInfo?.workStatusId != 1 &&
            //   memberInfo?.returnToWork == null
            // }
            disabled={true}
            variant="contained"
            size="small"
            onClick={() => setMemberEdit(true)}
          >
            Edit
          </Button>
        )}
      </Box> */}

      <Drawer
        open={returnToWorkDrawerOpen}
        onClose={() => setReturnToWorkDrawerOpen(false)}
        anchor="bottom"
        sx={{ maxHeight: '200px' }}
        PaperProps={{
          sx: {
            borderRadius: '12px 12px 0px 0px',
            padding: '0px 16px 24px',
            bgcolor: 'background.default',
            color: 'text.primary',
            maxHeight: '75%',
          },
        }}
        disableScrollLock={true}
      >
        <Box
          sx={{
            bgcolor: 'paper.background.default',
            color: 'text.primary',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          <Box
            className="row"
            sx={{
              marginBottom: '20px',
              backgroundColor: 'white',
              position: 'sticky',
              top: 0,
              paddingTop: '16px',
            }}
          >
            <Typography
              className="col-10"
              variant="blueAccent"
              sx={{ fontSize: '24px' }}
            >
              Confirm Work Status
            </Typography>
            <Box className="col-2" sx={{ textAlign: 'end' }}>
              <IconButton onClick={() => setReturnToWorkDrawerOpen(false)}>
                <CloseIcon sx={{ color: '#195ffb' }} />
              </IconButton>
            </Box>
          </Box>
          <Box className="col-12" sx={{ marginBottom: '20px' }}>
            <Typography variant="body1" sx={{ fontSize: '14px' }}>
              Please note, if you are not actively working you cannot receive
              benefits and payments will not be withdrawn.
            </Typography>
          </Box>

          <Box className="col-12" sx={{ marginBottom: '25px' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                // disableFuture
                label="Estimated Return Date*"
                openTo="month"
                views={['year', 'month', 'day']}
                format="MM/dd/yyyy"
                name="returnToWork"
                minDate={dateMinObj}
                // maxDate={dateMaxObj}
                value={new Date(memberInfo?.returnToWork) || null}
                onChange={handleMemberUpdate}
                // onAccept={handleDobBlur}
                // onError={(error) => setDobError(error)}
                sx={{ width: '100%' }}
                slotProps={{
                  textField: {
                    // helperText: dobErrorMessage,
                  },
                  openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                }}
              />
            </LocalizationProvider>
          </Box>
          <Box className="col-12" sx={{ textAlign: 'center' }}>
            <Button
              disabled={memberInfo?.returnToWork < dateMinObj}
              fullWidth
              variant="contained"
              onClick={handleMemberSave}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
}
