import React, { useEffect, useState } from 'react';

//services

// Assets

//Mui Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Divider,
  Alert,
} from '@mui/material';
//Mui icons
//Our Components

//utils
import {
  formatUTCDateToObjWithTimeZone,
  formatDateObjYYYYMMDD,
  formatDateObjMMDDYYYYDisplay,
  formatUTCToShortDate,
  formatUTCYYYYMMDD,
  todayDateObj, // formatted to
  formatCurrency,
} from '../../../../../../../utils';

// component styles
const componentStyles = {
  paymentInfoContainer: {
    backgroundColor: 'background.paymentModalbg',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '14px',
    margin: '0 auto 20px',
    padding: 2,
  },
  subTitleText: {
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '160%', // 25.6px
    letterSpacing: '0.15px',
    textAlign: 'left',
  },
  paymentDatesformat: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    mr: 2, // Add margin-right to provide space between the date and the alert
  },
  alertSizing: {
    display: 'flex',
    width: '180px',
    height: '24px',
    padding: '2px 2px',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,
    backgroundColor: 'transparent', // Ensure no background
  },
  paymentAmountformat: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    ml: 2,
  },
  dialogTitlestyle: {
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '32px',
    letterSpacing: '0.15px',
    padding: 0,
    marginBottom: '16px',
    ml: 2.5,
  },
  dialogCommentary: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    ml: 2.5,
  },
  dialogEndingText: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '55px', // different from the rest
    letterSpacing: '0.15px',
    ml: 2,
  },
  oneTimePaymentAlert: {
    backgroundColor: 'background.onMedicalLeaveChip',
    color: 'primary.onMedicalLeaveChip',
    // color: 'var(--Light-Info-Dark, #01579B)', from figma
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px', // 138.462%
    letterSpacing: '0.16px',
    padding: '3px 4px',
    height: '24px',
    width: '150px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    textAlign: 'center',
  },
  automaticAlert: {
    backgroundColor: 'background.activeWorking',
    color: 'primary.activeChip',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.16px',
    padding: '3px 4px',
    height: '24px',
    width: '150px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    textAlign: 'center',
  },
  failedPaymentAlert: {
    backgroundColor: 'background.inProgressChip',
    color: 'primary.inProgressChip',
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '18px',
    letterSpacing: '0.16px',
    padding: '3px 4px',
    height: '24px',
    width: '175px',
    boxSizing: 'border-box',
    borderRadius: '3px',
    textAlign: 'center',
  },
  dividerStyle: {
    // my: 2,
    margin: '20px 0px 10px',
    width: '90%',
    borderBottomWidth: '2px',
    borderColor: '#C0C0C0', // Lighter color for the divider
  },
  modalBox: {
    display: 'flex',
    width: '100%',
    maxWidth: '508px',
    height: '100%',
    maxHeight: '90vh',
    padding: '10px 12px',
    flexDirection: 'column',
  },
  insideOnetimePayment: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-start',
    marginBottom: '6px',
  },
  alignBoxes: {
    display: 'flex',
    alignItems: 'center',
    mt: 1,
  },
  alignOneBox: {
    display: 'flex',
    alignItems: 'center',
  },
  endingBoxstyle: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  typographyItalic: {
    variant: 'caption',
    display: 'block',
    mt: 2,
    fontStyle: 'italic',
    fontSize: '12px',
    textAlign: 'right',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Archivo',
    fontWeight: 400,
    lineHeight: '166%' /* 19.92px */,
    letterSpacing: '0.4px',
  },
};

export function MakePaymentModal(props) {
  //console log to be removed at the end of testing ----
  // console.log('make payment modal', props);

  // attempted to use formatDateObjMMDDYYYYDisplay but returned invalid date for the failed rerun payment its a string not a date object
  function formatDate(dateStr) {
    const [year, month, day] = dateStr.split('-');
    return `${month}/${day}/${year}`;
  }

  //this is to make the divider line between alerts only appear if there are actually alerts
  const hasNewPayment = !!props.newPaymentObj;
  const hasFailedPaymentRerun =
    !!props.currentCombinedPaymentInfo?.scheduleHasNextOccWithin5DaysInPast;
  const hasAutoPayment =
    !!props.currentCombinedPaymentInfo?.activePaymentSchedule;

  const [schPaymentAmountToDisplay, setSchPaymentAmountToDisplay] = useState(0);
  //find payment amount to display for scheduled payment if semi-monthly amounts are different
  const findSchPaymentAmountToDisplay = () => {
    //get the day of the month for the next payment date from props.currentCombinedPaymentInfo?.nextScheduledPaymentDate
    let nextPaymentDateObj = props.currentCombinedPaymentInfo
      ?.nextScheduledPaymentDateShort
      ? formatUTCDateToObjWithTimeZone(
          props.currentCombinedPaymentInfo?.nextScheduledPaymentDate
        )
      : null;
    let nextPaymentDayOfMonth = nextPaymentDateObj
      ? nextPaymentDateObj.getDate()
      : null;

    //create array of objects matching day of month for each payment amount
    let datesAndAmounts =
      props?.currentCombinedPaymentInfo?.activePaymentSchedule?.scheduledPaymentDates
        ?.sort((a, b) => a.dayOfMonth - b.dayOfMonth)
        .map((date, index) => {
          return {
            dayOfMonth: date.dayOfMonth,
            paymentAmount:
              props?.currentCombinedPaymentInfo?.activePaymentSchedule
                ?.payments[index + 1],
          };
        });

    //find the payment amount that matches next payment date
    let _paymentAmountToDisplay = datesAndAmounts.find(
      (day) => day.dayOfMonth === nextPaymentDayOfMonth
    )?.paymentAmount;
    if (_paymentAmountToDisplay > 0) {
      setSchPaymentAmountToDisplay(_paymentAmountToDisplay);
    } else {
      setSchPaymentAmountToDisplay(0);
    }
  };

  useEffect(() => {
    if (props?.currentCombinedPaymentInfo?.activePaymentSchedule) {
      findSchPaymentAmountToDisplay();
    }
  }, [
    props?.currentCombinedPaymentInfo?.activePaymentSchedule
      ?.scheduledPaymentDates,
  ]);

  return (
    <Dialog
      open={props.openModal}
      onClose={props.handleCloseModal}
      maxWidth="sm"
    >
      <Box sx={componentStyles.modalBox}>
        <DialogTitle sx={componentStyles.dialogTitlestyle}>
          Are you sure?
        </DialogTitle>

        {/* following section commentary after title and before content */}
        <DialogContent sx={{ width: '100%', padding: 0 }}>
          <Typography sx={componentStyles.dialogCommentary}>
            You are attempting to schedule a payment that may be within the same
            time frame as existing payments. Proceeding may result in a
            duplicate charge.
          </Typography>
          <Box mt={2} />

          {/*----------- This payment: + Pending Upcoming Payments: section begins----------- */}
          {/* one time payment */}
          <Box sx={componentStyles.paymentInfoContainer}>
            <Box sx={componentStyles.insideOnetimePayment}>
              <Typography sx={componentStyles.subTitleText}>
                This payment:
              </Typography>
            </Box>

            {/* -------------onetime payment about to be created alert box------------- */}

            {props.newPaymentObj && (
              <Box sx={componentStyles.alignBoxes}>
                {/* {onetimePaymentDate} */}
                <Typography sx={componentStyles.paymentDatesformat}>
                  {props.newPaymentObj?.paymentDate
                    ? formatDateObjMMDDYYYYDisplay(
                        props.newPaymentObj?.paymentDate
                      )
                    : ''}
                </Typography>
                <Alert
                  severity="info"
                  icon={false}
                  sx={componentStyles.alertSizing}
                >
                  <Typography sx={componentStyles.oneTimePaymentAlert}>
                    ONE TIME PAYMENT
                  </Typography>
                </Alert>
                <Typography sx={componentStyles.paymentAmountformat}>
                  {/* ${onetimePaymentAmount} */}
                  {props.newPaymentObj?.paymentAmount
                    ? formatCurrency(
                        props?.newPaymentObj?.paymentAmount +
                          props?.transactionFee //calculated in makePaymentDrawer based on date selected and scheduled payment running that day
                      )
                    : ''}
                </Typography>
              </Box>
            )}
          </Box>

          {/* PENDING UPCOMING PAYMENTS */}
          <Box sx={componentStyles.paymentInfoContainer}>
            <Box sx={componentStyles.insideOnetimePayment}>
              <Typography sx={componentStyles.subTitleText}>
                Pending Upcoming Payments:
              </Typography>
            </Box>

            {/* -------------onetime payment box number 2 ------------- */}
            {/* disscussed this  futureOneTimePayment: null, //render message and modal if this is true (blue chip in modal) */}
            {/* newPaymentObj was used in the previous alert box does display new one time upcoming  */}
            {props?.currentCombinedPaymentInfo
              ?.scheduleHasNextOccWithin5DaysInPast && (
              <Box sx={componentStyles.alignBoxes}>
                {/* {onetimePaymentDate} */}
                <Typography sx={componentStyles.paymentDatesformat}>
                  {/* date wasnt being displayed correctly investigating in PaymentInputs.js */}

                  {props.currentCombinedPaymentInfo
                    ?.scheduleHasNextOccWithin5DaysInPast
                    ? formatDateObjMMDDYYYYDisplay(todayDateObj)
                    : ''}
                </Typography>
                <Alert
                  severity="warning"
                  icon={false}
                  sx={componentStyles.alertSizing}
                >
                  <Typography sx={componentStyles.failedPaymentAlert}>
                    FAILED PAYMENT RERUN
                  </Typography>
                </Alert>
                <Typography sx={componentStyles.paymentAmountformat}>
                  {/* ${failedPaymentAmount} */}
                  {props.currentCombinedPaymentInfo
                    ?.scheduleHasNextOccWithin5DaysInPast?.paymentAmount &&
                  props.currentCombinedPaymentInfo
                    ?.scheduleHasNextOccWithin5DaysInPast?.paymentAmount >= 0
                    ? formatCurrency(
                        props.currentCombinedPaymentInfo
                          ?.scheduleHasNextOccWithin5DaysInPast?.paymentAmount +
                          props.currentCombinedPaymentInfo
                            ?.scheduleHasNextOccWithin5DaysInPast?.transactionFee
                      )
                    : ''}
                </Typography>
              </Box>
            )}

            {/* -------------onetime payment box number 2 ------------- */}
            {/* disscussed this  futureOneTimePayment: null, //render message and modal if this is true (blue chip in modal) */}
            {/* newPaymentObj was used in the previous alert box does display new one time upcoming  */}
            {props?.currentCombinedPaymentInfo?.futureOneTimePayment && (
              <>
                {/* conditionall render divider if failed payment rerun exists */}
                {props?.currentCombinedPaymentInfo
                  ?.scheduleHasNextOccWithin5DaysInPast && (
                  <Divider sx={componentStyles.dividerStyle} />
                )}
                <Box sx={componentStyles.alignBoxes}>
                  {/* {onetimePaymentDate} */}
                  <Typography sx={componentStyles.paymentDatesformat}>
                    {props?.currentCombinedPaymentInfo?.futureOneTimePayment
                      ?.occurrence
                      ? formatUTCToShortDate(
                          props?.currentCombinedPaymentInfo
                            ?.futureOneTimePayment?.occurrence
                        )
                      : ''}
                  </Typography>
                  <Alert
                    severity="info"
                    icon={false}
                    sx={componentStyles.alertSizing}
                  >
                    <Typography sx={componentStyles.oneTimePaymentAlert}>
                      ONE TIME PAYMENT
                    </Typography>
                  </Alert>
                  <Typography sx={componentStyles.paymentAmountformat}>
                    {/* ${onetimePaymentAmount} */}
                    {props?.currentCombinedPaymentInfo?.futureOneTimePayment
                      ?.paymentAmount
                      ? formatCurrency(
                          props?.currentCombinedPaymentInfo
                            ?.futureOneTimePayment?.paymentAmount
                        )
                      : ''}
                  </Typography>
                </Box>
              </>
            )}

            {/*-------------auto payment box------------- */}
            {props.currentCombinedPaymentInfo?.activePaymentSchedule &&
              props?.currentCombinedPaymentInfo
                ?.nextScheduledPaymentDateShort <=
                formatUTCYYYYMMDD(
                  props?.currentCombinedPaymentInfo?.activePaymentSchedule
                    ?.endPaymentDate
                ) && (
                <>
                  {(props?.currentCombinedPaymentInfo
                    ?.scheduleHasNextOccWithin5DaysInPast ||
                    props?.currentCombinedPaymentInfo
                      ?.futureOneTimePayment) && (
                    <Divider sx={componentStyles.dividerStyle} />
                  )}
                  <Box display="flex" alignItems="center" mt={1}>
                    {/* automatic payment date */}
                    <Typography sx={componentStyles.paymentDatesformat}>
                      {props.currentCombinedPaymentInfo
                        ?.activePaymentSchedule &&
                      props.currentCombinedPaymentInfo?.nextScheduledPaymentDate
                        ? formatUTCToShortDate(
                            props.currentCombinedPaymentInfo
                              ?.nextScheduledPaymentDate
                          )
                        : ''}
                    </Typography>

                    <Alert
                      severity="success"
                      icon={false}
                      sx={componentStyles.alertSizing}
                    >
                      <Typography sx={componentStyles.automaticAlert}>
                        AUTOMATIC
                      </Typography>
                    </Alert>
                    <Typography sx={componentStyles.paymentAmountformat}>
                      {/* ${automaticPaymentAmount} */}
                      {props.currentCombinedPaymentInfo?.activePaymentSchedule
                        ?.paymentAmount &&
                      props.currentCombinedPaymentInfo?.activePaymentSchedule
                        ?.transactionFee >= 0
                        ? formatCurrency(
                            schPaymentAmountToDisplay +
                              props.currentCombinedPaymentInfo
                                ?.activePaymentSchedule?.transactionFee
                          )
                        : ''}
                    </Typography>
                  </Box>
                </>
              )}

            {/* section after/right under alerts italic with the * */}

            <Box sx={componentStyles.endingBoxstyle}>
              <Typography sx={componentStyles.typographyItalic}>
                *Scheduled transactions are not guaranteed to be successful
              </Typography>
            </Box>
          </Box>

          {/* SECTION AFTER DIVIDER */}
          <Divider component="" />
          <Typography sx={componentStyles.dialogEndingText}>
            Do you wish to proceed with this new payment?
          </Typography>
        </DialogContent>
        {/* Yes Or Nevermind Buttons */}
        <DialogActions>
          <Button onClick={props.handleCloseModal} color="primary">
            NEVERMIND
          </Button>
          <Button
            onClick={() => {
              if (props.selectedPaymentTransactionType === 1) {
                props.handleSchedulePaymentClick();
              } else {
                props.handleIssuePayoutClick();
              }
              props.handleCloseModal(); // Closes the modal after handling the payment
            }}
            color="primary"
            variant="contained"
          >
            YES, SCHEDULE PAYMENT
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
