import React, { useState } from 'react';

//MUI Components
import { Box, Typography } from '@mui/material';

//Assets
import FallbackLogo from '../../../assets/images/SourceFileonly icon.png';

//Component Styles
const componentStyles = {
  logoContainer: {
    display: 'flex',
    margin: '6px auto',
    justifyContent: 'center',
    minHeight: '80px',
    minWidth: '80px',
  },
  image: {
    maxHeight: '86px',
    minHeight: '80px',
    width: '100%',
    objectFit: 'contain',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '5px',
    justifyContent: 'center',
    textAlign: 'center',
  },
};

const ExternalRegisterOrLoginEnrollmentHeader = (props) => {
  //fallback logo logic
  const [imgFetchAttempts, setImgFetchAttempts] = useState(0);
  const handleLogoError = (e) => {
    setImgFetchAttempts((prev) => prev++);
    if (imgFetchAttempts <= 10) e.target.src = FallbackLogo;
  };

  return (
    <>
      <Box sx={componentStyles.logoContainer}>
        <img
          src={
            props?.organization?.id > 0
              ? `/api/image/organization/${props?.organization.id}`
              : '/api/image/organization'
          }
          style={componentStyles.image}
          onError={handleLogoError}
          alt="Organization Logo"
        />
      </Box>

      {/* Title */}
      {props?.forgotPassword ? (
        <Box sx={componentStyles.titleContainer}>
          <Typography variant="subtitle" fontWeight="600">
            Forgot your password?
          </Typography>
        </Box>
      ) : (
        <>
          {props?.organization?.name != 'Teamsters' && (
            <Box sx={componentStyles.titleContainer}>
              <Typography variant="subtitle" fontWeight="600">
                {props?.login ? 'Log in to' : 'Register'} your UnionHub account
              </Typography>
            </Box>
          )}
          {props?.organization?.name == 'Teamsters' && (
            <Box sx={componentStyles.titleContainer}>
              <Typography variant="subtitle" fontWeight="600">
                {props?.login ? 'Log in to' : 'Register'} your Teamsters VIP+
              </Typography>
              <Typography variant="subtitle" fontWeight="600">
                UnionHub account
              </Typography>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default ExternalRegisterOrLoginEnrollmentHeader;
