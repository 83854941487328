import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  PieChart,
  Pie,
  Cell,
  LabelList,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Bar,
  Label,
  ResponsiveContainer,
  Legend,
  Tooltip,
  PolarAngleAxis,
  RadialBarChart,
  RadialBar,
} from 'recharts';

//Mui components
import { Card, CardHeader, Slide, Typography } from '@mui/material';
import { Tooltip as MuiTooltip } from '@mui/material/';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';

//Mui icons
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

//Services
import dashboardService from '../../../../../services/dashboard-service';
// import EnrollmentsByDate from './EnrollmentsByDateAndCoverage-reports/EnrollmentsByDate';
// import EnrollmentsByCoverage from './EnrollmentsByDateAndCoverage-reports/EnrollmentsByCoverage';

//Our components
import InfinityLoader from '../../../../UH-loading-animation/InfinityLoader';

//Our Styles
import reportingDashboardStyles from '../../reportingDashboardStyles';

export default function PostEnrollmentOptionSelection(props) {
  const [loading, setLoading] = useState(false);

  const [results, setResults] = useState([]);
  const getReportResults = async () => {
    setLoading(true);
    try {
      let _results = await dashboardService.getPremiumsWithDescription(
        props.groupPlanId,
        props.enrollmentWindowId
      );
      setResults(_results);
    } catch (error) {
      console.error('error getting PostEnrollmentOptionSelection', error);
      setLoading(false);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (props.groupPlanId && props.enrollmentWindowId) {
      if (props.enrollmentWindows?.length > 0) {
        let validId = false;
        for (let window of props.enrollmentWindows) {
          if (window.id == props.enrollmentWindowId) {
            validId = true;
            break;
          }
        }
        if (validId) {
          getReportResults();
          let _selectedEnrollmentWindowObj = props?.enrollmentWindows?.find(
            (window) => window.id === props?.enrollmentWindowId
          );
          setSelectedEnrollmentWindowObj(_selectedEnrollmentWindowObj);
          props.setSelectedEnrollmentWindowObjParent(
            _selectedEnrollmentWindowObj
          );
        }
      }
    }
  }, [props.enrollmentWindowId, props.enrollmentWindows]);

  const [selectedEnrollmentWindowObj, setSelectedEnrollmentWindowObj] =
    useState({});

  const theme = useTheme();

  let tooltip;
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Paper sx={{ padding: '20px' }}>
          <p className="label">{`${label}`}</p>
          {payload.map((bar, index) => {
            return (
              <p>
                {bar.payload.name[index]}: {bar.payload.count[index]}{' '}
                Enrollment(s)
              </p>
            );
          })}
        </Paper>
      );
    }
    return null;
  };

  const renderBarShape = (props) => {
    const getPath = (x, y, width, height) => {
      return `M${x},${y}
         v${height}
         h${width - 4}
         a4,4 0 0 0 4,-4
         v${-height + 8}
         a4,4 0 0 0 -4,-4
         h${-width}
         z
         `;
    };
    return (
      <path
        {...props}
        d={getPath(props.x, props.y, props.width, props.height)}
        stroke={props.stroke}
        fill={props.fill}
        strokeLinejoin="round"
        strokeWidth={props.strokeWidth}
        strokeLinecap="round"
      />
    );
  };

  const [reportData, setReportData] = useState([]);
  const [totalPotentialBars, setTotalPotentialBars] = useState(0);
  const buildReportData = (results) => {
    setLoading(true);
    let reportData = [];
    let planData = [];

    //need to have an object per plan, and then a count for each option
    let totalPotentialBars = 0;
    for (let resultObj of results) {
      //if not an array, make an array
      resultObj.type = resultObj.type.toUpperCase();
      if (!planData.some((plan) => plan.type == resultObj.type)) {
        planData.push({
          type: resultObj.type,
          name: [resultObj.desc],
          count: [resultObj.count],
        });
      } else {
        let plan = planData.find((plan) => plan.type == resultObj.type);
        plan.count.push(resultObj.count);
        plan.name.push(resultObj.desc);

        let amountOfOptions = plan.count?.length;
        if (amountOfOptions > totalPotentialBars)
          totalPotentialBars = amountOfOptions;
      }
      //push the new option into the array

      //this lets us know how many bars to render
      // let amountOfOptions = planData[resultObj.type]?.length;
      // if (amountOfOptions > totalPotentialBars)
      //   totalPotentialBars = amountOfOptions;

      // planData[resultObj.type] = {...planData[resultObj.type], [resultObj.desc]: resultObj.count}
      // planData[resultObj.type][resultObj.desc] = resultObj.count
    }
    if (totalPotentialBars == 0) totalPotentialBars = 1;
    setTotalPotentialBars(totalPotentialBars);
    setReportData(planData);
    setLoading(false);
  };
  useEffect(() => {
    if (results?.length > 0) buildReportData(results);
  }, [results]);

  const [colors, setColors] = useState([
    theme?.palette?.primary?.main,
    '#906FDC',
    '#5387FC',
    theme?.palette?.secondary?.main,
  ]);

  return (
    <>
      {loading && (
        <>
          <InfinityLoader
            style={reportingDashboardStyles.individualReportInfinityLoader}
          />
          <style>
            {`
                  div.lottie-div > svg {
                     height:55px!important;
                     position:absolute;
                     top:30%;
                  }
               `}
          </style>
        </>
      )}
      <Box sx={{ position: 'relative', height: '87%' }}>
        <Box sx={{ height: '100%' }}>
          {/*<div className="col-12" style={{ position: "absolute" }}>
            <Typography
              variant="h6"
              sx={{
                fontSize: "20px",
                fontFamily: "Poppins",
                fontWeight: 500,
                textAlign: "center",
                color: "#4F4F4F",
                padding: "10px",
                margin: "0 auto",
              }}
            >
              {selectedEnrollmentWindowObj?.openEnrollmentStart
                ? `Enrollments from ${props?.formatDate(
                    selectedEnrollmentWindowObj?.openEnrollmentStart
                  )} - ${props?.formatDate(
                    selectedEnrollmentWindowObj?.openEnrollmentEnd
                  )}`
                : "Unknown Enrollment Window"}
            </Typography>
            <Divider
              className="col-3 flex-row"
              sx={{
                margin: "0 auto",
                color: "background.border",
                opacity: 0.5,
              }}
            />
            </div>*/}
          <>
            <ResponsiveContainer minWidth={600} minHeight={300}>
              <BarChart
                label={{ fill: theme?.palette?.text?.primary }}
                layout="vertical"
                data={reportData}
                margin={{ top: 40, bottom: 40, left: 40, right: 40 }}
              >
                {/* <CartesianGrid strokeDasharray={'3'} /> */}
                <XAxis
                  type="number"
                  height={50}
                  dataKey={'count'}
                  tickCount={totalPotentialBars}
                  label={{
                    value: 'Submitted Enrollments',
                    angle: 0,
                    position: 'bottom',
                    fill: theme?.palette?.text?.primary,
                    offset: '2',
                  }}
                ></XAxis>
                <YAxis
                  width={180}
                  type="category"
                  interval={'preserveStartEnd'}
                  dataKey={'type'}
                  tick={{ fill: theme?.palette?.text?.primary }}
                ></YAxis>
                {/* <Tooltip content={<CustomTooltip />} /> */}
                {Array(totalPotentialBars)
                  .fill()
                  .map((a, index) => {
                    return (
                      <Bar
                        isAnimationActive={false}
                        dataKey={`count[${index}]`}
                        name={`name[${index}]`}
                        stackId={'option'}
                        fill={colors[index % 4]}
                        shape={renderBarShape}
                        stroke="white"
                        strokeWidth={'2px'}
                      >
                        <LabelList
                          dataKey={`count[${index}]`}
                          position="insideRight"
                          content={(props) => {
                            if (props.value == 0) return null;
                            return (
                              <g>
                                <text
                                  x={props.x + props.width - 14}
                                  y={props.y + props.height / 2}
                                  style={{
                                    stroke: '',
                                    fill: '#FFF',
                                    fontSize: '15px',
                                    fontFamily: 'Poppins',
                                    fontWeight: 500,
                                  }}
                                  textAnchor="end"
                                  dominantBaseline={'middle'}
                                >
                                  {props.value}
                                </text>
                              </g>
                            );
                          }}
                        />

                        <LabelList
                          dataKey={`name[${index}]`}
                          position="insideLeft"
                          content={(props) => {
                            if (props.value == 0) return null;
                            return (
                              <g>
                                <svg
                                  x={props.x + 14}
                                  y={props.y}
                                  width={props.width - 50}
                                  height={props.height * 2}
                                >
                                  <text
                                    x={0}
                                    y={props.height / 2}
                                    style={{
                                      stroke: '',
                                      fill: '#FFF',
                                      fontSize: '14px',
                                      fontFamily: 'Poppins',
                                      fontWeight: 500,
                                      textOverflow: 'ellipsis',
                                    }}
                                    textAnchor="start"
                                    dominantBaseline={'middle'}
                                  >
                                    {props.value}
                                  </text>
                                </svg>
                                {/* <svg x={props.x + props.width - 40}  y={props.y} width={props.width - 80} height={props.height * 2} >
                                       <text x={0}  y={ props.height / 2} style={{stroke:'',fill:'#FFF', fontSize:'14px', fontFamily:'Poppins',fontWeight:500, textOverflow:'ellipsis'}}  textAnchor='start' dominantBaseline={'middle'} >

                                       ...
                                       </text>
                                       
                                    </svg> */}
                              </g>
                            );
                          }}
                        />
                      </Bar>
                    );
                  })}
              </BarChart>
            </ResponsiveContainer>
          </>
        </Box>
      </Box>
    </>
  );
}
