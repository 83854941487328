import React, { useEffect, useState, useMemo } from 'react';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//MuiComponents
import { Alert } from '@mui/material';
import {
  MenuItem,
  Divider,
  Select,
  InputLabel,
  FormControl,
  Slider,
  TextField,
  Autocomplete,
  Button,
  Box,
  Radio,
  Typography,
} from '@mui/material';

//Mui Icons

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//Our components
import RecommendedBenefitDisplay from '../../../RecommendedBenefitDisplay';
import FlexSnackbar from '../../../../../flex-snackbar/FlexSnackbar';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../../enrollmentPlanStyles';

export default function EnrollmentLaddSpouse(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });

  let results = props.results;
  let lifeADDPlan = results?.lifeADDPlan;
  let dataUHTest = props.dataUHTest;

  let today = new Date();
  let day = today.getDate();
  let month = today.getMonth() + 1;
  let year = today.getFullYear();
  day = day < 10 ? '0' + day : day;
  month = month < 10 ? '0' + month : month;
  let dateMax = `${year - 18}-${month}-${day}`;
  let dateMaxObj = new Date(dateMax);
  let dateMin = `${year - 100}-${month}-${day}`;
  let dateMinObj = new Date(dateMin);
  let startYear = dateMinObj;
  let endYear = dateMaxObj;
  if (results.maxSpouseAge != 0 && results.minSpouseAge != 0) {
    var endDateCopy = new Date(startYear);
    startYear = new Date(
      startYear.setFullYear(today.getFullYear() - results.maxSpouseAge)
    );
    endYear = new Date(
      endDateCopy.setFullYear(today.getFullYear() - results.minSpouseAge)
    );
  }

  const [opacity, setOpacity] = useState(1); //temp full opacity for now

  useEffect(() => {
    handleOpacity();
  }, [props.checkboxes.spouse, props.checkboxes.member]);

  const handleOpacity = () => {
    //temp disabled for now - setting full opacity to 1
    // if((!props.checkboxes.spouse && !props.recommendations.ShowSpouse && Object.keys(props.recommendations).length > 0) || !props.checkboxes.member) setOpacity(.6)
    // else setOpacity(1)
    setOpacity(1);
  };

  const spouseValuetext = (value) =>
    `${UsdFormatter.format(lifeADDPlan?.spouseOptions[value].coverageAmount)}`;

  const [displayAlert, setDisplayAlert] = useState(false);
  const checkMemberIsSelected = () => {
    if (props.checkboxes.member !== true) {
      setDisplayAlert(true);
    }
  };

  const autoCompleteHandler = (e) => {
    let tagName = e.target.tagName.toLowerCase();
    if (tagName == 'li') {
      let value = e.target.innerHTML.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < lifeADDPlan.spouseOptions.length; i++) {
        let coverageAmount = lifeADDPlan.spouseOptions[i].coverageAmount;
        if (coverageAmount == value) {
          if (i <= props.spouseMaxIndex) props.setSpouseSlider(i);
          else props.setSpouseSlider(props.spouseMaxIndex);
        }
      }
    } else if (tagName == 'input') {
      let value = e.target.value.slice(1).replace(',', '');
      if (value.includes('(max)')) value = value.replace('(max)', '');
      if (value.includes('(recommended)'))
        value = value.replace('(recommended)', '');
      for (let i = 0; i < lifeADDPlan.spouseOptions.length; i++) {
        let coverageAmount = lifeADDPlan.spouseOptions[i].coverageAmount;
        if (coverageAmount == value) {
          if (i <= props.spouseMaxIndex) props.setSpouseSlider(i);
          else props.setSpouseSlider(props.spouseMaxIndex);
        }
      }
    }
  };

  let maxCoverage =
    lifeADDPlan?.spouseOptions[lifeADDPlan?.spouseOptions.length - 1]
      ?.coverageAmount;

  //dropdown select display value logic
  let benefitSelectionValue = () => {
    if (
      lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage !== props?.currSpouseRec?.coverageAmount
    ) {
      //last but not rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount
      )} (max)`;
    } else if (
      lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount ===
        maxCoverage &&
      maxCoverage === props?.currSpouseRec?.coverageAmount
    ) {
      //last and rec amount
      return `${UsdFormatter.format(
        lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount
      )} (recommended)`;
    } else if (
      lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount ===
        props?.currSpouseRec?.coverageAmount &&
      props?.currSpouseRec?.coverageAmount !== maxCoverage
    ) {
      //rec amount but not last
      return `${UsdFormatter.format(
        lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount
      )} (recommended)`;
    } else {
      return UsdFormatter.format(
        lifeADDPlan.spouseOptions[props.spouseSlider]?.coverageAmount
      );
    }
  };

  useEffect(() => {
    if (props.spouse.dateOfBirth) {
      var today = new Date();
      let spouseDob = new Date(props.spouse.dateOfBirth);
      if (results.maxSpouseAge != 0 && results.minSpouseAge != 0) {
        // only validate age if ageband data is coming in
        var dummyDate = new Date(spouseDob);
        var maxAgePossibleInYears = new Date(
          dummyDate.setYear(dummyDate.getFullYear() + results.maxSpouseAge)
        );
        dummyDate = new Date(spouseDob);
        var minPossibleInYears = new Date(
          dummyDate.setYear(dummyDate.getFullYear() + results.minSpouseAge)
        );
        if (maxAgePossibleInYears >= today && minPossibleInYears <= today) {
          setAgeError(false);
          setDobError(false);
          props.setSpouse({
            ...props.spouse,
            // dateOfBirth: (e>dateMinObj)?e:null,
            ageError: false,
            dobError: false,
          });
        } else {
          setAgeError(true);
          setDobError(true);
          props.setSpouse({
            ...props.spouse,
            // dateOfBirth: (e>dateMinObj)?e:null,
            ageError: true,
            dobError: true,
          });
        }
      }
    }
  }, [props.spouse.dateOfBirth]);

  //update dob
  const handleMobileDobChange = (e, i, key) => {
    props.setSpouse({
      ...props.spouse,
      dateOfBirth: e,
    });
  };

  const [addContact, setAddContact] = useState(false);

  //  Error Messaging
  const [fNameError, setFNameError] = useState(false);
  const [lNameError, setLNameError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [ageError, setAgeError] = useState(false);

  const dobErrorMessage = useMemo(() => {
    switch (dobError) {
      case 'maxDate': {
        return 'Must be older than 18 years old';
      }
      case 'minDate': {
        return 'Must be less than 100 years old';
      }
      case 'disableFuture': {
        return 'Must be less than 100 years old';
      }
      case 'invalidDate': {
        return 'Your date is not valid';
      }
      default: {
        return '';
      }
    }
  }, [dobError]);

  //check first and last name errors - want to combine this w/ dob error check in future for combined validations
  const checkError = (value) => {
    if (value == 'firstName') {
      if (props.spouse.firstName == '') {
        setFNameError(true);
      } else {
        setFNameError(false);
      }
    }

    if (value == 'lastName') {
      if (props.spouse.lastName == '') {
        setLNameError(true);
      } else {
        setLNameError(false);
      }
    }
  };

  const handleFirstNameChange = (e) => {
    let spouseFirstName = e.target.value;

    props.setSpouse({ ...props.spouse, firstName: e.target.value });

    if (spouseFirstName == '') {
      setFNameError(true);
    } else {
      setFNameError(false);
    }
  };

  const handleLastNameChange = (e) => {
    let spouseLastName = e.target.value;
    props.setSpouse({ ...props.spouse, lastName: e.target.value });

    if (spouseLastName == '') {
      setLNameError(true);
    } else {
      setLNameError(false);
    }
  };

  //handle change in dob errors from datepicker and update spouse obj w/ dobError
  useEffect(() => {
    props.setSpouse({
      ...props.spouse,
      dobError: dobError,
    });
  }, [dobError, props?.spouse?.dateOfBirth]);

  // Email Error Handling
  const [showEmailError, setShowEmailError] = useState(false);
  const [emailError, setEmailError] = useState(null);

  const isValidEmail = (email) => {
    if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email)) {
      return false;
    } else return true;
  };

  const checkEmailError = () => {
    if (!isValidEmail(props.spouse?.email)) {
      setShowEmailError(true);
      setEmailError('Please provide a valid email address');
    } else {
      setShowEmailError(false);
      setEmailError(null);
    }
  };

  const handleSpouseEmail = (e) => {
    props.setSpouse({ ...props.spouse, email: e.target.value });
    checkEmailError();
  };

  return (
    <>
      <style>
        {`
      .Mui-disabled.MuiButton-root.enroll-survey-btn {
         color:#195FFB;
         background-color: white;
         border: #195FFB 1px solid;
      }
      `}
      </style>

      <FlexSnackbar
        displayAlert={displayAlert}
        setDisplayAlert={setDisplayAlert}
        severity="error"
        text="Member must be selected to enroll spouse coverage"
      />

      <Box sx={enrollmentPlanStyles.componentContentContainer}>
        {props.checkboxes.member !== true && (
          <Box
            sx={enrollmentPlanStyles.sectionClickFieldForSnackbarDisplay}
            onClick={checkMemberIsSelected}
          />
        )}
        {/* <RadioGroup 
         sx={{ 
            padding: "0px 10px 0px 0px", 
            position: "relative", marginBottom: '15px', 
            flexDirection: 'column!important' 
         }}  
         value={props.checkboxes.spouse} 
         > */}

        {/* Radio Button and Option Row */}
        <Box sx={enrollmentPlanStyles.optionRow}>
          <Box sx={enrollmentPlanStyles.optionRadioAndTextContainer}>
            <Radio
              sx={enrollmentPlanStyles.planSelectRadioButton}
              onChange={props.laddSelectionHandler}
              value={props.checkboxes.spouse}
              checked={props.checkboxes.spouse && !props?.spouseLocalDecline}
              name={'1'}
              icon={
                <RadioButtonIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(
                    props.checkboxes.member !== true
                  )}
                />
              }
              checkedIcon={
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtonIcon(
                    props.checkboxes.member !== true
                  )}
                />
              }
              inputProps={{
                'data-amount':
                  lifeADDPlan?.spouseOptions[props.spouseSlider].premiumAmount,
                'data-person': 'spouse',
                'data-uhtest': dataUHTest,
                'data-coverage':
                  lifeADDPlan?.spouseOptions[props.spouseSlider]
                    ?.coverageAmount,
              }}
              disabled={props.checkboxes.member !== true}
            />

            <Box sx={enrollmentPlanStyles.singleOptionTextContainer}>
              <Typography
                sx={enrollmentPlanStyles.singleOptionText(
                  props.checkboxes.spouse &&
                    props.checkboxes?.spouse !== 'decline'
                )}
              >
                Spouse (
                {UsdFormatter.format(
                  results?.lifeADDPlan.spouseOptions[props.spouseSlider]
                    .coverageAmount
                )}
                )
              </Typography>
            </Box>
          </Box>

          {/* premium amount */}
          <Box
            sx={enrollmentPlanStyles.singleOptionText(
              props.checkboxes.spouse && props.checkboxes?.spouse !== 'decline'
            )}
          >
            {props.checkboxes.spouse &&
              props.checkboxes?.spouse !== 'decline' &&
              '+'}
            {!props.dummyDisplay &&
              UsdFormatterDec.format(
                results?.lifeADDPlan.spouseOptions[props.spouseSlider]
                  .premiumAmount
              )}
            {props.dummyDisplay && '$0.00'}
          </Box>
        </Box>
        <Box sx={enrollmentPlanStyles.blurbTextContainer}>
          {results &&
            results.maxSpouseAge != 0 &&
            results.minSpouseAge != 0 && (
              <Typography sx={enrollmentPlanStyles.blurbText}>
                Coverage does not apply to spouses over age of{' '}
                {results?.maxSpouseAge}.
              </Typography>
            )}
        </Box>

        <RecommendedBenefitDisplay
          currSpouseRec={
            props.recommendations?.ShowSpouse && props?.currSpouseRec
          }
        />

        {/* Spouse Coverage Amount Dropdown */}
        <Box sx={enrollmentPlanStyles.benefitAmountDropdownContainer}>
          <Autocomplete
            disablePortal
            fullWidth
            autoComplete
            options={lifeADDPlan.spouseOptions?.map((option, i) => {
              let isLast = i == lifeADDPlan.spouseOptions.length - 1;
              if (
                isLast &&
                props?.currSpouseRec?.coverageAmount !== option?.coverageAmount
              ) {
                // last but not rec amount
                return `${UsdFormatter.format(option.coverageAmount)} (max)`;
              } else if (
                isLast &&
                props?.currSpouseRec?.coverageAmount === option?.coverageAmount
              ) {
                //last and = rec amount
                return `${UsdFormatter.format(
                  option.coverageAmount
                )} (recommended)`;
              } else if (
                !isLast &&
                props?.currSpouseRec?.coverageAmount === option?.coverageAmount
              ) {
                //not last and = rec amount
                return `${UsdFormatter.format(
                  option.coverageAmount
                )} (recommended)`;
              } else {
                return UsdFormatter.format(option.coverageAmount);
              }
            })}
            value={benefitSelectionValue() || 0}
            onChange={autoCompleteHandler}
            renderInput={(params) => (
              <TextField
                {...params}
                InputLabelProps={{
                  sx: {
                    color:
                      props.checkboxes.member !== true ? '' : 'primary.main',
                  },
                }}
                sx={enrollmentPlanStyles.activeBlueDropdownField(
                  props.checkboxes.member !== true
                )}
                label="Coverage Amount"
              />
            )}
            // InputLabelProps={enrollmentPlanStyles.inputLabelProps}
            componentsProps={{
              popupIndicator: {
                sx: {
                  color: props.checkboxes.member !== true ? '' : 'primary.main',
                },
              },
            }}
          />
        </Box>

        {/* SLIDER */}
        <Box sx={enrollmentPlanStyles.incSliderSection}>
          {/* Slider min */}
          <Box sx={enrollmentPlanStyles.incSliderLowAmount}>
            {`$${results?.lifeADDPlan.spouseOptions[0].coverageAmount / 1000}K`}
          </Box>

          {/* Slider container */}
          <Box sx={enrollmentPlanStyles.incSliderContainer}>
            <Slider
              onChange={props.spouseSliderChange}
              disabled={props.checkboxes.spouse !== true}
              value={props.spouseSlider}
              marks={props.marks}
              step={1}
              min={0}
              max={results?.lifeADDPlan.spouseOptions.length - 1}
              sx={enrollmentPlanStyles.incSlider}
              valueLabelDisplay="auto"
              valueLabelFormat={spouseValuetext}
            />
          </Box>

          {/* Slider max */}
          <Box sx={enrollmentPlanStyles.incSliderHighAmount}>
            {`$${
              results?.lifeADDPlan.spouseOptions[
                results?.lifeADDPlan.spouseOptions.length - 1
              ].coverageAmount / 1000
            }K`}
          </Box>
        </Box>

        {props.checkboxes.spouse && !props?.spouseLocalDecline && (
          <Box sx={enrollmentPlanStyles.inputSection}>
            {/* Spouse First Name */}
            <Box sx={enrollmentPlanStyles.inputField}>
              <TextField
                fullWidth
                error={fNameError}
                onBlur={(e) => checkError('firstName')}
                label="Spouse First Name"
                variant="outlined"
                required={props.checkboxes.spouse}
                value={props.spouse?.firstName || ''}
                onChange={(e) => handleFirstNameChange(e)}
                InputLabelProps={enrollmentPlanStyles.inputLabelProps}
              />
            </Box>
            {/* Spouse Last Name */}
            <Box sx={enrollmentPlanStyles.inputField}>
              <TextField
                fullWidth
                required={props.checkboxes.spouse}
                error={lNameError}
                onBlur={(e) => checkError('lastName')}
                label="Spouse Last Name"
                variant="outlined"
                value={props.spouse?.lastName || ''}
                onChange={(e) => handleLastNameChange(e)}
                InputLabelProps={enrollmentPlanStyles.inputLabelProps}
              />
            </Box>

            {/* Spouse Gender */}
            <Box sx={enrollmentPlanStyles.inputField}>
              <FormControl fullWidth>
                <InputLabel sx={enrollmentPlanStyles.inputLabelProps.sx}>
                  Spouse Gender
                </InputLabel>
                <Select
                  variant="outlined"
                  label="Spouse Gender"
                  value={props?.spouse?.gender ? props.spouse.gender : ''}
                  onChange={(e) =>
                    props?.setSpouse({
                      ...props.spouse,
                      gender: e.target.value,
                    })
                  }
                >
                  {props?.genderTypes?.length > 0 &&
                    props?.genderTypes.map((option) => {
                      return (
                        <MenuItem value={option.id} key={option.id}>
                          {option.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>

            {/* Spouse DOB */}

            <Box sx={enrollmentPlanStyles.inputField}>
              <LocalizationProvider
                required={false}
                dateAdapter={AdapterDateFns}
              >
                <DatePicker
                  // onBlur={(e) => checkError("dob")}
                  disableFuture
                  openTo="year"
                  views={['year', 'month', 'day']}
                  value={props.spouse.dateOfBirth || null}
                  onChange={handleMobileDobChange}
                  format="MM/dd/yyyy"
                  label="Date of birth *"
                  // onAccept={(e) => checkError("dob")}
                  minDate={startYear}
                  maxDate={endYear}
                  onError={(error) => setDobError(error)}
                  sx={enrollmentPlanStyles.datePickerInput}
                  slotProps={{
                    textField: {
                      helperText: dobErrorMessage,
                      InputLabelProps: enrollmentPlanStyles.inputLabelProps,
                    },
                    openPickerButton: { sx: { visibility: 'hidden' } }, //hide calendar icon calandar
                  }}
                />
              </LocalizationProvider>
            </Box>

            {/* alert for exceeding age limit */}
            {results.minSpouseAge != 0 &&
              results.maxSpouseAge != 0 &&
              ageError && (
                <Alert
                  severity="error"
                  sx={{ marginBottom: '10px', whiteSpace: 'pre-line' }}
                >
                  Spouse will not be covered due to age exceeding the limit of
                  eligibility. Please decline coverage.
                </Alert>
              )}

            {/* Will need to refactor this section IF we use it again */}
            {/* spouse making changes to account logic */}
            {/* <Box className='flex-row' sx={{textAlign:'left',fontWeight:500,fontSize:'15px',color:'#646464',lineHeight:'25px',marginBottom:'11px'}}>
                  <span>
                     Can your spouse make changes to your account on your behalf?
                  </span>
               </Box>

               <Box className='flex-row' sx={{marginBottom:'20px'}}>
                  <Box className='col-6' onClick={(e) => props.setSpouse({...props.spouse,coverageAuthorize:true})}>
                     <Button variant='contained' className='enroll-survey-btn' 
                     disabled={props.spouse.coverageAuthorize == false || props.spouse.coverageAuthorize == null}
                     sx={{ borderRadius:'100px', 
                     width:"95%!important",
                     height:"50px"}}
                     >
                        YES
                     </Button>
                  </Box>
                  <Box className='col-6' sx={{textAlign:'end'}} 
                  onClick={(e) => {
                     props.setSpouse({...props.spouse,coverageAuthorize:false,email:''})
                     setAddContact(false)
                  }}
                  >
                     <Button variant='contained' className='enroll-survey-btn'
                     disabled={props.spouse.coverageAuthorize === true || props.spouse.coverageAuthorize == null}
                     sx={{ borderRadius:'100px', 
                     width:"95%!important",
                     height:"50px"}}
                     >
                        NO
                     </Button>
                  </Box>
               </Box> */}

            {!addContact && (
              <Box
                sx={{
                  ...enrollmentPlanStyles.addAdditionalButtonContainer,
                  pointerEvents: ageError ? 'none' : 'initial',
                  opacity: ageError ? 0.5 : 1,
                }}
              >
                <Button
                  onClick={(e) => setAddContact(true)}
                  variant="text"
                  sx={enrollmentPlanStyles.addAdditionalButton}
                >
                  + add spouse contact information
                </Button>
              </Box>
            )}

            {addContact && !ageError && (
              <Box sx={enrollmentPlanStyles.inputField}>
                <TextField
                  fullWidth
                  label="Spouse Email"
                  variant="outlined"
                  error={showEmailError && emailError}
                  helperText={showEmailError && emailError}
                  value={props.spouse?.email || ''}
                  onBlur={checkEmailError}
                  onChange={(e) => handleSpouseEmail(e)}
                  InputLabelProps={enrollmentPlanStyles.inputLabelProps}
                />
              </Box>
            )}
          </Box>
        )}

        {/* NOT NOW */}
        <Box sx={enrollmentPlanStyles.declineOptionRow}>
          <Radio
            value={true}
            onClick={(e) => {
              props?.handleLaddDeclineSelection(e, 'spouse');
            }}
            checked={props?.spouseLocalDecline}
            name={'1'}
            sx={enrollmentPlanStyles.planSelectRadioButton}
            icon={
              <RadioButtonIcon
                sx={enrollmentPlanStyles.radioButtonIcon(
                  props.checkboxes.member !== true
                )}
              />
            }
            checkedIcon={
              <RadioButtonCheckedIcon
                sx={enrollmentPlanStyles.radioButtonIcon(
                  props.checkboxes.member !== true
                )}
              />
            }
            inputProps={{
              'data-amount': 0,
              'data-person': 'spouse',
            }}
            disabled={props.checkboxes.member !== true}
          />
          <Box
            sx={enrollmentPlanStyles.declineOptionText(
              props?.spouseLocalDecline
            )}
          >
            Decline Spouse Life Coverage
          </Box>
        </Box>
        {/* </RadioGroup> */}
      </Box>

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
