import React from 'react';

// MUI Icons
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { Typography } from '@mui/material';

export default function EnrollmentSummaryCriticalIllness(props) {
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  return (
    <>
      <div
        className="flex-row"
        style={{
          minHeight: '57px',
          fontSize: '20px',
          fontWeight: 600,
          lineHeight: '30px',
          position: 'relative',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0px',
        }}
      >
        <span className="" style={{ cursor: 'pointer', position: 'relative' }}>
          <Typography variant="subtitle2" fontWeight="600">
            Critical Illness
          </Typography>
        </span>

        <div
          onClick={() =>
            props.handleExpandClick(props.results.criticalIllnessPlan)
          }
          style={{
            position: 'absolute',
            right: '0%',
            top: '0%',
            color: '#195ffb',
            width: '18%',
            height: '100%',
            cursor: 'pointer',
          }}
        >
          <CreateOutlinedIcon
            fontSize="large"
            sx={{
              position: 'absolute',
              right: '14%',
              top: '20%',
              color: 'primary.main',
              borderRadius: '100px',
              boxShadow: '-1px 1px 4px 0px #00000040',
              padding: '6px',
            }}
          />
        </div>
      </div>

      <hr
        style={{
          marginLeft: '-2%',
          marginBottom: '10px',
          minWidth: '104%',
          backgroundColor: '#F0F0F0',
          opacity: 1,
        }}
      />

      <div
        className="flex-row"
        style={{
          margin: '0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          color: '#646464',
        }}
      >
        {props.results.criticalIllnessPlan &&
          props.results.criticalIllnessPlan?.carrierName}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          color: '#646464',
        }}
      >
        Coverage Effective:{' '}
        {props.results.criticalIllnessPlan &&
          props.formatDate(props.results.criticalIllnessPlan?.effectiveDate)}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px 0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          justifyContent: 'space-between',
          color: '#646464',
          alignItems: 'center',
        }}
      >
        {props.selections.ci?.length > 0 && props.selections.ciVal[0] > 0 && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Member -{' '}
              {UsdFormatterDec.format(
                props.results.criticalIllnessPlan?.memberOptions.filter(
                  (x) => x.memberOptionId === props.selections.ci[0]
                )[0]?.benefitAmount
              )}
            </div>

            <div style={{ marginRight: '15px', fontWeight: '700' }}>
              + {UsdFormatterDec.format(props.selections.ciVal[0])}
            </div>
          </>
        )}
        {props.selections.ci?.length > 0 && props.selections.ciVal[0] === 0 && (
          <>
            <span>
              Member - <em>not elected</em>
            </span>
            <span style={{ float: 'right', marginRight: '15px' }}>$0.00</span>
          </>
        )}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px 0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          justifyContent: 'space-between',
          color: '#646464',
          alignItems: 'center',
        }}
      >
        {props.selections.ci?.length > 0 && props.selections.ciVal[1] > 0 && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Spouse -{' '}
              {UsdFormatterDec.format(
                props.results.criticalIllnessPlan?.spouseOptions.filter(
                  (x) => x.memberAndSpouseOptionId === props.selections.ci[1]
                )[0]?.benefitAmount
              )}
            </div>

            <div style={{ marginRight: '15px', fontWeight: '700' }}>
              + {UsdFormatterDec.format(props.selections.ciVal[1])}
            </div>
          </>
        )}
        {props.selections.ci?.length > 0 && props.selections.ciVal[1] === 0 && (
          <>
            <span>
              Spouse - <em>not elected</em>
            </span>
            <span style={{ float: 'right', marginRight: '15px' }}>$0.00</span>
          </>
        )}
      </div>

      <div
        className="flex-row"
        style={{
          margin: '0px 0px',
          fontSize: '15px',
          fontWeight: '500',
          padding: '0px',
          justifyContent: 'space-between',
          color: '#646464',
          alignItems: 'center',
        }}
      >
        {props.selections.ci?.length > 0 && props.selections.ciVal[2] > 0 && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Dependent(s) -{' '}
              {UsdFormatterDec.format(
                props.results.criticalIllnessPlan?.dependentOptions.filter(
                  (x) =>
                    x.memberAndDependentsOptionId === props.selections.ci[2]
                )[0]?.benefitAmount
              )}
            </div>

            <div style={{ marginRight: '15px', fontWeight: '700' }}>
              + {UsdFormatterDec.format(props.selections.ciVal[2])}
            </div>
          </>
        )}
        {props.selections.ci?.length > 0 && props.selections.ciVal[2] === 0 && (
          <>
            <span>
              Dependent - <em>not elected</em>
            </span>
            <span style={{ float: 'right', marginRight: '15px' }}>$0.00</span>
          </>
        )}
      </div>
    </>
  );
}
