import React, { useEffect, useState, useRef } from 'react';

//Assets
import FallbackLogo from '../../../../../../../assets/images/SourceFileonly icon.png';
import airLogo from '../../../../../../../assets/images/TeamstersAirLogo.png';

//Services
import planService from '../../../../../../../services/plan-service';
import enrollmentService from '../../../../../../../services/enrollment-service';

//Mui icons
import FlightIcon from '@mui/icons-material/Flight';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';

//Mui Components
import Chip from '@mui/material/Chip';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import { Box, display, margin, padding } from '@mui/system';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Collapse } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

//Our Components
import EnrollmentSummaryCardLTD from './enrollment-summary-plans/EnrollmentSummaryCardLTD';
import EnrollmentSummaryCardSTD from './enrollment-summary-plans/EnrollmentSummaryCardSTD';
import EnrollmentSummaryCardLADD from './enrollment-summary-plans/EnrollmentSummaryCardLADD';
import EnrollmentSummaryCardAccident from './enrollment-summary-plans/EnrollmentSummaryCardAccident';
import EnrollmentSummaryCardCancer from './enrollment-summary-plans/EnrollmentSummaryCardCancer';
import EnrollmentSummaryCardADD from './enrollment-summary-plans/EnrollmentSummaryCardADD';
import EnrollmentSummaryCardSTDIncremental from './enrollment-summary-plans/EnrollmentSummaryCardSTDIncremental';
import EnrollmentSummaryCardLTDIncremental from './enrollment-summary-plans/EnrollmentSummaryCardLTDIncremental';
import EnrollmentSummaryCardHospital from './enrollment-summary-plans/EnrollmentSummaryCardHospital';
import { MoreVertOutlined } from '@mui/icons-material';
import CancelCoverageDrawer from './CancelCoverageDrawer';
import EditCoverageEffectiveDrawer from './EditCoverageEffectiveDrawer';
import EnrollmentSummaryCardCriticalIllness from './enrollment-summary-plans/EnrollmentSummaryCardCriticalIllness';

//utils
import { isAirPlan } from '../../../../../../utils';

//component styles
const componentStyles = {
  collapsedViewContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%',
  },
  enrollmentTitleAndSubtitleContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 10px 10px',
    justifyContent: 'flex-start',
    width: '100%',
  },
  enrollmentImageContainer: {
    marginTop: '20px',
    textAlign: 'center',
    maxWidth: '120px',
    // maxHeight: '120px',
  },
  image: {
    maxHeight: '86px',
    minHeight: '80px',
    objectFit: 'contain',
  },
  openEnrollmentMessageContainer: (displayMessage) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: '4px',
    color: displayMessage.color,
    backgroundColor: displayMessage.backgroundColor,
    padding: '10px 16px 12px',
    alignItems: 'felx-start',
    marginTop: '10px',
  }),
  iconContainer: {
    marginRight: '16px',
    padding: '0px',
  },
  messageContentContainer: {
    flex: 2,
    paddingTop: '4px',
  },
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  conditionalButton: {
    fontFamily: 'Archivo',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '.4px',
    height: '30px',
    // marginBottom: '8px',
    // marginRight: '8px',
  },
  verticalMenuOptions: {
    color: 'text.primary',
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '.17px',
    height: '40px',
    '&:hover': { backgroundColor: 'primary.light' },
  },
};

export default function EnrollmentSummaryCard(props) {
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const [enrollmentInfo, setEnrollmentInfo] = useState(null);
  const getThisEnrollment = async () => {
    let _enrollmentInfo;
    try {
      _enrollmentInfo = await enrollmentService.getEnrollmentById(
        props.singleEnrollment.enrollmentId
      );

      if (_enrollmentInfo) {
        setEnrollmentInfo(_enrollmentInfo);
      } else {
        setEnrollmentInfo(props.singleEnrollment);
      }
    } catch (error) {
      console.error(
        `Error grabbing enrollment ${props.singleEnrollment.enrollmentId}`,
        error
      );
      setEnrollmentInfo(props?.singleEnrollment); //this sets enrollment in this component from parent where enrollment object is potentially smaller/has less data  due to method get enrollment by member Id which can return an open but not started enrollment with enrollmentId=0
    }
  };

  useEffect(() => {
    if (props.singleEnrollment && !enrollmentInfo) {
      getThisEnrollment();
    }
  }, [props.singleEnrollment, props?.singleEnrollment?.enrollmentId]);

  //date formatter - takes in date string
  const dateFormatter = (date) => {
    let dateObj = new Date(date);

    let month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    let day = dateObj.getDate().toString().padStart(2, '0');
    let year = dateObj.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  };

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const formatEffective = (date) => {
    // let fullDate = new Date(props?.enrollmentResults?.coverageEffectiveDate);
    let sliceDate = date.slice(0, 10);
    let thisYear = Number(sliceDate.slice(0, 4));
    let thisMonth = Number(sliceDate.slice(5, 7)) - 1;
    // let thisMonth = fullDate.getMonth();
    // let thisYear = fullDate.getFullYear();
    return `${months[thisMonth]} ${thisYear} Effective`;
    // setFirstPayment({
    //   ...firstPayment,
    //   month: months[thisMonth],
    //   year: thisYear,
    // });
  };

  const [nextPayment, setNextPayment] = useState();

  // useEffect(() => {
  //    if(props.paymentSchedule?.nextOccurance){
  //             // let _nextPayment = props.paymentSchedule.nextOccurance
  //             let _nextPayment = format(new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`), 'MM-dd-yyyy')
  //    setNextPayment(_nextPayment)
  //    }

  // }, [props.paymentSchedule.nextOccurance])

  const [plans, setPlans] = useState([]);

  useEffect(() => {
    if (enrollmentInfo) {
      let _plans = [];
      for (let [key, value] of Object.entries(enrollmentInfo)) {
        if (key.slice(key.length - 4, key.length) === 'Plan' && value != null) {
          _plans.push({ [key]: value });
        }
      }
      _plans = _plans.sort((a, b) =>
        Object.values(a)[0].sortOrder > Object.values(b)[0].sortOrder ? 1 : -1
      );
      setPlans(_plans);
    }
  }, [enrollmentInfo]);

  //currently NOT USING THIS, LOOK INTO REMOVEING
  // useEffect(() => {
  //    if(props.paymentSchedule?.nextOccurance && props.paymentSchedule?.scheduledPaymentDates?.length > 0){
  //       let _nextOccuranceUnmodified = new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`)
  //       _nextOccuranceUnmodified.setDate(props.paymentSchedule?.scheduledPaymentDates[0].dayOfMonth)
  //       _nextOccuranceUnmodified = format(_nextOccuranceUnmodified,'MM-dd-yyyy')
  //             // let _nextPayment = props.paymentSchedule.nextOccurance
  //             // let _nextPayment = format(new Date(`${props?.paymentSchedule?.nextOccurance?.slice(0,10)}T00:00:00`), 'MM-dd-yyyy')
  //    // setNextPayment(_nextPayment)
  //    setNextPayment(_nextOccuranceUnmodified)
  //    }

  // }, [props.paymentSchedule])

  const [resumeAnchorEl, setResumeAnchorEl] = useState(null);

  const [collapse, setCollapse] = useState(false);

  const handleMenuClick = () => {
    setCollapse(!collapse);
    setResumeAnchorEl(null);
  };

  const handleLogoError = (e) => {
    e.target.src = FallbackLogo;
  };

  //Return conditional message based on enrollment state
  const [conditionalMessage, setConditionalMessage] = useState({
    title: '',
    message: '',
    color: '',
    backgroundColor: '',
    icon: '',
    buttonText: '',
    buttonHandler: null,
  });

  const returnConditionalEnrollmentMessage = (enrollmentStateId) => {
    let displayMessage = {
      title: '',
      message: '',
      color: '',
      backgroundColor: '',
      icon: '',
      buttonText: '',
      buttonHandler: null,
    };

    //check if enrollment submitted (enrollmentStateId = 2) and declined all coverage
    if (enrollmentStateId === 2 && enrollmentInfo?.totalMonthlyPremium === 0) {
      enrollmentStateId = 4; //submitted but declined all coverage
    }
    //check if enrollment submitted (enrollmentStateId = 2) and if NAW (any work status other than active (id=1))
    if (
      enrollmentStateId === 2 &&
      enrollmentInfo?.totalMonthlyPremium > 0 &&
      enrollmentInfo?.workStatusId > 1
    ) {
      //set to state of 5 for naw - need to get working status sent with get enrollment by id record
      enrollmentStateId = 5;
    }

    switch (enrollmentStateId) {
      case undefined:
        displayMessage = {
          title: 'This plan is in open enrollment!',
          message:
            'Start the enrollment here or send the link to the member for self-enrollment. ',
          color: 'text.enrollmentState.start',
          backgroundColor: 'background.enrollmentState.start',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{ color: 'text.enrollmentState.start' }}
            />
          ),
          buttonText: 'START',
          buttonHandler: handleResumeClick,
        };
        break;
      case 0:
        displayMessage = {
          title: 'This plan is in open enrollment!',
          message:
            'Start the enrollment here or send the link to the member for self-enrollment. ',
          color: 'text.enrollmentState.start',
          backgroundColor: 'background.enrollmentState.start',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{ color: 'text.enrollmentState.start' }}
            />
          ),
          buttonText: 'START',
          buttonHandler: handleResumeClick,
        };
        break;
      case 1:
        displayMessage = {
          title: 'Enrollment in progress.',
          message:
            'Enrollment has been started. Click resume to finish the application for the member.',
          color: 'text.enrollmentState.inProgress',
          backgroundColor: 'background.enrollmentState.inProgress',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{ color: 'text.enrollmentState.inProgress' }}
            />
          ),
          buttonText: 'RESUME',
          buttonHandler: handleResumeClick,
        };
        break;
      case 2:
        displayMessage = {
          title: 'Enrollment Submitted',
          message: 'Member has submitted their enrollment.',
          color: 'text.enrollmentState.submitted',
          backgroundColor: 'background.enrollmentState.submitted',
          icon: (
            <CheckCircleOutlinedIcon
              sx={{ color: 'text.enrollmentState.submittedCheckMark' }}
            />
          ),
          buttonText: 'REOPEN',
          buttonHandler: handleReOpenClick,
        };
        break;
      case 3:
        displayMessage = {
          title: 'Enrollment cancelled.',
          message: 'This enrollment has been cancelled.',
          color: 'text.enrollmentState.cancelled',
          backgroundColor: 'background.enrollmentState.cancelled',
          icon: (
            <ErrorOutlineOutlinedIcon
              sx={{ color: 'text.enrollmentState.cancelled' }}
            />
          ),
        };
        break;
      case 4:
        displayMessage = {
          title: 'Enrollment Submitted: Member has declined all coverage.',
          message:
            'Member has declined all coverage on the current open enrollment. ',
          color: 'text.enrollmentState.inProgress',
          backgroundColor: 'background.enrollmentState.inProgress',
          icon: (
            <CheckCircleOutlinedIcon
              sx={{ color: 'text.enrollmentState.inProgress' }}
            />
          ),
          buttonText: 'REOPEN',
          buttonHandler: handleReOpenClick,
        };
        break;
      case 5:
        displayMessage = {
          title: 'Enrollment Submitted: Pending Eligibility.',
          message:
            'Member is employed but Not Actively Working. Eligibility is pending. ',
          color: 'text.enrollmentState.inProgress',
          backgroundColor: 'background.enrollmentState.inProgress',
          icon: (
            <CheckCircleOutlinedIcon
              sx={{ color: 'text.enrollmentState.inProgress' }}
            />
          ),
          buttonText: 'REOPEN',
          buttonHandler: handleReOpenClick,
        };
        break;
      default:
        displayMessage = {
          title: '',
          message: '',
          color: '',
          backgroundColor: '',
          icon: '',
          buttonText: '',
          buttonHandler: null,
        };
    }
    setConditionalMessage(displayMessage);
  };

  useEffect(() => {
    if (enrollmentInfo) {
      console.log('enrollmentInfo has changed', props?.singleEnrollment);
      returnConditionalEnrollmentMessage(enrollmentInfo?.enrollmentStateId);
    }
  }, [
    enrollmentInfo?.enrollmentStateId,
    enrollmentInfo?.enrollmentId,
    enrollmentInfo,
    props?.singleEnrollment?.enrollmentId,
  ]);

  const handleResumeClick = (e) => {
    console.log('resume enrollment clicked', enrollmentInfo);
    props.setSelectedEnrollment(enrollmentInfo);
    props.setActivePage(4);
  };

  const handleReOpenClick = () => {
    props.setSelectedEnrollment(enrollmentInfo);
    props.handleEnrollmentClick(enrollmentInfo.enrollmentStateId);
  };

  const handleCancelClick = () => {
    //this is true here cause EnrollmentSummaryCard only deals with currently open enrollments, not active coverages
    //hence, canceling an enrollment will reopen it

    // let cancelEnrollment = true
    setResumeAnchorEl(null);
    // props.setSelectedEnrollment(enrollmentInfo)
    // props.handleEnrollmentClick(enrollmentInfo.enrollmentState, cancelEnrollment)
    setCancelCoverageDrawerOpen(true);
  };

  const handleChangeEffectiveClick = () => {
    setResumeAnchorEl(null);
    setEditCoverageEffectiveDateDrawerOpen(true);
  };

  const handleRegenerateClick = async () => {
    let sendEmail = false;
    try {
      await enrollmentService.generateEnrollmentReport(
        enrollmentInfo.enrollmentId,
        sendEmail
      );
    } catch (error) {
      console.error('error generating enrollment report', error);
    }
    setTimeout(async () => {
      await props.getAllDocuments();
    }, 200);
    setResumeAnchorEl(null);
  };

  const [error, setError] = useState(false);

  const handleMoveToInProgress = async () => {
    let memberId = props?.member?.id;
    let signatureObj = {
      enrollmentId: props?.singleEnrollment?.enrollmentId,
      signatureText: '',
      signature: '',
      signatureDate: Date.toLocaleString().slice(0, 9),
      groupPlanId: props?.singleEnrollment?.groupPlanId,
      isMail: false,
      isVerbal: false,
    };

    try {
      let moveToInProgress =
        enrollmentService.reopenSubmittedEnrollmentForMember(
          signatureObj,
          memberId
        );
    } catch (error) {
      console.error('error canceling all coverage', error);
      //local error value for conditional rendering of modal
    }
    if (!error) {
      setResumeAnchorEl(null);
    }

    //refresh
    setTimeout(() => {
      getThisEnrollment();
    }, 500);
  };

  // Cancel Enrollment Drawer
  const [cancelCoverageDrawerOpen, setCancelCoverageDrawerOpen] =
    useState(false);
  const [cancelAllError, setCancelAllError] = useState(false);

  const [
    editCoverageEffectiveDateDrawerOpen,
    setEditCoverageEffectiveDateDrawerOpen,
  ] = useState(false);

  const [currentAvailableWindows, setCurrentAvailableWindows] = useState([]);
  const getWindowsForGroupPlan = async (groupPlanId) => {
    let _windows = [];
    try {
      _windows = await enrollmentService.getEnrollmentWindowsByGroupPlanId(
        groupPlanId
      );
    } catch (error) {
      console.error('error getting windows for group plan', error);
    }
    if (_windows?.length > 0) {
      setCurrentAvailableWindows(_windows);
    }
  };
  //   useEffect(() => {
  //     if (props?.singleEnrollment?.groupPlanId > 0) {
  //       getWindowsForGroupPlan(props?.singleEnrollment?.groupPlanId);
  //     }
  //   }, [props?.singleEnrollment?.groupPlanId]);

  return (
    <>
      <Box
        sx={{
          padding: '8px 18px 15px',
          width: '100%',
        }}
      >
        <CancelCoverageDrawer
          cancelCoverageDrawerOpen={cancelCoverageDrawerOpen}
          setCancelCoverageDrawerOpen={setCancelCoverageDrawerOpen}
          planEnrollmentId={props?.singleEnrollment?.enrollmentId}
          formatDate={props?.formatDate}
          cancellationReasons={props?.cancellationReasons}
          updateSinglePlan={props?.updateSinglePlan}
          member={props?.member}
          singleEnrollment={props?.singleEnrollment}
          getThisPlan={getThisEnrollment}
          groupPlanId={props?.singleEnrollment?.groupPlanId}
          cancelEnrollment={true}
          cancelAllError={cancelAllError}
          setCancelAllError={setCancelAllError}
          getMemberHistory={props.getMemberHistory}
          getActiveCoverage={props.getActiveCoverage}
          getPaymentSchedule={props.getPaymentSchedule}
        />

        <EditCoverageEffectiveDrawer
          editCoverageEffectiveDateDrawerOpen={
            editCoverageEffectiveDateDrawerOpen
          }
          setEditCoverageEffectiveDateDrawerOpen={
            setEditCoverageEffectiveDateDrawerOpen
          }
          currentAvailableWindows={currentAvailableWindows}
          planEnrollmentId={props?.singleEnrollment?.enrollmentId}
          formatDate={props?.formatDate}
          updateSinglePlan={props?.updateSinglePlan}
          member={props?.member}
          singleEnrollment={props?.singleEnrollment}
          getThisPlan={getThisEnrollment}
          groupPlanId={props?.singleEnrollment?.groupPlanId}
        />

        {/* Open Enrollment Message */}
        {/* {enrollmentInfo?.isEnrollmentOpen && (   //uncomment for re-enrollment when we return multiple enrollments to front, some of which may not be in open enrollment*/}
        <Box sx={componentStyles.collapsedViewContainer}>
          <Box sx={componentStyles.enrollmentImageContainer}>
            <Fade in={props?.member?.organizationId ? true : false}>
              <img
                src={
                  isAirPlan(props?.singleEnrollment, props?.surveyResponses)
                    ? airLogo
                    : props.member?.organizationId > 0
                    ? `api/image/organization/${props.member?.organizationId}`
                    : '/api/image/organization'
                }
                alt="organization logo"
                width="80%"
                // sx={componentStyles.image}
              />
            </Fade>
          </Box>

          <Box sx={componentStyles.enrollmentTitleAndSubtitleContainer}>
            <Typography variant="h6">
              {props?.singleEnrollment?.name ? (
                isAirPlan(props?.singleEnrollment, props?.surveyResponses) ? (
                  'Teamsters Voluntary Income Protection VIP+ Air'
                ) : (
                  props?.singleEnrollment?.name || 'Enrollment'
                )
              ) : (
                <></>
                // <Skeleton width="16rem" />
              )}
            </Typography>
            {props?.singleEnrollment?.enrollmentStateId === 0 ||
            !props?.singleEnrollment.enrollmentStateId ||
            props?.singleEnrollment?.enrollmentState === 0 ? (
              <Typography sx={{ color: 'text.secondary' }}>
                No current coverage.
              </Typography>
            ) : (
              ''
            )}
            <Box
              sx={componentStyles.openEnrollmentMessageContainer(
                conditionalMessage
              )}
            >
              <Box sx={componentStyles.iconContainer}>
                {conditionalMessage.icon}
              </Box>
              <Box sx={componentStyles.messageContentContainer}>
                <Typography fontWeight={500}>
                  {conditionalMessage.title}
                </Typography>
                <Typography variant="body2">
                  {conditionalMessage.message}
                </Typography>
              </Box>

              {/* Button Section and Menu */}
              <Box sx={componentStyles.buttonGroupContainer}>
                <Box sx={componentStyles.buttonRow}>
                  <Box>
                    <Button
                      ref={resumeAnchorEl}
                      disableRipple
                      disabled={
                        !enrollmentInfo ||
                        (conditionalMessage.buttonText === 'REOPEN' &&
                          props?.paymentSchedule?.length > 2)
                      }
                      variant="variant"
                      component="label"
                      size="medium"
                      sx={componentStyles.conditionalButton}
                      onClick={conditionalMessage.buttonHandler}
                      data-uhtest="enrollment-summary-conditional-button"
                    >
                      {conditionalMessage.buttonText}
                    </Button>
                  </Box>
                  <IconButton
                    onClick={(e) => setResumeAnchorEl(e.currentTarget)}
                    sx={{ padding: '2px 0px' }}
                  >
                    <MoreVertIcon
                      sx={{
                        color: conditionalMessage.color,
                        display:
                          conditionalMessage?.message !== '' ? 'block' : 'none',
                      }}
                    />
                  </IconButton>
                  <Menu
                    anchorEl={resumeAnchorEl}
                    open={resumeAnchorEl ? true : false}
                    fullWidth
                    onClose={() => setResumeAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {(enrollmentInfo?.enrollmentStateId === 2 ||
                      enrollmentInfo?.enrollmentStateId === 1) && (
                      <MenuItem
                        value={!collapse}
                        onClick={handleMenuClick}
                        disabled={!enrollmentInfo}
                        sx={componentStyles.verticalMenuOptions}
                      >
                        {!collapse ? 'Show Preview' : 'Hide Preview'}
                      </MenuItem>
                    )}
                    {(enrollmentInfo?.enrollmentStateId === 2 ||
                      enrollmentInfo?.enrollmentStateId === 1) && (
                      <MenuItem
                        onClick={handleCancelClick}
                        sx={componentStyles.verticalMenuOptions}
                        disabled={props?.paymentSchedule?.length > 2}
                      >
                        Cancel Enrollment
                      </MenuItem>
                    )}
                    {/* {enrollmentInfo?.enrollmentStateId === 2 && (
                  <MenuItem
                    onClick={handleChangeEffectiveClick}
                    sx={componentStyles.verticalMenuOptions}
                  >
                    Change Coverage Effective
                  </MenuItem>
                )} */}
                    {enrollmentInfo?.enrollmentStateId === 2 && (
                      <MenuItem
                        onClick={handleRegenerateClick}
                        sx={componentStyles.verticalMenuOptions}
                      >
                        Regenerate PDF
                      </MenuItem>
                    )}
                    {(enrollmentInfo?.enrollmentStateId === 0 ||
                      enrollmentInfo?.enrollmentStateId == undefined) && (
                      <MenuItem
                        // onClick={} need a method for this
                        sx={componentStyles.verticalMenuOptions}
                        disabled={true}
                      >
                        Send Enrollment Link
                      </MenuItem>
                    )}
                    {enrollmentInfo?.enrollmentStateId === 3 && (
                      <MenuItem
                        value={!collapse}
                        onClick={handleMoveToInProgress}
                        disabled={!enrollmentInfo}
                        sx={componentStyles.verticalMenuOptions}
                      >
                        Re-Open Application
                      </MenuItem>
                    )}
                    {/* <MenuItem value='Settings' 
                        // onClick={handleStatusOpen}
                        divider disabled
                           sx={{
                              color: "text.primary",  fontFamily: "Archivo", fontWeight: "500", padding: "10px",
                              '&:hover': { backgroundColor: 'primary.light' }
                           }}>
                           HIDE
                        </MenuItem> */}

                    {/*///////temp pilot enrollment logic - disable pilot enrollment IF pilot enrollment accidentally opened and not started */}
                    {(props?.singleEnrollment?.name
                      ?.toLowerCase()
                      .includes('teamsters') ||
                      props?.singleEnrollment?.name
                        ?.toLowerCase()
                        .includes('ibt')) &&
                      props?.surveyResponses?.isPilot && (
                        <MenuItem
                          sx={componentStyles.verticalMenuOptions}
                          onClick={() => {
                            //close menu
                            setResumeAnchorEl(null);
                            //open pilot modal
                            props?.handleOpenPilotModal();
                          }}
                          disabled={
                            props?.singleEnrollment?.enrollmentStateId > 0 ||
                            props?.singleEnrollment?.enrollmentState > 0
                          } //cannot enable if isPilot is already true
                        >
                          <FlightIcon sx={{ height: '19px' }} /> Disable Pilot
                          Enrollment
                        </MenuItem>
                      )}
                  </Menu>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* )} */}
      </Box>

      {/* Election View Expanded */}
      <Collapse in={collapse}>
        {/* Election Summary */}
        <Box
          sx={{
            position: 'relative',
            padding: '8px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          {/* For rolling enrollments, use isLastWindow to determine if the enrollment is in last window. If it is, then hide the coverage effective dates */}
          {!props?.singleEnrollment?.isLastWindow && (
            // <Box >
            <Box sx={{ padding: '4px 10px' }}>
              <Typography
                component={'div'}
                sx={{
                  fontFamily: 'Archivo',
                  fontSize: '16px',
                  fontWeight: 400,
                  lineHeight: '28px',
                  letterSpacing: '.15px',
                  color: 'text.gray',
                }}
              >
                {(props.singleEnrollment &&
                  props.singleEnrollment?.policyNumber?.length > 0 &&
                  `Policy #${props.singleEnrollment?.policyNumber}`) ||
                  'Policy Number'}
              </Typography>
              {/* <br/> */}
              <Collapse in={plans.length > 0}>
                {plans.length > 0 &&
                  plans.map((plan) => {
                    let planKey = Object.keys(plan)[0];
                    let planValue = Object.values(plan)[0];
                    let planName = planKey.slice(0, planKey.length - 4);
                    switch (planName.toUpperCase()) {
                      case 'LIFEADD':
                        planName = 'Life & AD&D';
                        break;
                      default:
                        console.log('no plan found');
                    }

                    //find any declined plan options elected, otherwise this value is false
                    //check for declined ladd plan in return below w/ planValue?.totalPremiumAmount == 0 since ladd plan has this value on object and will be > 0 if anything is elected
                    // let declinePlanOptionSelected = false
                    // if(planValue?.options){
                    //    declinePlanOptionSelected = planValue?.options?.find(option => {
                    //       if(option?.isDecline && option?.memberOptionId === planValue?.selectedOptionId) {
                    //          return option
                    //       }
                    //    })
                    // }

                    return (
                      <Typography
                        component={'div'}
                        sx={{
                          fontFamily: 'Archivo',
                          fontSize: '14px',
                          fontWeight: 500,
                          lineHeight: '22px',
                          letterSpacing: '.1px',
                          color: 'text.gray',
                        }}
                      >
                        {planName.toUpperCase()}:{' '}
                        {(planValue?.effectiveDate &&
                          formatEffective(planValue?.effectiveDate)) ||
                          ''}
                      </Typography>
                    );
                  })}
              </Collapse>
            </Box>
            // </Box>
          )}
          {/* <Typography variant="body2" sx={{ marginTop: '10px' }}>
            Coverage Effective Date:{' '}
            {(props?.singleEnrollment?.coverageEffectiveDate &&
              dateFormatter(props?.singleEnrollment?.coverageEffectiveDate)) ||
              ''}
          </Typography> */}
        </Box>
        {/* 
        list of lines of coverage  */}
        {plans.length > 0 &&
          plans.map((plan) => {
            let planKey = Object.keys(plan)[0];
            let planValue = Object.values(plan)[0];

            // let selectedOptionId = planValue?.selectedOptionId
            let returnedPlanComponent;

            let planName = planKey.slice(0, planKey.length - 4);
            switch (planName.toUpperCase()) {
              case 'LIFEADD':
                planName = 'Life & AD&D';
                break;
              case 'CRITICALILLNESS':
                planName = 'Critical Illness';
                break;
              default:
                console.log('no plan found');
            }
            switch (planKey.slice(0, planKey.length - 4).toUpperCase()) {
              case 'LTD':
                if (planValue?.isIncremental) {
                  // need incremental
                  returnedPlanComponent = (
                    <EnrollmentSummaryCardLTDIncremental
                      plan={planValue}
                      planName={planName}
                      UsdFormatterDec={UsdFormatterDec}
                      planCosts={props.planCosts} //not used
                      updateSinglePlan={props.updateSinglePlan}
                      currentEdit={props.currentEdit}
                      setCurrentEdit={props.setCurrentEdit}
                      formatDate={props?.formatDate}
                    />
                  );
                } else {
                  returnedPlanComponent = (
                    <EnrollmentSummaryCardLTD
                      plan={planValue}
                      planName={planName}
                      UsdFormatterDec={UsdFormatterDec}
                      planCosts={props.planCosts} //not used
                      updateSinglePlan={props.updateSinglePlan}
                      currentEdit={props.currentEdit}
                      setCurrentEdit={props.setCurrentEdit}
                      formatDate={props?.formatDate}
                    />
                  );
                }
                break;
              case 'STD':
                if (planValue?.isIncremental) {
                  //need incremental
                  returnedPlanComponent = (
                    <EnrollmentSummaryCardSTDIncremental
                      plan={planValue}
                      planName={planName}
                      UsdFormatterDec={UsdFormatterDec}
                      planCosts={props.planCosts} //not used
                      updateSinglePlan={props.updateSinglePlan}
                      currentEdit={props.currentEdit}
                      setCurrentEdit={props.setCurrentEdit}
                      formatDate={props?.formatDate}
                    />
                  );
                } else {
                  returnedPlanComponent = (
                    <EnrollmentSummaryCardSTD
                      plan={planValue}
                      planName={planName}
                      UsdFormatterDec={UsdFormatterDec}
                      planCosts={props.planCosts} //not used
                      updateSinglePlan={props.updateSinglePlan}
                      currentEdit={props.currentEdit}
                      setCurrentEdit={props.setCurrentEdit}
                      formatDate={props?.formatDate}
                    />
                  );
                }
                break;
              case 'LIFEADD':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardLADD
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    groupPlanId={enrollmentInfo.groupPlanId}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              case 'CRITICALILLNESS':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardCriticalIllness
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    groupPlanId={enrollmentInfo.groupPlanId}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              case 'ACCIDENT':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardAccident
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              case 'CANCER':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardCancer
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              case 'ADD':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardADD
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              case 'HOSPITAL':
                returnedPlanComponent = (
                  <EnrollmentSummaryCardHospital
                    plan={planValue}
                    planName={planName}
                    UsdFormatterDec={UsdFormatterDec}
                    planCosts={props.planCosts} //not used
                    member={props.member}
                    relationshipTypes={props.relationshipTypes}
                    currentEdit={props.currentEdit}
                    setCurrentEdit={props.setCurrentEdit}
                    formatDate={props?.formatDate}
                  />
                );
                break;
              default:
                console.log('no plan found');
            }
            return returnedPlanComponent;
          })}
      </Collapse>
      {!collapse && <>{/* Collapsed */}</>}
    </>
  );
}
