import React, { useEffect, useState } from 'react';

//MuiComponents
import Checkbox from '@mui/material/Checkbox';

//Our components
import FlexSnackbar from '../../../flex-snackbar/FlexSnackbar';

//Our icons
import CustomCheckIcon from '../../../../assets/images/CustomCheckIcon.svg';

export default function CalculatorLaddDependent(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });

  let results = props.results;
  let lifeADDPlan = results?.lifeADDPlan;

  const [opacity, setOpacity] = useState(props.survey.HaveDependents ? 1 : 0.6);
  useEffect(() => {
    if (props.checkboxes.dependent) setOpacity(1);
    else if (!props.checkboxes.dependent && !props.survey.HaveDependents)
      setOpacity(0.6);
  }, [props.checkboxes.dependent]);

  const [displayAlert, setDisplayAlert] = useState(false);
  const checkMemberIsSelected = () => {
    if (props.checkboxes.member !== true) {
      setDisplayAlert(true);
    }
  };

  return (
    <>
      <FlexSnackbar
        text="Member must be selected to enroll dependent coverage"
        displayAlert={displayAlert}
        setDisplayAlert={setDisplayAlert}
        severity="error"
      />
      <div style={{ opacity: opacity }} onClick={checkMemberIsSelected}>
        <div
          className="flex-row container"
          style={{
            padding: '0px 0px 0px 0vw',
            position: 'relative',
            marginBottom: '0px',
          }}
        >
          <Checkbox
            onChange={props.laddSelectionHandler}
            value={props.checkboxes.dependent}
            checked={props.checkboxes.dependent}
            name={2}
            inputProps={{
              'data-amount':
                results?.lifeADDPlan.dependentOptions[0].premiumAmount,
              'data-person': 'dependent',
            }}
            disabled={props.checkboxes.member !== true}
          />
          <div
            className="col-6"
            style={{
              fontSize: '24px',
              fontWeight: props.checkboxes.dependent ? 800 : 600,
              color: '#195ffb',
            }}
          >
            {UsdFormatter.format(props.dependentMax)}
            {props.calculated && props.survey.HaveDependents && (
              <img src={CustomCheckIcon} style={{ marginLeft: '5px' }} />
            )}
            <p
              style={{
                fontSize: '16px',
                fontWeight: props.checkboxes.dependent ? 800 : 400,
                color: 'black',
                marginBottom: '0px',
                whiteSpace: 'nowrap',
              }}
            >
              Dependent(s){' '}
            </p>
            {/* {props.dependentMax == 0 && <p style={{fontSize:"10px",fontWeight:400, color:"black",marginBottom:"0px",whiteSpace:"nowrap"}}>(cannot exceed {percentFormatter.format(lifeADDPlan.spousePercentageCap)} of member election)</p>} */}
          </div>
          <div
            className="col-6"
            style={{
              textAlign: 'right',
              position: 'absolute',
              right: '8%',
              top: '10%',
              fontSize: '16px',
            }}
          >
            <span
              style={{
                fontSize: '18px',
                fontWeight: props.checkboxes.dependent ? 800 : 500,
                color: props.dummyFontColor,
              }}
            >
              {props.checkboxes.dependent && '+'}
              {!props.dummyDisplay &&
                props.dependentMax != 0 &&
                UsdFormatterDec.format(
                  results?.lifeADDPlan.dependentOptions[0].premiumAmount
                )}
              {(props.dummyDisplay || props.dependentMax == 0) && '$0.00'}
            </span>
          </div>
        </div>
        <div
          className="flex-row container"
          style={{ padding: '0 8%', marginBottom: '15px' }}
        >
          <div className="ladd-explanation">
            {lifeADDPlan.dependentExplanationOfBenefits}
          </div>
        </div>
      </div>
    </>
  );
}
