import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//Mui Components
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Alert,
  Box,
  Typography,
  Checkbox,
  Grid,
  Stack,
  IconButton,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { display, fontFamily } from '@mui/system';

//Our Components
import PendingChangesWarning from './PendingChangesWarning';
import PropTypes from 'prop-types';
//utils
import { formatUTCToShortDate } from '../../../../../../../utils';

//shared styles
import acDrawerSharedStyles from './acDrawerSharedStyles';

//component styles

const componentStyles = {
  header: {
    width: '100%',
    padding: '16px',
    position: 'relative', // Add this line
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  planName: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
  },
  closeButton: {
    marginLeft: 'auto',
  },
  divider: {
    height: '1px',
    width: '100%',
    backgroundColor: 'rgba(25, 95, 251, 1)',
    border: 'none',
    margin: '0',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  manageTitle: {
    color: '#000',
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '160%',
    letterSpacing: '0.15px',
    marginBottom: '8px',
    fontFeatureSettings: '"liga" off, "clig" off',
  },
  contentContainer: {
    padding: '0 16px',
  },
  closeButton: {
    marginLeft: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
};

const PLAN_TYPE_NAMES = {
  LTD: 'Long-Term Disability',
  STD: 'Short-Term Disability',
  LADD: 'Life and Accidental Death & Dismemberment',
};

export default function ViewAcLine({ plan, onClose }) {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const { id: memberId } = useParams();

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const getPlanDisplayName = (plan) => {
    return PLAN_TYPE_NAMES[plan?.displayName] || plan?.displayName;
  };

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
      <Box sx={acDrawerSharedStyles.componentContainer}>
        <Box sx={componentStyles.header}>
          <Box sx={componentStyles.titleContainer}>
            <Typography sx={componentStyles.planName}>
              {getPlanDisplayName(plan)}
            </Typography>
            <IconButton
              onClick={handleCloseDrawer}
              sx={componentStyles.closeButton}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider sx={componentStyles.divider} />
        </Box>

        <Box sx={componentStyles.contentContainer}>
          <Typography sx={componentStyles.manageTitle}>
            Manage Coverage
          </Typography>

          <PendingChangesWarning
            memberId={memberId}
            planId={plan?.planId}
            displayName={plan?.displayName}
            onModifyClick={() => console.log('Modify clicked')}
            onCancelChanges={() => console.log('Cancel clicked')}
          />
        </Box>
      </Box>
    </Drawer>
  );
}

ViewAcLine.propTypes = {
  plan: PropTypes.shape({
    displayName: PropTypes.string,
    planId: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
};
