import React, { useEffect, useState } from 'react';

//MuiComponents
import Checkbox from '@mui/material/Checkbox';

//Our components
import SaveChangesSnackbar from '../../../save-changes-snackbar/SaveChangesSnackbar';

//Our icons
import CustomCheckIcon from '../../../../assets/images/CustomCheckIcon.svg';
import { Slider } from '@mui/material';

export default function DependentSlider(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let percentFormatter = new Intl.NumberFormat('en-US', { style: 'percent' });

  let results = props.results;
  let plan = props.plan;

  let nonDeclineOptions = plan.dependentOptions.filter(
    (option) => option.isDecline === false
  );

  const [opacity, setOpacity] = useState(props.survey.HaveDependents ? 1 : 0.6);
  useEffect(() => {
    if (props.checkboxes.dependent) setOpacity(1);
    else if (!props.checkboxes.dependent && !props.survey.HaveDependents)
      setOpacity(0.6);
  }, [props.checkboxes.dependent]);

  const [displayAlert, setDisplayAlert] = useState(false);
  const checkMemberIsSelected = () => {
    if (props.checkboxes.member !== true) {
      setDisplayAlert(true);
    }
  };

  const dependentValuetext = (value) =>
    `${UsdFormatter.format(nonDeclineOptions[value]?.benefitAmount)}`;

  console.log('nondecline dep options', nonDeclineOptions);

  return (
    <>
      <SaveChangesSnackbar
        displayAlert={displayAlert}
        setDisplayAlert={setDisplayAlert}
        text="Member must be selected to enroll dependent coverage"
        severity="error"
        backgroundColor="#CD2026"
      />
      <div style={{ opacity: opacity }} onClick={checkMemberIsSelected}>
        <div
          className="flex-row container"
          style={{
            padding: '0px 0px 0px 0vw',
            position: 'relative',
            marginBottom: '0px',
          }}
        >
          <Checkbox
            onChange={props.criticalIllnessSelectionHandler}
            value={props.checkboxes.dependent}
            checked={props.checkboxes.dependent}
            name={2}
            inputProps={{
              'data-amount':
                nonDeclineOptions[0]?.memberAndDependentsPremiumAmount,
              'data-person': 'dependent',
            }}
            disabled={props.checkboxes.member !== true}
          />

          <div
            className="col-6"
            style={{
              fontSize: '24px',
              fontWeight: props.checkboxes.dependent ? 800 : 600,
              color: '#195ffb',
            }}
          >
            {UsdFormatter.format(
              nonDeclineOptions[props.dependentSlider]?.benefitAmount
            )}
            {props.calculated && props.survey.HaveDependents && (
              <img src={CustomCheckIcon} style={{ marginLeft: '5px' }} />
            )}
            <p
              style={{
                fontSize: '16px',
                fontWeight: props.checkboxes.dependent ? 800 : 400,
                color: 'black',
                marginBottom: '0px',
                whiteSpace: 'nowrap',
              }}
            >
              Dependent(s){' '}
            </p>
            {props.dependentMax < plan.dependentMaximumAmount && (
              <p
                style={{
                  fontSize: '10px',
                  fontWeight: 400,
                  color: 'black',
                  marginBottom: '0px',
                  whiteSpace: 'nowrap',
                }}
              >
                (cannot exceed{' '}
                {percentFormatter.format(plan.dependentPercentageCap)} of member
                election)
              </p>
            )}
          </div>
          <div
            className="col-6"
            style={{
              textAlign: 'right',
              position: 'absolute',
              right: '8%',
              top: '10%',
              fontSize: '16px',
            }}
          >
            <span
              style={{
                fontSize: '18px',
                fontWeight: props.checkboxes.dependent ? 800 : 500,
                color: props.dummyFontColor,
              }}
            >
              {props.checkboxes.dependent && '+'}
              {!props.dummyDisplay &&
                props.dependentMax != 0 &&
                UsdFormatterDec.format(
                  nonDeclineOptions[props.dependentSlider]
                    ?.memberAndDependentsPremiumAmount
                )}
              {(props.dummyDisplay || props.dependentMax == 0) && '$0.00'}
            </span>
          </div>
        </div>
        {nonDeclineOptions?.length > 1 && (
          <div
            className="flex-row container"
            style={{ padding: '0px 0px', marginBottom: '0px' }}
          >
            <Slider
              className="flex-row"
              id="spouse-slider"
              onChange={props.dependentSliderChange}
              value={props.dependentSlider}
              marks={props.marks}
              componentsProps={{
                markLabel: {
                  style: {
                    color: '#195ffb',
                    top: '-50%',
                    fontWeight: 600,
                    fontSize: '16px',
                  },
                },
              }}
              step={1}
              min={0}
              max={nonDeclineOptions.length - 1}
              sx={{
                display: 'block',
                margin: 'auto',
                marginBottom: '0px',
                marginTop: '20px',
                width: '80%',
              }}
              valueLabelDisplay="auto"
              valueLabelFormat={dependentValuetext}
            />
          </div>
        )}
        <div
          className="flex-row container"
          style={{ padding: '0 8%', marginBottom: '15px' }}
        >
          <div className="ladd-explanation">
            {plan.dependentExplanationOfBenefits}
          </div>
        </div>
      </div>
    </>
  );
}
