import React, { useEffect, useState, useContext } from 'react';
//Assets
import airLogo from '../../../../../../assets/images/TeamstersAirLogo.png';
//Services
import calculatorService from '../../../../../../services/calculator-service';
//Mui icons
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FlightIcon from '@mui/icons-material/Flight';

//Mui Components
// import { Box } from '@mui/system';
import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Typography,
  Fade,
} from '@mui/material';

//Our Components
import EnrollmentSummaryCard from './summary-cards/EnrollmentSummaryCard';
import PlanSummaryCard from './summary-cards/PlanSummaryCard';
import SignatureModal from '../../../../../app-external/external-enrollments/signature-modal/SignatureModal';
import NonPeakPayChip from '../../../DisplayChips/NonPeakPayChip';
//Our components for R-side of plans tab
import MemberPlanPaymentSummary from './plan-tab-left-side/MemberPlanPaymentSummary';
import MemberPlanPaymentSchedule from './plan-tab-left-side/MemberPlanPaymentSchedule';
import MemberPlanDocuments from './plan-tab-left-side/MemberPlanDocuments';
import PaymentHealthChips from '../../../DisplayChips/PaymentHealthChips';
import { set } from 'date-fns';
import PilotStatusModal from './PilotStatusModal';

//utils
import { isAirPlan } from '../../../../../utils';

const componentStyles = {
  titleContainer: { padding: '16px 16px 0px', borderBottom: '0px' },
  sectionTitle: {
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '32px',
    letterSpacing: '.15px',
    color: 'light',
    width: '100%',
  },
  sectionSubtitle: {
    fontFamily: 'Archivo',
    fontWeight: 400,
    fontSie: '16px',
    lineHeight: '28px',
    letter: '.15px',
    color: 'text.gray',
    width: '100%',
  },
  divider: {
    width: '98%',
    height: '1px',
    margin: '0 auto',
    color: 'background.border',
    opacity: 0.6,
  },
  // enrollmentMenuContainer: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   justifyContent: 'flex-end',
  //   textAlign: 'end',
  // },
  // enrollmentMenuOptions: {
  //   color: 'text.primary',
  //   fontFamily: 'Archivo',
  //   fontWeight: 400,
  //   fontSize: '14px',
  //   lineHeight: '24px',
  //   height: '48px',
  //   '&:hover': { backgroundColor: 'primary.light' },
  // },
};

export default function MemberPlansTab(props) {
  //////////////RE-enrollment temp modicfications work calculator service logic for getting and answering isPilot question to conditionally render open re-enrollment
  //Temoporary for IBT Air re-enrolls We will remove this after getting re-enroll full sorted.
  // const [isPilot, setIsPilot] = useState(null);
  // const [surveyResponses, setSurveyResponses] = useState(null);
  const getSurveyResponses = async () => {
    let _surveyResponses = null;
    try {
      _surveyResponses =
        await calculatorService.getRecommendationResponsesForMember(
          props?.member?.id
        );
      if (_surveyResponses) {
        props?.setSurveyResponses(_surveyResponses);
        setLoading(false);
        //close pilot modal after getting responses
        handleClosePilotModal();
        return _surveyResponses;
      }
    } catch (error) {
      console.error('error ngetting survey responses', error);
      //close pilot modal after getting responses
      handleClosePilotModal();
    }
  };

  useEffect(() => {
    if (props?.member?.id) {
      getSurveyResponses();
    }
  }, [props?.member?.id]);

  //update survey response for is pilot question
  const [loading, setLoading] = useState(false);
  const updateSurveyResponse = async (question, answer) => {
    setLoading(true);
    //local obj to send survey response to db
    let localSurveyResObj = { ...props?.surveyResponses };
    //update local obj
    localSurveyResObj = { ...localSurveyResObj, [question]: answer };
    //update db
    try {
      await calculatorService.getRecommendationsForMember(
        props?.member?.id,
        localSurveyResObj
      );
    } catch (error) {
      console.error('error updating survey response', error);
    }
    //use this value to stop loading after update

    setTimeout(() => {
      getSurveyResponses();
    }, 500);
  };

  //open modal to update pilot status
  const [openPilotModal, setOpenPilotModal] = useState(false);

  const handleOpenPilotModal = () => {
    setOpenPilotModal(true);
  };
  const handleClosePilotModal = () => {
    setOpenPilotModal(false);
  };

  //determine if current AC is different groupPlanId from open enrollment - ex: we want gpId = 20 teamsters to be able to enroll in gpId 19
  //will remove this after we release re-enrollment fully
  const [openEnrollmentDiffGpId, setOpenEnrollmentDiffGpId] = useState(true);

  const openEnrollmentDifferentGroupPlanId = () => {
    let _openEnrollment = props?.availableEnrollments?.find(
      (enrollment) => enrollment?.isEnrollmentOpen
    );
    let _currentActiveCoverage = props?.currentActiveCoverage[0];
    if (
      _openEnrollment &&
      _currentActiveCoverage &&
      props?.futureActiveCoverage?.length === 0 && // make sure they don't have future AC from deferred CED
      _openEnrollment?.groupPlanId !== _currentActiveCoverage?.groupPlanId
    ) {
      return setOpenEnrollmentDiffGpId(true);
    }
    return setOpenEnrollmentDiffGpId(false);
  };

  useEffect(() => {
    if (props?.availableEnrollments?.length > 0) {
      openEnrollmentDifferentGroupPlanId();
    }
  }, [
    props?.currentActiveCoverage,
    props?.futureActiveCoverage?.length,
    props?.availableEnrollments,
  ]);

  ////////////////////////////////////////////////////////////////

  const [permissionsEnabler, setPermissionsEnabler] = useState({});
  useEffect(() => {
    if (props.permissionsEnabler) {
      setPermissionsEnabler(props.permissionsEnabler);
    }
  }, [props.permissionsEnabler]);
  const [currentEdit, setCurrentEdit] = useState({});
  let planCosts = props?.planCosts;
  const [paymentSchedule, setPaymentSchedule] = useState();
  useEffect(() => {
    setPaymentSchedule(props.paymentSchedule);
  }, [props?.paymentSchedule]);
  //make sure a user can edit a wallet from the plans tab
  useEffect(() => {
    props.setEditWalletFromPlans(true);
  }, []);
  //format date to be used in other components throughout plans summary
  const formatDate = (rawDate) => {
    // if (rawDate?.length < 11) rawDate = new Date(rawDate).toISOString();
    let [yyyy, mm, dd, hh, m, s] = rawDate.split(/[^\d]+/);
    let date = new Date();
    date.setUTCFullYear(+yyyy);
    date.setUTCDate(+dd);
    date.setUTCMonth(mm - 1);
    date.setUTCHours(+hh);
    date.setUTCMinutes(+m + date.getTimezoneOffset());
    date.setUTCSeconds(+s);
    return date;
  };
  //SigModal
  // State starts closed
  const [openEnrollment, setOpenEnrollment] = useState(false);
  const [modalConfig, setModalConfig] = useState('');

  const handleEnrollmentClick = (
    enrollmentStateId,
    cancelEnrollment = false
  ) => {
    if (enrollmentStateId == 2) {
      if (cancelEnrollment) {
        //Show sig modal for cancel
        setModalConfig('cancelEnrollment');
        setSigModalOpen(true);
        //  setEnrollmentMenu(null)
      } else {
        //Show sig modal for reopen
        setModalConfig('Reopen');
        setSigModalOpen(true);
        //  setEnrollmentMenu(null)
      }
    } else if (enrollmentStateId == 3) {
      setSigModalOpen(true);
      setModalConfig('Cancel');
      //  setEnrollmentMenu(null)
    } else {
      setOpenEnrollment(true);
      //  setTabValue(4)
    }
  };
  const [sigModalOpen, setSigModalOpen] = useState(false);

  //PAY CHIP DISPLAY
  const [payChipDisplay, setPayChipDisplay] = useState('');

  const handlePayChipDisplay = () => {
    if (
      props?.paymentSchedule?.length < 1 &&
      !props?.topOutstandingBanance >= 0
    )
      return '';
    //display health chip if member has active covrage and is on peak pay
    if (
      props?.currentActiveCoverage?.length > 0 &&
      props?.paymentSchedule[0]?.id > 0 &&
      props?.paymentSchedule[0]?.isPeakPay
    ) {
      return (
        <PaymentHealthChips paymentHealthStatus={props.topOutstandingBalance} />
      );
      //display not on peak pay chip if member has active coverage and is not on peak pay
    } else if (
      !props?.paymentSchedule[0]?.isPeakPay &&
      props?.currentActiveCoverage?.length > 0
    ) {
      return <NonPeakPayChip />;
    }
  };

  //REFRESH DISPLAY AS DATA IS LOADED
  useEffect(() => {
    let _display = handlePayChipDisplay();
    setPayChipDisplay(_display);
  }, [
    props?.currentActiveCoverage?.length,
    props?.paymentSchedule?.length,
    props?.topOutstandingBalance,
  ]);

  ////////////check open enrollment against potential future activeCoverage///////////////////

  const checkOpenEnrollmentAgainstFutureAC = (singleEnrollment) => {
    //if future AC exists and an available(open) enrollmentId matches - display = true - this means agent can still make changes to submitted enrollment
    //if future AC exists and open enrollmentId does NOT match but groupPlanId matches - display = false (this means member is already enrolled in a future window from a deferred CED)
    let _displayAvailableEnrollment = false;
    if (props?.futureActiveCoverage?.length > 0) {
      let _futureAcEnrollmentIdMatchingOpenEnrollment =
        props?.futureActiveCoverage?.find(
          (futureActiveCoverage) =>
            futureActiveCoverage?.enrollmentId ===
            singleEnrollment?.enrollmentId
        );

      if (_futureAcEnrollmentIdMatchingOpenEnrollment) {
        _displayAvailableEnrollment = true;
      } else {
        //if no matching enrollmentId, check to see if open enrollment has different groupPlanId from future AC - we want to display open enrollments with different groupPlanIds
        let _openEnrollmentGroupPlanDoesNotMatchFutureAc =
          props?.futureActiveCoverage?.find(
            (futureAC) =>
              futureAC?.groupPlanId !== singleEnrollment?.groupPlanId
          );
        if (_openEnrollmentGroupPlanDoesNotMatchFutureAc) {
          _displayAvailableEnrollment = true;
        }
      }
    } else {
      _displayAvailableEnrollment = true;
    }

    return _displayAvailableEnrollment;
  };

  const checkFutureAcInOpenEnrollment = (futureAc) => {
    let _futureAcEnrollmentIdMatchingOpenEnrollment =
      // _testAvailableEnrollments.find(
        props?.availableEnrollments.find(
        (availableEnrollment) =>
          availableEnrollment?.enrollmentId === futureAc?.enrollmentId
      );
    if (_futureAcEnrollmentIdMatchingOpenEnrollment) {
      return false;
    } else {
      return true;
    }
  };

  //if futureAC matches an enrollment that is open - do not display in 'Future Active Coverage' section
  const [displayFutureActiveCoverage, setDisplayFutureAcSection] = useState([]);
  //check future ac against open enrollment - only want to show future AC section if any exists with enrollmentId that does NOT match open enrollment
  useEffect(() => {
    if (props?.futureActiveCoverage?.length > 0) {
      let _displayFutureAcSection = [];
      props?.futureActiveCoverage?.map((futureAc) => {
        let _displayFutureAcInOpenEnrollment =
          checkFutureAcInOpenEnrollment(futureAc);
        if (_displayFutureAcInOpenEnrollment) {
          _displayFutureAcSection.push(futureAc);
        }
      });
      setDisplayFutureAcSection(_displayFutureAcSection);
    } else {
      setDisplayFutureAcSection([]);
    }
  }, [
    props?.futureActiveCoverage?.length,
    props?.availableEnrollments?.length,
  ]);

  return (
    <Box
      className="member-router-container"
      sx={{
        height: 'calc(100vh - 104px)',
        position: 'relative',
        bgcolor: 'background.background',
        color: 'text.primary',
        ...props?.disabledProfileStyle,
      }}
    >
      <PilotStatusModal
        surveyResponses={props?.surveyResponses}
        openPilotModal={openPilotModal}
        handleClosePilotModal={handleClosePilotModal}
        updateSurveyResponse={updateSurveyResponse}
        loading={loading}
        setLoading={setLoading}
      />

      {props.availableEnrollments?.length < 1 &&
      props?.activeCoverage?.length < 1 ? (
        //No Plan Info Message
        <Box
          sx={{
            backgroundColor: '',
            height: '80%',
            textAlign: 'center',
            paddingTop: '20px',
          }}
        >
          <Box
            sx={{
              margin: '0 auto',
              width: '58px',
              height: '58px',
              backgroundColor: '#195FFB12',
              borderRadius: 100,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ManageSearchIcon
              sx={{ width: '28px', height: '28px', color: '#195FFB' }}
            />
          </Box>
          <Box sx={{ marginBottom: '19px' }}>
            Member does not currently have plan information
          </Box>
        </Box>
      ) : (
        //Display existing plan info
        <Paper
          elevation={0}
          sx={{
            height: '100% ',
            border: '0px solid',
            borderRadius: '0px',
            borderColor: 'background.border',
            backgroundColor: 'background.default',
          }}
        >
          <Box
            sx={{
              flexWrap: 'wrap',
              borderBottom: '0px solid',
              borderColor: 'background.border',
              height: '95px',
              padding: '17px 9px 8px',
            }}
          >
            <Box className="col-12">
              <Typography variant="h4">
                All Plans
                <Box
                  sx={{
                    display: props.permissionsEnabler?.viewPayments
                      ? 'inline'
                      : 'none',
                    marginLeft: '10px',
                  }}
                >
                  {payChipDisplay}
                </Box>
              </Typography>
            </Box>
            <Box
              className="col-12"
              sx={{
                fontFamily: 'Archivo',
                fontWeight: 400,
                fontSie: '16px',
                lineHeight: '28px',
                letter: '.15px',
              }}
            >
              Overview of all plans that a member is participating in
            </Box>
          </Box>

          <Divider sx={componentStyles.divider} />

          <Box className="flex-row">
            {/* PLAN INFO */}
            <Box
              className="col-7"
              sx={{
                borderRight: '1px solid',
                borderColor: 'background.border',
                height: 'calc(100vh - 213px)',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              {/* OPEN ENROLLMENTS AND NO CURRENT ACTIVE COVERAGE DISPLAY */}
              {
                //temporarily showing for IBT air re-enrolls, remove this block after re-enroll
                (props?.surveyResponses?.isPilot ||
                  props?.currentActiveCoverage?.length === 0 ||
                  openEnrollmentDiffGpId) && (
                  <>
                    {props.availableEnrollments?.length > 0 ? (
                      <>
                        {
                          props.availableEnrollments.map((enrollment) => {
                          // _testAvailableEnrollments.map((enrollment) => {
                            //check if future AC enrollmentId matches available enrollmentId and display - this mean enrollment is still open and agent can make changes
                            let _displayAvailableEnrollment =
                              checkOpenEnrollmentAgainstFutureAC(enrollment);

                            return _displayAvailableEnrollment ? (
                              <EnrollmentSummaryCard
                                key={enrollment?.enrollmentId}
                                member={props.member}
                                currentActiveCoverage={
                                  props.currentActiveCoverage
                                }
                                getActiveCoverage={props.getActiveCoverage}
                                UsdFormatterDec={props.UsdFormatterDec}
                                singleEnrollment={enrollment}
                                planCosts={planCosts}
                                paymentSchedule={paymentSchedule}
                                enrollmentStates={props.enrollmentStates}
                                dependents={props.dependents}
                                spouse={props.spouse}
                                relationshipTypes={props.relationshipTypes}
                                setSelectedEnrollment={
                                  props.setSelectedEnrollment
                                }
                                setActivePage={props.setActivePage}
                                handleEnrollmentClick={handleEnrollmentClick}
                                documents={props.documents}
                                getAllDocuments={props.getAllDocuments}
                                formatDate={formatDate}
                                updateSinglePlan={props.updateSinglePlan}
                                cancellationReasons={props.cancellationReasons}
                                getMemberHistory={props.getMemberHistory}
                                //ibt air pilot re-enroll logic
                                handleOpenPilotModal={handleOpenPilotModal}
                                surveyResponses={props?.surveyResponses}
                                getPaymentSchedule={props.getPaymentSchedule}
                                profileDisabled={props.profileDisabled}
                                disabledProfileStyle={
                                  props.disabledProfileStyle
                                }
                              />
                            ) : null;
                          })
                        }
                      </>
                    ) : null}
                  </>
                )
              }

              {/* Active Coverages - only render if any exist */}
              {props?.currentActiveCoverage?.length > 0 && (
                <>
                  <Divider sx={componentStyles.divider} />
                  <Box sx={componentStyles.titleContainer}>
                    <Typography sx={componentStyles.sectionTitle}>
                      Active Coverage
                    </Typography>

                    <Typography sx={componentStyles.sectionSubtitle}>
                      View current plan coverage
                    </Typography>
                  </Box>
                  <Divider
                    sx={{
                      margin: '20px 0px 10px',
                      color: 'background.border',
                      opacity: 0.6,
                    }}
                  />
                  {/* hiding temporarily forteamsters  ibt air re-enrolls, POTENTIAL OPEN ENROLLMENT available to this member who currently has AC -NEED updated method to check and display this*/}
                  {/* {props?.availableEnrollments?.length > 0 &&
                    surveyResponses?.isPilot && (
                      <>
                        {props.availableEnrollments?.length > 0 ? (
                          <>
                            {props.availableEnrollments.map(
                              (enrollment) => (
                                console.log('enrollment MAPPED', enrollment),
                                (
                                  <EnrollmentSummaryCard
                                    member={props.member}
                                    currentActiveCoverage={
                                      props.currentActiveCoverage
                                    }
                                      getActiveCoverage={props.getActiveCoverage}
                                    UsdFormatterDec={props.UsdFormatterDec}
                                    singleEnrollment={enrollment}
                                    planCosts={planCosts}
                                    paymentSchedule={paymentSchedule}
                                    enrollmentStates={props.enrollmentStates}
                                    dependents={props.dependents}
                                    spouse={props.spouse}
                                    relationshipTypes={props.relationshipTypes}
                                    setSelectedEnrollment={
                                      props.setSelectedEnrollment
                                    }
                                    setActivePage={props.setActivePage}
                                    handleEnrollmentClick={
                                      handleEnrollmentClick
                                    }
                                    documents={props.documents}
                                    getAllDocuments={props.getAllDocuments}
                                    formatDate={formatDate}
                                    updateSinglePlan={props.updateSinglePlan}
                                    cancellationReasons={
                                      props.cancellationReasons
                                    }
                                      getPaymentSchedule={props.getPaymentSchedule}
                                  />
                                )
                              )
                            )}
                          </>
                        ) : null}
                      </>
                    )} */}

                  {/* LIST OF CURRENT ACTIVE COVERAGE */}
                  {props?.currentActiveCoverage?.length > 0 && (
                    <>
                      {props?.currentActiveCoverage.map((coverage) => (
                        <PlanSummaryCard
                          key={coverage?.enrollmentId}
                          member={props.member}
                          currentActiveCoverage={coverage}
                          getActiveCoverage={props.getActiveCoverage}
                          UsdFormatterDec={props.UsdFormatterDec}
                          planCosts={planCosts}
                          paymentSchedule={paymentSchedule}
                          enrollmentStates={props.enrollmentStates}
                          relationshipTypes={props.relationshipTypes}
                          dependents={props.dependents}
                          spouse={props.spouse}
                          allowModify={permissionsEnabler.modifyEnrollment}
                          allowEditEnrollment={
                            permissionsEnabler.editEnrollment
                          }
                          documents={props.documents}
                          getAllDocuments={props.getAllDocuments}
                          cancellationReasons={props.cancellationReasons}
                          getPaymentSchedule={props.getPaymentSchedule}
                          getMemberHistory={props.getMemberHistory}
                          formatDate={formatDate}
                          getEnrollments={props.getEnrollments}
                          handleActivePageChange={props?.handleActivePageChange}
                          surveyResponses={props?.surveyResponses}
                          updateSurveyResponse={updateSurveyResponse}
                          handleOpenPilotModal={handleOpenPilotModal}
                          profileDisabled={props.profileDisabled}
                          disabledProfileStyle={props.disabledProfileStyle}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
              {/* List of Future Active Coverage - view only */}
              {props?.futureActiveCoverage?.length > 0 &&
                displayFutureActiveCoverage?.length > 0 && (
                  <>
                    <Divider sx={componentStyles.divider} />
                    <Box sx={componentStyles.titleContainer}>
                      <Typography sx={componentStyles.sectionTitle}>
                        Future Active Coverage
                      </Typography>

                      <Typography sx={componentStyles.sectionSubtitle}>
                        View future plan coverage
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        margin: '20px 0px 10px',
                        color: 'background.border',
                        opacity: 0.6,
                      }}
                    />

                    {/* LIST OF FUTURE ACTIVE COVERAGE */}
                    {props?.futureActiveCoverage?.length > 0 && (
                      <>
                        {props?.futureActiveCoverage?.map((coverage) => {
                          const _displayFutureAcInOpenEnrollment =
                            checkFutureAcInOpenEnrollment(coverage);
                          return _displayFutureAcInOpenEnrollment ? (
                            <PlanSummaryCard
                              key={coverage?.enrollmentId}
                              member={props.member}
                              currentActiveCoverage={coverage}
                              getActiveCoverage={props.getActiveCoverage}
                              UsdFormatterDec={props.UsdFormatterDec}
                              planCosts={planCosts}
                              paymentSchedule={paymentSchedule}
                              enrollmentStates={props.enrollmentStates}
                              relationshipTypes={props.relationshipTypes}
                              dependents={props.dependents}
                              spouse={props.spouse}
                              allowModify={permissionsEnabler.modifyEnrollment}
                              allowEditEnrollment={
                                permissionsEnabler.editEnrollment
                              }
                              documents={props.documents}
                              getAllDocuments={props.getAllDocuments}
                              cancellationReasons={props.cancellationReasons}
                              getPaymentSchedule={props.getPaymentSchedule}
                              getMemberHistory={props.getMemberHistory}
                              formatDate={formatDate}
                              getEnrollments={props.getEnrollments}
                              handleActivePageChange={
                                props?.handleActivePageChange
                              }
                              surveyResponses={props?.surveyResponses}
                              updateSurveyResponse={updateSurveyResponse}
                              handleOpenPilotModal={handleOpenPilotModal}
                              profileDisabled={props.profileDisabled}
                              disabledProfileStyle={props.disabledProfileStyle}
                              isFutureCoverage={true}
                            />
                          ) : null;
                        })}
                      </>
                    )}
                  </>
                )}
            </Box>

            {/* RIGHT SIDE - PAYMENT INFO */}
            <Box
              className="col-5"
              sx={{
                height: 'calc(100vh - 213px)',
                overflowX: 'hidden',
                overflowY: 'auto',
              }}
            >
              <MemberPlanPaymentSummary
                totalPlanCosts={props.totalPlanCosts}
                paymentSchedule={props.paymentSchedule}
                UsdFormatterDec={props.UsdFormatterDec}
              />
              <MemberPlanPaymentSchedule
                member={props.member}
                UsdFormatterDec={props.UsdFormatterDec}
                bankAccount={props.bankAccount}
                paymentSchedule={props.paymentSchedule}
                paymentFrequencies={props.paymentFrequencies}
                walletService={props.walletService}
                getPaymentSchedule={props.getPaymentSchedule}
                getBankAccount={props.getBankAccount}
                permissionsEnabler={permissionsEnabler}
                setBankAccount={props.setBankAccount}
                bankAccountTypes={props.bankAccountTypes}
                editWalletFromPlans={props.editWalletFromPlans}
                currentActiveCoverage={props.currentActiveCoverage}
                profileDisabled={props.profileDisabled}
                disabledProfileStyle={props.disabledProfileStyle}
                displayNextPaymentChip={props.displayNextPaymentChip}
                currentCombinedPaymentInfo={props.currentCombinedPaymentInfo}
              />
              <MemberPlanDocuments
                member={props.member}
                permissionsEnabler={permissionsEnabler}
                documents={props.documents}
                getAllDocuments={props.getAllDocuments}
                attachmentTypes={props.attachmentTypes}
                profileDisabled={props.profileDisabled}
                disabledProfileStyle={props.disabledProfileStyle}
              />
            </Box>
          </Box>
        </Paper>
      )}
      <SignatureModal
        agentEnroller={true}
        setAgentEnrollmentPageState={props.setAgentEnrollmentPageState}
        fullMemberObj={props.member}
        guid={props.guid}
        setGuid={props.setGuid}
        results={props.singleEnrollment} // may not need this, might be confusing what enrollment we're working with: prev enrollments submitted in a differnet window or current open enrollment in current windos
        open={sigModalOpen}
        handleClose={() => setSigModalOpen(false)}
        handleOpen={() => setSigModalOpen(false)}
        setEnrollmentSelected={props.setSelectedEnrollment}
        signClick={modalConfig}
        setOpenEnrollment={setOpenEnrollment}
        // setTabValue={setTabValue}
        enrollmentId={props?.singleEnrollment?.enrollmentId}
        setSigModalOpen={setSigModalOpen}
        // displayPay={displayPay}
        refreshEnrollments={props.getEnrollments}
        refreshEnrollment={props.getOneEnrollment}
        setActivePage={props.setActivePage}
        getActiveCoverage={props.getActiveCoverage}
        getPaymentSchedule={props.getPaymentSchedule}
        getEnrollments={props.getEnrollments}
      />
    </Box>
  );
}
