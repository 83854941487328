// import './BenefitsWizardAccident.css';

import React, { useState, useEffect } from 'react';

//Assets

//Services
import questionService from '../../../../../services/question-service';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

//Mui icons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RadioButtonIcon from '@mui/icons-material/RadioButtonUnchecked';

//Our Components
import AccidentSpouseInputs from './accident-components/AccidentSpouseInputs';
import AccidentDependentsInputs from './accident-components/AccidentDependentsInputs';
import AccidentFamilyInputs from './accident-components/AccidentFamilyInputs';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';
import CurrentAcLineChipDisplay from '../CurrentAcLineChipDisplay';
// import AssistanceFooter from '../assistance-footer/AssistanceFooter';
// import UmpMobileStepper from '../../../mobile-stepper/UmpMobileStepper';
// import BenefitsWizardBeneficiary from '../benefits-wizard-beneficiary/BenefitsWizardBeneficiary';

//Our Enrollment Plan Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';

export default function EnrollmentCancer(props) {
  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  let results = props.results;
  let planInfo = results?.cancerPlan;
  let options = planInfo?.options;
  let helpText = planInfo?.helpText;

  const componentStyles = {
    cancerQuestion: {
      margin: '5px 0px 0px',
      padding: '0px',
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '14px',
      alignItems: 'center',
      fontFamily: 'Archivo',
    },
    checkBoxRowContainer: {
      margin: '5px 0px 0px',
      padding: '0px',
      paddingLeft: '8px',
      textAlign: 'left',
      fontWeight: 700,
      fontSize: '14px',
      alignItems: 'center',
    },
    checkBoxLabel: {
      fontWeight: 600,
    },
  };
  // const handleFormChange = (e) => {
  //    e.persist();
  //    let newValue = e.target.value;
  //    let handledId = e.target.id.replace("Updater", "");

  //    props.setBenefitsMemberObj({
  //       ...props.benefitsMemberObj,
  //       [handledId]: newValue
  //    })
  // };
  const [spouseOpacity, setSpouseOpacity] = useState(
    props.recommendations.ShowSpouse ? 1 : 0.6
  );
  const [dependentOpacity, setDependentOpacity] = useState(
    props.recommendations.ShowDependent ? 1 : 0.6
  );
  const [familyOpacity, setFamilyOpacity] = useState(
    props.recommendations.ShowSpouse && props.recommendations.ShowDependent
      ? 1
      : 0.6
  );

  const [seed, setSeed] = useState(1);
  const refreshKey = () => {
    setSeed(Math.random());
  };
  useEffect(() => {
    if (props.selections?.cancer) {
      let planHasSpouseRates = true;

      if (planHasSpouseRates) {
        refreshKey();
      }
    }
  }, [props.selections?.cancerVal]);

  const [questionAnswers, setQuestionAnswers] = useState([]);
  const getQuestionAnswers = async () => {
    let answers = [];
    if (props.agentEnroller) {
      try {
        answers = await questionService.getAnswersForMember(
          props.fullMemberObj.id
        );
      } catch (error) {
        console.error('error getting question answers AGENT', error);
      }
    } else {
      try {
        answers = await questionService.getAnswers();
      } catch (error) {
        console.error('error getting question answers MEMBER', error);
      }
    }
    setQuestionAnswers(answers);
  };

  useEffect(() => {
    if (props.fullMemberObj) getQuestionAnswers();
  }, [props.fullMemberObj, props.agentEnroller]);

  useEffect(() => {
    if (questionAnswers.length > 0) {
      let _additionalInfo = {};
      for (let answerObj of questionAnswers) {
        switch (answerObj.questionId) {
          case 6:
            _additionalInfo.cancer = answerObj.isYes;
            break;
          case 7:
            _additionalInfo.aids = answerObj.isYes;
            break;
          case 8:
            _additionalInfo.skin = answerObj.isYes;
            break;
          case 9:
            _additionalInfo.internal = answerObj.isYes;
            break;
          case 10:
            _additionalInfo.all = answerObj.isYes;
            break;
        }
      }
      setAdditionalInfo(_additionalInfo);
    }
  }, [questionAnswers]);

  // 6 - Do you have cancer?
  // 7 - Do you have AIDS?
  // 8 - Skin Cancer?
  // 9 - Internal Cancer?
  // 10 - All cancer

  const [expanded, setExpanded] = useState(false);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  //    const checkExpanded = () => {
  //     let thisPlanId = planInfo.planId
  //     let planInExpandArray = props.expanded.find(expandObj => Object.keys(expandObj)[0] == thisPlanId)
  //     if (planInExpandArray) {
  //       let bool = planInExpandArray[thisPlanId]
  //       setExpanded(bool)
  //     }
  //   }
  //   useEffect(() => {
  //     if (props.expanded) checkExpanded()
  //   },[props.expanded])

  const [currRecc, setCurrRec] = useState(0);

  const [additionalInfo, setAdditionalInfo] = useState({
    cancer: false,
    aids: false,
    skin: false,
    internal: false,
    all: false,
  });

  const saveAnswers = async () => {
    let answers = [];
    answers.push({ questionId: 6, isYes: additionalInfo.cancer, response: '' });
    answers.push({ questionId: 7, isYes: additionalInfo.aids, response: '' });
    answers.push({ questionId: 8, isYes: additionalInfo.skin, response: '' });
    answers.push({
      questionId: 9,
      isYes: additionalInfo.internal,
      response: '',
    });
    answers.push({ questionId: 10, isYes: additionalInfo.all, response: '' });
    if (props.agentEnroller) {
      try {
        await questionService.saveAnswersForMember(
          props.fullMemberObj.id,
          answers
        );
      } catch (error) {
        console.error('error saving answers AGENT', error);
      }
    } else {
      try {
        await questionService.saveAnswers(answers);
      } catch (error) {
        console.error('error saving answers MEMBER', error);
      }
    }
  };

  const handleRadioSelection = (e, optionId) => {
    //save answers to cancer checkbox questions
    saveAnswers();
    //update db with selection
    props?.handleEnrollClick(optionId);
  };

  let spouseRatesRequired =
    !props.spouse?.dateOfBirth ||
    props.spouse?.dateOfBirth == 'Invalid Date' ||
    props.spouse?.dateOfBirth < new Date(1920, 1, 1) ||
    props.selections.cancerVal == 0 ||
    props.spouseRatesLoading;
  let memberIsSelected;
  let memberAndSpouseIsSelected;
  let memberAndDependentsIsSelected;
  let familyIsSelected;

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Cancer
        </Typography>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        {/* Current AC Line - display only to agent side if member already has this line type of active coverage */}
        {props?.agentEnroller &&
          props?.matchingACPlanType &&
          props?.matchingACPlanType?.length > 0 && (
            <CurrentAcLineChipDisplay plan={props.matchingACPlanType} />
          )}
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: helpText }}
          />
        </Box>

        <Box sx={{ ...componentStyles.cancerQuestion, fontWeight: 700 }}>
          Has anyone to be covered under this policy EVER been diagnosed or
          treated for:
        </Box>
        <Box sx={componentStyles.cancerQuestion}>
          Acquired Immune Deficiency Syndrome (AIDS) or AIDS Related Complex
          (ARC)?
        </Box>

        <Box sx={componentStyles.checkBoxRowContainer}>
          <FormControlLabel
            disableTypography
            sx={componentStyles.checkBoxLabel}
            label="Yes"
            onClick={(e) =>
              setAdditionalInfo({ ...additionalInfo, aids: true })
            }
            control={
              <Checkbox size="small" checked={additionalInfo.aids === true} />
            }
          />
          <FormControlLabel
            disableTypography
            sx={componentStyles.checkBoxLabel}
            label="No"
            onClick={(e) =>
              setAdditionalInfo({ ...additionalInfo, aids: false })
            }
            control={
              <Checkbox size="small" checked={additionalInfo.aids === false} />
            }
          />
        </Box>

        <Box sx={componentStyles.cancerQuestion}>Cancer in any form?</Box>

        <Box sx={componentStyles.checkBoxRowContainer}>
          <FormControlLabel
            disableTypography
            sx={componentStyles.checkBoxLabel}
            label="Yes"
            onClick={() =>
              setAdditionalInfo({ ...additionalInfo, cancer: true })
            }
            control={
              <Checkbox size="small" checked={additionalInfo.cancer === true} />
            }
          />
          <FormControlLabel
            disableTypography
            sx={componentStyles.checkBoxLabel}
            label="No"
            onClick={() =>
              setAdditionalInfo({ ...additionalInfo, cancer: false })
            }
            control={
              <Checkbox
                size="small"
                checked={additionalInfo.cancer === false}
              />
            }
          />
        </Box>

        {/* Additional Info if Yes selected for either checkbox above */}

        <Collapse in={additionalInfo.cancer || additionalInfo.aids}>
          <Box sx={componentStyles.cancerQuestion}>
            Any person with a history of cancer, AIDS, or ARC will be partially
            or totally excluded from coverage under this policy, pursuant to the
            designated exclusion indicated below:
          </Box>

          <Box sx={{ ...componentStyles.cancerQuestion, fontWeight: 500 }}>
            {props.fullMemberObj?.firstName && props.fullMemberObj?.lastName
              ? `${props.fullMemberObj?.firstName} ${props.fullMemberObj?.lastName}`
              : 'Member'}
          </Box>

          <Divider
            sx={{ position: 'relative', color: '#F0F0F0', opacity: 1 }}
          />

          <Box sx={componentStyles.checkBoxRowContainer}>
            <FormGroup>
              <FormControlLabel
                disableTypography
                sx={componentStyles.checkBoxLabel}
                label="Skin Cancer"
                onClick={() =>
                  setAdditionalInfo({
                    ...additionalInfo,
                    skin: !additionalInfo.skin,
                  })
                }
                control={
                  <Checkbox size="small" checked={additionalInfo.skin} />
                }
              />
              <FormControlLabel
                disableTypography
                sx={componentStyles.checkBoxLabel}
                label="Internal Cancer"
                onClick={() =>
                  setAdditionalInfo({
                    ...additionalInfo,
                    internal: !additionalInfo.internal,
                  })
                }
                control={
                  <Checkbox size="small" checked={additionalInfo.internal} />
                }
              />

              <FormControlLabel
                disableTypography
                sx={componentStyles.checkBoxLabel}
                label="All Cancers"
                onClick={() =>
                  setAdditionalInfo({
                    ...additionalInfo,
                    all: !additionalInfo.all,
                  })
                }
                control={<Checkbox size="small" checked={additionalInfo.all} />}
              />
            </FormGroup>
          </Box>
        </Collapse>

        {/*  Selection Options */}
        <RadioGroup
          onChange={props.selectionHandler}
          value={props.selections.cancer || currRecc}
        >
          <>
            {options &&
              options
                .sort((a, b) => a.displayOrder - b.displayOrder)
                .map((option) => {
                  memberIsSelected =
                    props.selections.cancer == option.memberOptionId;
                  memberAndSpouseIsSelected =
                    props.selections.cancer == option.memberAndSpouseOptionId;
                  memberAndDependentsIsSelected =
                    props.selections.cancer ==
                    option.memberAndDependentsOptionId;
                  familyIsSelected =
                    props.selections.cancer == option.familyOptionId;
                  let onlyFamilyAndMemberAvailable =
                    !option.memberAndDependentsAreEnabled &&
                    !option.memberAndSpouseOptionsAreEnabled &&
                    option.familyOptionsAreEnabled &&
                    option.memberOptionsAreEnabled;

                  if (!option.isDecline) {
                    return (
                      <>
                        {/* MEMBER */}
                        {planInfo.memberOptionsAreEnabled &&
                          option.memberOptionId > 0 && (
                            <Box sx={enrollmentPlanStyles.optionRow}>
                              <Box
                                sx={
                                  enrollmentPlanStyles.optionRadioAndTextContainer
                                }
                              >
                                <Radio
                                  sx={
                                    enrollmentPlanStyles.planSelectRadioButton
                                  }
                                  value={option.memberOptionId}
                                  id={`${option.memberOptionId}`}
                                  name="cancer"
                                  icon={
                                    <RadioButtonIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(
                                        false
                                      )}
                                    />
                                  }
                                  checkedIcon={
                                    <RadioButtonCheckedIcon
                                      sx={enrollmentPlanStyles.radioButtonIcon(
                                        false
                                      )}
                                    />
                                  }
                                  onClick={(e) => {
                                    handleRadioSelection(
                                      e,
                                      option.memberOptionId
                                    );
                                  }}
                                  inputProps={{
                                    'data-amount': option.memberPremiumAmount,
                                  }}
                                />

                                <Box
                                  sx={
                                    enrollmentPlanStyles.singleOptionTextContainer
                                  }
                                >
                                  <Typography
                                    sx={enrollmentPlanStyles.singleOptionText(
                                      memberIsSelected
                                    )}
                                  >
                                    Member
                                  </Typography>
                                </Box>
                              </Box>

                              {/* premium amount */}
                              <Box
                                sx={enrollmentPlanStyles.singleOptionText(
                                  memberIsSelected
                                )}
                              >
                                {memberIsSelected && '+'}
                                {UsdFormatterDec.format(
                                  option.memberPremiumAmount
                                )}
                              </Box>
                            </Box>
                          )}

                        {/* MEMBER AND SPOUSE */}
                        {planInfo.memberAndSpouseOptionsAreEnabled &&
                          option.memberAndSpouseOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.memberAndSpouseOptionId}
                                    id={`${option.memberAndSpouseOptionId}`}
                                    name="cancer"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={(e) => {
                                      handleRadioSelection(
                                        e,
                                        option.memberAndSpouseOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount':
                                        option.memberAndSpousePremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: memberAndSpouseIsSelected
                                        ? 1
                                        : spouseOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        memberAndSpouseIsSelected
                                      )}
                                    >
                                      Member and spouse
                                    </Typography>
                                  </Box>
                                </Box>
                                {/* premium amount */}
                                <Box
                                  key={seed}
                                  sx={{
                                    ...enrollmentPlanStyles.singleOptionText(
                                      memberAndSpouseIsSelected
                                    ),
                                    ...enrollmentPlanStyles.animateOnChanges(
                                      spouseRatesRequired
                                    ),
                                  }}
                                >
                                  {spouseRatesRequired ? (
                                    <>Rate pending</>
                                  ) : (
                                    <>
                                      {memberAndSpouseIsSelected && '+'}
                                      {UsdFormatterDec.format(
                                        option.memberAndSpousePremiumAmount
                                      )}
                                    </>
                                  )}
                                </Box>
                              </Box>

                              {/* spouse inputs */}
                              {memberAndSpouseIsSelected && (
                                <AccidentSpouseInputs
                                  spouse={props.spouse}
                                  setSpouse={props.setSpouse}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}

                        {/* MEMBER AND DEPENDENTS  */}
                        {planInfo.memberAndDependentsAreEnabled &&
                          option.memberAndDependentsOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.memberAndDependentsOptionId}
                                    id={`${option.memberAndDependentsOptionId}`}
                                    name="cancer"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={(e) => {
                                      handleRadioSelection(
                                        e,
                                        option.memberAndDependentsOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount':
                                        option.memberAndDependentsPremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: memberAndDependentsIsSelected
                                        ? 1
                                        : dependentOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        memberAndDependentsIsSelected
                                      )}
                                    >
                                      Member and dependents
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* premium amount */}
                                <Box
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    memberAndDependentsIsSelected
                                  )}
                                >
                                  {memberAndDependentsIsSelected && '+'}
                                  {UsdFormatterDec.format(
                                    option.memberAndDependentsPremiumAmount
                                  )}
                                </Box>
                              </Box>
                              {/* dependent inputs */}
                              {memberAndDependentsIsSelected && (
                                <AccidentDependentsInputs
                                  dependents={props.dependents}
                                  setDependents={props.setDependents}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}
                        {/* FAMILY */}
                        {planInfo.familyOptionsAreEnabled &&
                          option.familyOptionId > 0 && (
                            <Box
                              sx={
                                enrollmentPlanStyles.optionAndRequiredInputsContainer
                              }
                            >
                              <Box sx={enrollmentPlanStyles.optionRow}>
                                <Box
                                  sx={
                                    enrollmentPlanStyles.optionRadioAndTextContainer
                                  }
                                >
                                  <Radio
                                    sx={
                                      enrollmentPlanStyles.planSelectRadioButton
                                    }
                                    value={option.familyOptionId}
                                    id={`${option.familyOptionId}`}
                                    name="cancer"
                                    icon={
                                      <RadioButtonIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    checkedIcon={
                                      <RadioButtonCheckedIcon
                                        sx={enrollmentPlanStyles.radioButtonIcon(
                                          false
                                        )}
                                      />
                                    }
                                    onClick={(e) => {
                                      handleRadioSelection(
                                        e,
                                        option.familyOptionId
                                      );
                                    }}
                                    inputProps={{
                                      'data-amount': option.familyPremiumAmount,
                                    }}
                                  />

                                  <Box
                                    sx={{
                                      ...enrollmentPlanStyles.singleOptionTextContainer,
                                      opacity: familyIsSelected
                                        ? 1
                                        : familyOpacity,
                                    }}
                                  >
                                    <Typography
                                      sx={enrollmentPlanStyles.singleOptionText(
                                        familyIsSelected
                                      )}
                                    >
                                      Member and family
                                    </Typography>
                                  </Box>
                                </Box>

                                {/* premium amount */}
                                <Box
                                  sx={enrollmentPlanStyles.singleOptionText(
                                    familyIsSelected
                                  )}
                                >
                                  {familyIsSelected && '+'}
                                  {UsdFormatterDec.format(
                                    option.familyPremiumAmount
                                  )}
                                </Box>
                              </Box>

                              {/* family inputs */}
                              {familyIsSelected && (
                                <AccidentFamilyInputs
                                  spouse={props.spouse}
                                  setSpouse={props.setSpouse}
                                  dependents={props.dependents}
                                  setDependents={props.setDependents}
                                  genderTypes={props?.genderTypes}
                                />
                              )}
                            </Box>
                          )}
                      </>
                    );
                  } else {
                    return (
                      <Box
                        key={option.memberPremiumAmount}
                        sx={enrollmentPlanStyles.declineOptionRow}
                      >
                        <Radio
                          sx={enrollmentPlanStyles.planSelectRadioButton}
                          value={option.memberOptionId}
                          name="cancer"
                          icon={
                            <RadioButtonIcon
                              sx={enrollmentPlanStyles.radioButtonIcon(false)}
                            />
                          }
                          checkedIcon={
                            <RadioButtonCheckedIcon
                              sx={enrollmentPlanStyles.radioButtonIcon(false)}
                            />
                          }
                          onClick={(e) => {
                            handleRadioSelection(e, option.memberOptionId);
                          }}
                          inputProps={{
                            'data-amount': option.memberPremiumAmount,
                          }}
                        />
                        <Box
                          sx={enrollmentPlanStyles.declineOptionText(
                            memberIsSelected
                          )}
                        >
                          Not Now
                        </Box>
                      </Box>
                    );
                  }
                })}
          </>
        </RadioGroup>
      </Collapse>

      {!expanded && (
        <Box sx={enrollmentPlanStyles.collapsedPlanSection}>
          {props.selections.cancer && props.selections.cancerVal > 0 && (
            <>
              <Box>
                <RadioButtonCheckedIcon
                  sx={enrollmentPlanStyles.radioButtionIconCollapse}
                />
                {planInfo.options.map((option) => {
                  if (props.selections.cancer == option.memberOptionId)
                    return 'Member';
                  else if (
                    props.selections.cancer == option.memberAndSpouseOptionId
                  )
                    return 'Member and Spouse';
                  else if (
                    props.selections.cancer ==
                    option.memberAndDependentsOptionId
                  )
                    return 'Member and Dependents';
                  else if (props.selections.cancer == option.familyOptionId)
                    return 'Family';
                })}
              </Box>

              {memberAndSpouseIsSelected && spouseRatesRequired ? (
                <>Rate pending</>
              ) : (
                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.cancerVal)}
                </Box>
              )}
            </>
          )}
          {props.selections.cancer && props.selections.cancerVal == 0 && (
            <>
              <Typography>
                <em>not elected</em>
              </Typography>
              <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                $0.00
              </Typography>
            </>
          )}
          {!props.selections.cancer && 'No plan selected'}
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
