import React, { useState } from 'react';

//MUI Components
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

//services
import userService from '../../../services/user-service';
import memberService from '../../../services/member-service';
import adminService from '../../../services/admin-service';
import externalServices from '../../../services/external-service';

export default function PayloadDumpTest(props) {
  let debugging = window.location.hostname === 'cornerstone.unionhub-dev.com';

  const [payload, setPayload] = useState('');
  const [response, setResponse] = useState('');
  const [open, setOpen] = useState(false);

  const handleSend = async () => {
    let payloadData;
    try {
      payloadData = JSON.parse(payload);
    } catch (error) {
      console.error('Invalid JSON');
      setResponse('Invalid JSON');
      return;
    }
    try {
      const res = await externalServices.sendPayload(payloadData);
      setResponse(res);
    } catch (error) {
      setResponse(error);
    }
  };

  return (
    <>
      {debugging && (
        <>
          <Box>
            <TextField
              value={payload}
              fullWidth
              maxRows={10}
              onChange={(e) => setPayload(e.target.value)}
              multiline
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{ height: 50, width: 300, marginTop: '15px' }}
              onClick={handleSend}
            >
              Send to Pru API
            </Button>
          </Box>

          <Box>
            <Button
              variant="contained"
              sx={{
                height: 50,
                width: 300,
                marginTop: '15px',
                marginBottom: '15px',
              }}
              onClick={() => setOpen((prev) => !prev)}
            >
              Show Response
            </Button>
            <Collapse in={open}>
              <Box>
                <TextField
                  fullWidth
                  value={JSON.stringify(
                    response,
                    (key, value) => {
                      return value;
                    },
                    2
                  )}
                  multiline
                />
              </Box>
            </Collapse>
          </Box>
        </>
      )}
    </>
  );
}
