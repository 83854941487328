import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format, addYears, set } from 'date-fns';

//MuiX
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

//services
import paymentsService from '../../../../../../../../services/payments-service';

// Assets

//Mui Components

import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  Chip,
  Drawer,
  FormControl,
  FormHelperText,
  InputAdornment,
  Menu,
  MenuItem,
  Typography,
  Divider,
  FormGroup,
  FormControlLabel,
  Radio,
  Select,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';

//Mui icons
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

//utils
import {
  formatUTCToShortDate,
  formatUTCDateToObjWithTimeZone,
  formatUTCYYYYMMDD,
  formatDateObjYYYYMMDD,
} from '../../../../../../../utils';

export default function PaymentInputs(props) {
  //date values from parent component
  const { currentDate, dateMinObj, dateMaxObj } = props;
  //Component Styles
  const componentStyles = {
    componentBody: {
      fontFamily: 'Poppins',
      minWidth: '538px',
      maxWidth: '538px',
      height: '100%',
      backgroundColor: 'background.default',
    },
    headerContainer: {
      width: '100%',
      padding: '13px 38px',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 auto',
      borderBottom: '2px solid',
      borderColor: 'primary.main',
    },
    title: {
      color: 'text.primary',
      fontFamily: 'Poppins',
      fontSize: '24px',
      fontWeight: '400',
    },
    description: {
      color: 'text.primary',
      fontFamily: 'Archivo',
      fontSize: '14px',
      fontWeight: '500',
      padding: '10px 16px',
    },
    closeDrawerButton: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    interactionBody: {
      padding: '34px 32px 8px',
      flexWrap: 'wrap',
    },
    selectionContainers: {
      marginTop: '20px',
      width: '100%',
    },
    //PAYMENT SCHEDULE CARD STYLES
    paymentScheduelCard: {
      border: 'background.border',
      backgroundColor:
        props?.paymentSchedule?.length > 0 &&
        props?.paymentSchedule[0]?.id !== 0
          ? 'background.default'
          : 'background.paper',
      padding: '8px 8px 4px 8px',
      width: '100%',
      margin: '0px 0px 12px',
      display: 'flex',
      flexWrap: 'wrap',
      // justifyContent: "flex-start",
      alignItems: 'top',
      fontFamily: 'Poppins',
      minHeight: '85px',
    },
    iconContainer: {
      display: 'flex',
      maxWidth: '35px',
      margin: '0 auto',
    },
    paymentInfo: {
      flex: '2',
      // paddingLeft: "6px",
      maxWidth: '220px',
      // border: "2px solid red",
    },
    chipDesign: {
      backgroundColor: 'background.paymentScheduleChip',
      color: 'primary.paymentScheduleChip',
      height: '24px',
      fontWeight: '500',
      lineHeight: '18px',
      fontFamily: 'Archivo',
      fontSize: '13px',
      borderRadius: '4px',
      marginRight: '5px',
    },
    amountTotalContainer: {
      display: 'flex',
      flexDirection: 'column',
      // justifyContent: "center",
      padding: '0 4px',
      // border: "2px solid red",
    },
    amountTotal: {
      fontSize: '20px',
      fontWeight: '500',
      height: '24px',
      fontFamily: 'Poppins',
      alignSelf: 'bottom',
      // border: "2px solid red",
    },
    perMonth: {
      fontSize: '12px',
      fontWeight: '400px',
      height: '24px',
      fontFamily: 'Poppins',
      alignSelf: 'bottom',
      marginTop: '10px',
      paddingLeft: '5px',
    },
    paymentAmountText: {
      color: 'text.secondary',
      fontWeight: '400',
      fontSize: '14px!important',
    },
    scheduleChipContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      marginTop: '6px',
      width: '100%',
    },
  };

  let numberFormatter = new Intl.NumberFormat('en-US', {
    style: 'decimal', // Use "decimal" instead of "currency"
    minimumFractionDigits: 2, // Optionally specify the minimum number of fraction digits
    maximumFractionDigits: 2, // Optionally specify the maximum number of fraction digits
  });

  const [newPaymentObj, setNewPaymentObj] = useState(props.newPaymentObj);
  const [selectedPaymentSchedule, setSelectedPaymentSchedule] = useState(
    props?.paymentSchedule[0]
  );
  // rounded up as default
  const [roundCentUp, setRoundCentUp] = useState(true);

  //set new payment object amount to be the rerunPaymentAmount if this is a rerun payment (rerunPaymentAmount > 0)
  useEffect(() => {
    //currently not setting a local state of newPaymentObj since we are only assigning the amount if a payment is being reRun from the data grid
    setNewPaymentObj(props.newPaymentObj);
    if (props?.rerunPaymentAmount > 0) {
      props.setNewPaymentObj({
        ...props.newPaymentObj,
        paymentAmount: props.rerunPaymentAmount,
      });
    }
  }, [props?.rerunPaymentAmount]);

  //check payment schedule regularly scheduled day(s) of month AND that selected pay schedule isEnabled=true and set scheduled payment amount for selected dtae if applicable and  if additional $1 transation fee is needed
  useEffect(() => {
    props?.determineTransFee(newPaymentObj?.paymentDate);
  }, [
    newPaymentObj?.paymentDate,
    props?.paymentSchedule,
    props?.paymentDrawerOpen,
  ]);

  //set local state of newPaymentObj to be the props.newPaymentObj
  useEffect(() => {
    setNewPaymentObj(props.newPaymentObj);
  }, [props?.newPaymentObj?.paymentDate, props?.newPaymentObj]);

  //select a payment schedule if multiple exist:
  const selectPaymentScheduleHandler = (schedule) => {
    setSelectedPaymentSchedule(schedule);
    //if payment schedule changed, reset newPaymentObj and props.newPaymentObj payment amount to reflect the new total monthly amount from selected schedule - default to one payment
    balanceInputHandler(null, schedule?.paymentAmount, 'One payment');
  };

  //determine Chip fill (monthly vs semi-monthly based on paymentFrequencyId)
  const getMemberPaymentFrequency = (frequencyId) => {
    let paymentFrequency = props.paymentFrequencies?.find(
      (scheduleFrequency) => {
        //if member paymentFrequencyId = 1, frequency is monthly, if 2, semi-monthly
        return scheduleFrequency.id === frequencyId;
      }
    );
    return paymentFrequency?.name?.toUpperCase() || '';
  };

  //list of payment dates (ex. [1, 15] would be 1st and 15th of month, add suffix for display)
  const getDaySuffix = (day) => {
    if (typeof day !== 'number' || isNaN(day)) {
      return `No date(s) selected`;
    }
    if (day >= 4 && day <= 20) {
      return `${day}th`;
    }
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  //will be used later when we give options to select a payment amount
  const balanceInputHandler = (e, paymentAmount, multiplierName) => {
    //round payment amount to nearest cent based on up/down toggle
    let _paymentAmount = paymentAmount;
    //check multiplier name and pass through handleRoundCent function if it is a 1/2 payment
    if (multiplierName?.includes('.5')) {
      _paymentAmount = handleRoundCent(paymentAmount);
    }

    if (_paymentAmount > 0) {
      props.setNewPaymentObj({
        ...props.newPaymentObj,
        paymentAmount: _paymentAmount,
        paymentMultiplierName: multiplierName,
      });
      setNewPaymentObj({
        ...newPaymentObj,
        paymentAmount: _paymentAmount,
        paymentMultiplierName: multiplierName,
      });
    }
  };

  //toggle uneven 1/2 payment cent rounding
  const toggleRoundCent = () => {
    //if it is true at start of toggle event, will be false after toggle event so subtract a cent from payment amount, otherwise add a cent
    //only add or subtract a cent if payment amount selected contains half payments
    let containsHalfPayment =
      (props?.newPaymentObj?.paymentAmount /
        selectedPaymentSchedule?.paymentAmount) %
        1 !==
      0;

    let _paymentAmount;
    let formattedPayment;

    if (containsHalfPayment) {
      if (roundCentUp) {
        _paymentAmount = props?.newPaymentObj?.paymentAmount - 0.01;
        formattedPayment = parseFloat(_paymentAmount.toFixed(2));
        props?.setNewPaymentObj({
          ...props.newPaymentObj,
          paymentAmount: formattedPayment,
        });
      } else {
        _paymentAmount = props?.newPaymentObj?.paymentAmount + 0.01;
        formattedPayment = parseFloat(_paymentAmount.toFixed(2));
        props?.setNewPaymentObj({
          ...props.newPaymentObj,
          paymentAmount: formattedPayment,
        });
      }
    }
    setRoundCentUp(!roundCentUp);
  };

  const handleRoundCent = (amount) => {
    //if roundCentUp is true, round up to the nearest cent

    let roundedAmount = 0;
    if (roundCentUp) {
        roundedAmount = Math.ceil(parseFloat(amount * 100).toFixed()) / 100;
      return roundedAmount;
    } else {
        roundedAmount = Math.floor(parseFloat(amount * 100).toFixed()) / 100;
      return roundedAmount;
    }
  };

  const newPaymentObjRadioHandler = (property, bool) => {
    //Swap amount between custom amount and balance here

    if (bool) {
      props.setNewPaymentObj({
        ...props.newPaymentObj,
        [property]: bool,
      });
    } else {
      props.setNewPaymentObj({
        ...props.newPaymentObj,
        [property]: bool,
        paymentDate: formatUTCDateToObjWithTimeZone(
          props.paymentSchedule[0]?.calculatedNextOccurrence
        ),
      });
      // set bank account back to primary account if date is changed from custom to 'next scheduled transaction'
      let _primaryBankAccount = props?.bankAccount?.find(
        (account) => account.isPrimary
      );
      props?.setSelectedBankAccount(_primaryBankAccount);
    }
  };

  const handlePaymentDateChange = (dateObj) => {
    //need to check for date selected compared to calculatedNextOccurrence and if it is the same, set isCustomDate to false
    props?.setIsCustomDate(true);
    let newDate = dateObj;

    //FOR DATE COMPARISON ONLY - format newDateObj, dateMinObj, and dateMaxObj to short date string for date comparison only - do not need to compare time
    let _newDateString = formatDateObjYYYYMMDD(newDate);
    let _dateMinString = formatDateObjYYYYMMDD(dateMinObj);
    let _dateMaxString = formatDateObjYYYYMMDD(dateMaxObj);

    if (_newDateString >= _dateMinString && _newDateString <= _dateMaxString) {
      props.setNewPaymentObj({
        ...props.newPaymentObj,
        paymentDate: newDate,
        customDate: true,
      });

      //update local component state
      setNewPaymentObj({
        ...newPaymentObj,
        paymentDate: newDate,
        customDate: true,
      });
    }
  };

  //potential future code if we ever let agents enter a custom amount
  //  const [customAmountError, setCustomAmountError] = useState(false);
  //  const customAmountCheck = () => {

  //  if (
  //     props.outstandingBalance &&
  //     props.newPaymentObj.paymentAmount > props.outstandingBalance?.balanceAmount
  //  ) {
  //     setCustomAmountError("Must be less than or equal to current balance");
  //  } else {
  //     setCustomAmountError();
  //  }
  //  };

  //select plan to payment is associated with - currently hidden and unused
  const handlePlanSelection = (e, plan) => {
    props.setNewPaymentObj({
      ...props.newPaymentObj,
      enrollmentId: plan.enrollmentId,
    });
  };

  const oneTimePaymentMultiplier = [
    { name: '0.5 month', multiplier: 0.5 },
    { name: '1 month', multiplier: 1 },
    { name: '1.5 months', multiplier: 1.5 },
    { name: '2 months', multiplier: 2 },
    { name: '2.5 months', multiplier: 2.5 },
    { name: '3 months', multiplier: 3 },
    { name: '3.5 months', multiplier: 3.5 },
    { name: '4 months', multiplier: 4 },
  ];

  ///////UPCOMING PAYMENT WITHIN 5 DAYS LOGIC

  function fiveDayCheck(newPaymentDateShort, dateShort) {
    // Convert both short date strings into Date objects
    let newPaymentDateObj = new Date(newPaymentDateShort);
    let dateObj2 = new Date(dateShort);

    // Calculate the difference in time (in milliseconds)
    const timeDifference = Math.abs(newPaymentDateObj - dateObj2);

    // Convert the time difference to days (1000 ms * 60 seconds * 60 minutes * 24 hours)
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);

    // Check if the difference is within 5 days in either direction
    return daysDifference <= 5;
  }

  //check if selected payment date is within 5 days of other payments (existing payment schedule date, another one-time payment, or a rerun payment from 5 day queue)
  // const todayDateShort = formatDateObjMMDDYYYYDisplay(new Date()); // short date string for today
  // tried out  using todayDateShort in the fiveDayCheck function, we ensure that the comparison is always relative to the current date. This means that the function will check
  // if any upcoming payments are within 5 days from today, providing a more accurate warning to the agent

  const handleCheckIfUpcomingPaymentWithin5DaysOfSelected = () => {
    let _scheduleWithin5Days = false;
    // let _oneTimePaymentWithin5Days = false;
    let _futureOneTimePaymentExists = false;
    let _rerunPaymentWithin5Days = false;

    let selectedOneTimePaymentDate = newPaymentObj?.paymentDate; //date object
    let selectedDateShort = formatDateObjYYYYMMDD(selectedOneTimePaymentDate); //short date string

    // check if active payment schedule is true and calculated nextOccurrence in future, before schedule endPaymentDate, and if the selected payment date is within 5 days of this
    if (
      props?.currentCombinedPaymentInfo?.activePaymentSchedule &&
      props?.currentCombinedPaymentInfo?.nextScheduledPaymentDateShort &&
      props?.currentCombinedPaymentInfo?.nextScheduledPaymentDateShort <=
        formatUTCYYYYMMDD(
          props?.currentCombinedPaymentInfo?.activePaymentSchedule
            ?.endPaymentDate
        )
    ) {

      _scheduleWithin5Days = fiveDayCheck(
        selectedDateShort,
        props?.currentCombinedPaymentInfo?.nextScheduledPaymentDateShort
      );
    }
    // check if upcoming one time payment exists and if the selected payment date is within 5 days of this
    if (props?.currentCombinedPaymentInfo?.futureOneTimePayment?.occurrence) {
      //if there is a future one time payment, set state to true and display soonest upcoming one time payment date
      if (props?.currentCombinedPaymentInfo?.futureOneTimePayment?.occurrence) {
        _futureOneTimePaymentExists = true;
      }
    }
    // check if member is in 5 day queue and selected payment date is within 5 days of the nextOccurrence date stuck in past
    if (props?.currentCombinedPaymentInfo?.scheduleHasNextOccWithin5DaysInPast) {
      _rerunPaymentWithin5Days = true;
    }

    //if any of the three values above are true, set state to true
    if (
      _scheduleWithin5Days ||
      // _oneTimePaymentWithin5Days ||
      _futureOneTimePaymentExists ||
      _rerunPaymentWithin5Days
    ) {
      props?.setUpcomingPaymentWithinFiveDays(true);
    } else {
      props?.setUpcomingPaymentWithinFiveDays(false);
    }
  };

  useEffect(() => {
    if (newPaymentObj?.paymentDate) {
      handleCheckIfUpcomingPaymentWithin5DaysOfSelected();
    }
  }, [newPaymentObj?.paymentDate]);

  return (
    <>
      {/* PAY SCHEDULE - if multiple pay schedules with different total monthly amounts, give option to select which one we want to make payments based of of total montly amount owed */}
      {/* {props?.paymentSchedule?.length > 1 && (props?.paymentSchedule[0]?.paymentAmount !== props?.paymentSchedule[1]?.paymentAmount) && 
      <Box sx={componentStyles.selectionContainers}>
        <div className="col-12" style={{ marginBottom: "20px" }}>
        <Alert  severity="warning">
                      <AlertTitle>Multiple Payment Schedules</AlertTitle>
                      This member has pending balance changes taking place next month.  Please select which monthly balance you wish to make a payment against.
                  </Alert>
          </div>

          {props?.paymentSchedule?.map((schedule, index) => {
            return (
              <div key={index} className="col-12" style={{ marginBottom: "10px" }}>
                <Radio
                  checked={selectedPaymentSchedule?.id === schedule?.id}
                  onClick={() => selectPaymentScheduleHandler(schedule)}
                />
                <span style={{ fontFamily: "archivo" }}>
                  Monthly balance - {schedule?.paymentAmount && props?.UsdFormatterDec.format(schedule?.paymentAmount)}
                </span>
              </div>
              )
            })}
          
          
      </Box>
        } */}

      {props?.paymentSchedule?.length > 1 &&
      props?.paymentSchedule[0]?.paymentAmount !==
        props?.paymentSchedule[1]?.paymentAmount ? (
        <>
          <Box className="col-12" style={{ marginBottom: '20px' }}>
            <Alert severity="warning">
              <AlertTitle>Multiple Payment Schedules</AlertTitle>
              This member has pending balance changes taking place next month.
              Please select which monthly balance you wish to make a payment
              against.
            </Alert>
          </Box>
          {props?.paymentSchedule?.map((schedule) => {
            return (
              <Card
                elevation={0}
                variant="outlined"
                key={schedule?.id}
                sx={componentStyles.paymentScheduelCard}
              >
                {schedule?.id !== 0 && schedule ? (
                  <>
                    <Box style={componentStyles.iconContainer}>
                      <CalendarTodayIcon
                        sx={{
                          color: 'primary.main',
                          margin: '6px auto',
                        }}
                      />
                    </Box>

                    {/* Schedule Frequency Chip and Dates */}
                    <Box style={componentStyles.paymentInfo}>
                      <Box className="flex-row">
                        <Chip
                          sx={componentStyles.chipDesign}
                          label={getMemberPaymentFrequency(
                            schedule?.paymentFrequencyId
                          )}
                        />
                        <Box className="flex-row" sx={{ flexWrap: 'wrap' }}>
                          {/* Single Date */}
                          {schedule?.scheduledPaymentDates?.length === 1 && (
                            <>
                              <Typography
                                variant="body2"
                                sx={{
                                  fontFamily: 'Poppins',
                                  fontSize: '14px!important',
                                }}
                              >
                                {getDaySuffix(
                                  schedule?.scheduledPaymentDates[0]?.dayOfMonth
                                )}
                              </Typography>
                              {/* <Typography
                                      variant="body2"
                                      sx={{ fontFamily: "Poppins", fontSize: '14px!important', marginLeft: '4px', lineHeight: '24px' }}
                                    >
                                      of the month
                                    </Typography> */}
                            </>
                          )}

                          {/* Multiple dates */}
                          {schedule?.scheduledPaymentDates?.length > 1 &&
                            schedule?.scheduledPaymentDates?.map(
                              (date, index) => {
                                if (
                                  index ===
                                  schedule?.scheduledPaymentDates.length - 1
                                ) {
                                  return (
                                    <>
                                      <Typography
                                        key={index}
                                        variant="body2"
                                        sx={{
                                          fontFamily: 'Poppins',
                                          fontSize: '14px!important',
                                          lineHeight: '24px',
                                        }}
                                      >
                                        {getDaySuffix(date?.dayOfMonth)}
                                      </Typography>
                                      {/* <Typography
                                              variant="body2"
                                              sx={{ fontFamily: "Poppins", fontSize: '14px!important', marginLeft: '4px', lineHeight: '24px' }}
                                            >
                                              of the month
                                            </Typography> */}
                                    </>
                                  );
                                } else {
                                  return (
                                    <>
                                      <Typography
                                        key={index}
                                        variant="body2"
                                        sx={{
                                          fontFamily: 'Poppins',
                                          marginRight: '4px',
                                          fontSize: '14px!important',
                                        }}
                                      >
                                        {`${getDaySuffix(date?.dayOfMonth)} &`}
                                      </Typography>
                                    </>
                                  );
                                }
                              }
                            )}
                        </Box>
                      </Box>

                      {/* SCHEDULE CHIP DISPLAY */}

                      <Box sx={componentStyles.scheduleChipContainer}>
                        {/* display IF this schedule has an end date - is active until end of month because schedule has been changed - new changes take effect in new month and schedule is NOT DISABLED */}
                        {schedule?.endPaymentDate?.slice(0, 4) !== '9999' && (
                          <Chip
                            icon={
                              <AccessTimeIcon
                                sx={{
                                  height: '24px',
                                  color: 'rgba(123, 31, 162, 1)!important',
                                }}
                              />
                            }
                            sx={{
                              // minWidth: "200px",
                              backgroundColor: 'background.onDisabilityChip',
                              color: 'primary.onDisabilityChip',
                              fontFamily: 'Archivo',
                              margin: '0',
                              fontWeight: '500',
                              alignItems: 'center',
                              fontSize: '12px',
                              alignSelf: 'self-end',
                            }}
                            label={
                              `Schedule ENDS ${formatUTCToShortDate(
                                schedule?.endPaymentDate
                              )}` || ''
                            }
                          />
                        )}
                        {/* display IF this schedule has a start date in future- is not active until start of new month and schedule is NOT DISABLED */}
                        {schedule?.beginPaymentDate >
                          currentDate?.toISOString() && (
                          <Chip
                            icon={
                              <AccessTimeIcon
                                sx={{
                                  height: '24px',
                                  color: 'rgba(230, 81, 0, 1)!important',
                                }}
                              />
                            }
                            sx={{
                              // minWidth: "200px",
                              backgroundColor: 'background.newPaymentSchedule',
                              color: 'primary.newPaymentSchedule',
                              fontFamily: 'Archivo',
                              margin: '0',
                              fontWeight: '500',
                              alignItems: 'center',
                              fontSize: '12px',
                              alignSelf: 'self-end',
                            }}
                            label={
                              `Schedule BEGINS ${formatUTCToShortDate(
                                schedule?.beginPaymentDate
                              )}` || ''
                            }
                          />
                        )}
                      </Box>
                    </Box>

                    {/* Total Monthly Payment Amount */}
                    <Box sx={componentStyles.amountTotalContainer}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          height: '30px',
                          // border: "2px solid red",
                        }}
                      >
                        <Box className="flex-row">
                          <Typography
                            variant="body1"
                            sx={componentStyles.amountTotal}
                          >
                            {(schedule?.paymentAmount &&
                              props.UsdFormatterDec.format(
                                schedule?.paymentAmount
                              )) ||
                              ''}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={componentStyles.perMonth}
                          >
                            /per&nbsp;month
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography
                          variant="body2"
                          sx={{
                            ...componentStyles.paymentAmountText,
                            fontSize: '10px!important',
                          }}
                        >
                          *EXCLUDES TRANS FEE
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Radio
                        sx={{
                          '& .MuiSvgIcon-root': {
                            fontSize: '17px', // Adjust the size of the radio icon
                          },
                        }}
                        checked={selectedPaymentSchedule?.id === schedule?.id}
                        onClick={() => selectPaymentScheduleHandler(schedule)}
                      />
                    </Box>
                  </>
                ) : (
                  <Typography
                    variant="h6"
                    sx={{ padding: '10px', margin: 'auto 0' }}
                  >
                    No Schedule Information
                  </Typography>
                )}
              </Card>
            );
          })}
        </>
      ) : (
        ''
      )}

      {/* PAYMENT AMOUNT */}
      <Box sx={{ ...componentStyles.selectionContainers, marginTop: '10px' }}>
        <Box
          sx={{
            marginBottom: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '24px',
            }}
          >
            {props?.rerunPaymentAmount > 0
              ? 'Rerun Payment Amount'
              : 'Payment Amount'}
          </Typography>

          {/* display IF not a rerun payment */}
          {props?.rerunPaymentAmount === 0 && (
            <>
              <Typography
                sx={{
                  fontFamily: 'Archivo',
                  fontSize: '13.5px!important',
                  margin: '6px 0px',
                  fontWeight: 400,
                }}
              >
                Payment totals are based on increments of this member's monthly
                premium.
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Archivo',
                  fontSize: '12px!important',
                  // margin: '6px 0px',
                  fontWeight: 400,
                }}
              >
                Select number of monthly payment amount:
              </Typography>
            </>
          )}
        </Box>

        <Box
          className="flex-row"
          sx={{
            marginBottom: '10px',
            width: '100%',
            alignItems: 'bottom',
            // justifyContent: "space-between",
          }}
        >
          {/* display options to select payment amount based on total monthly amount IF not a rerun payment */}
          {props?.rerunPaymentAmount === 0 && (
            <TextField
              variant="outlined"
              sx={{ width: '45%', marginRight: '20px' }}
              select
              label="Number of Payments"
              value={newPaymentObj?.paymentAmount || ''}
            >
              {oneTimePaymentMultiplier?.map((multiplier, index) => {
                return (
                  <MenuItem
                    key={multiplier?.multiplier}
                    value={
                      multiplier?.name?.includes('.5')
                        ? handleRoundCent(
                            selectedPaymentSchedule?.paymentAmount *
                              multiplier?.multiplier
                          )
                        : selectedPaymentSchedule?.paymentAmount *
                          multiplier?.multiplier
                    }
                    onClick={(e) =>
                      balanceInputHandler(
                        e,
                        selectedPaymentSchedule?.paymentAmount *
                          multiplier?.multiplier,
                        multiplier?.name
                      )
                    }
                  >
                    {multiplier?.name}
                  </MenuItem>
                );
              })}
            </TextField>
          )}

          <TextField
            variant="standard"
            sx={{ width: '47%', padding: '4px 4px 10px 4px' }}
            label="Total"
            InputProps={{
              readOnly: true, // Disable user input
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
            }}
            value={
              newPaymentObj?.paymentAmount
                ? numberFormatter.format(newPaymentObj?.paymentAmount)
                : ''
            }
          />

          {/* {(parseFloat(selectedPaymentSchedule?.paymentAmount?.toFixed(2)?.slice(-1)) % 2 !== 0) && 
              } */}
        </Box>

        {/* only show the cent toggle if the payment amount is not an even split amount AND it is NOT a rerun payment */}
        {parseFloat(
          selectedPaymentSchedule?.paymentAmount?.toFixed(2)?.slice(-1)
        ) %
          2 !==
          0 &&
          (props?.newPaymentObj?.paymentAmount /
            selectedPaymentSchedule?.paymentAmount) %
            1 !==
            0 &&
          props?.rerunPaymentAmount === 0 && (
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                margin: '0px 0px 10px 10px',
                border: '1px solid red',
              }}
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={roundCentUp}
                      disabled={
                        (props?.newPaymentObj?.paymentAmount /
                          selectedPaymentSchedule?.paymentAmount) %
                          1 ==
                        0
                      }
                      onChange={() => toggleRoundCent()}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label="Round up"
                />
              </FormGroup>

              <Typography
                variant="body2"
                sx={{
                  fontSize: '12px!important',
                  color: 'text.secondary',
                  width: '100%',
                }}
              >
                * Uneven monthly payment amount. Please round 1/2 payments up or
                down a cent.
              </Typography>
            </div>
          )}
      </Box>

      {/* PAYMENT DATE */}
      <Box sx={componentStyles.selectionContainers}>
        <Box className="col-12" style={{ marginBottom: '10px' }}>
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            Payment Date
          </Typography>
        </Box>

        {props?.currentCombinedPaymentInfo?.activePaymentSchedule && (
          <Box className="col-12" sx={{ marginBottom: '10px' }}>
            <Radio
              checked={!newPaymentObj.customDate}
              onClick={() => newPaymentObjRadioHandler('customDate', false)}
            />
            <span style={{ fontFamily: 'archivo' }}>
              Next scheduled transaction: (
              {props?.paymentSchedule[0]?.calculatedNextOccurrence &&
                format(
                  new Date(
                    formatUTCDateToObjWithTimeZone(
                      props?.paymentSchedule[0]?.calculatedNextOccurrence
                    )
                  ),
                  'MM/dd/yyyy'
                )}
              )
            </span>
          </Box>
        )}
        <Box className="col-10">
          <Radio
            checked={newPaymentObj.customDate}
            onClick={() => newPaymentObjRadioHandler('customDate', true)}
            data-property="customDate"
          />
          <span style={{ fontFamily: 'Archivo', marginRight: '13px' }}>
            Custom Date:
          </span>
          {/* <TextField 
             variant="standard"
             
             /> */}
          <span>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                openTo="day"
                maxDate={dateMaxObj}
                minDate={dateMinObj}
                views={['year', 'month', 'day']}
                value={props?.newPaymentObj?.paymentDate || null}
                onChange={(date) => handlePaymentDateChange(date)}
                format="MM/dd/yyyy"
                sx={{ marginBottom: '20px', maxWidth: '180px' }}
              />
            </LocalizationProvider>
          </span>
        </Box>
        {props?.upcomingPaymentWithinFiveDays && (
          <Alert severity="warning">
            A payment is already scheduled around this time, please ensure that
            you are not scheduling a duplicate payment.
          </Alert>
        )}
      </Box>

      {/* hiding for now as it is not currently being used to add one time payment */}
      {/* <div className="col-12" style={{ marginBottom: "20px" }}>
            <TextField
              fullWidth
              select
              required
              sx={{
                "& fieldset": { borderRadius: "4px" },
                "&.MuiOutlinedInput-root.Mui-focused > fieldset": {
                  border: "2px solid #195ffb",
                  borderRadius: "4px",
                },
              }}
              variant="outlined"
              value={newPaymentObj.enrollmentId}
              label="Plan"
              InputLabelProps={{ shrink: true }}
              //  disable={}
            >
              {props.enrollments &&
                props.enrollments?.map((plan) => {
                  return (
                    <MenuItem
                      key={plan.enrollmentId}
                      value={plan.enrollmentId}
                      onClick={(e) => handlePlanSelection(e, plan)}
                    >
                      {plan.name}
                    </MenuItem>
                  );
                })}
            </TextField>
          </div> */}
    </>
  );
}
