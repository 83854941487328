import React, { useState, useEffect } from 'react';

//MuiIcons
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

//MuiComponents
import Collapse from '@mui/material/Collapse';
import RadioGroup from '@mui/material/RadioGroup';
import Divider from '@mui/material/Divider';
import { Box, Typography } from '@mui/material';

//Our icons

//Our Components
import EnrollmentCiMember from './ci-components/EnrollmentCiMember';
import EnrollmentCiSpouse from './ci-components/EnrollmentCiSpouse';
import EnrollmentCiDependent from './ci-components/EnrollmentCiDependent';
import ExpandAndCollapseIcon from '../../enrollment-custom-components/ExpandAndCollapseIcon';

//Shared Styles
import enrollmentPlanStyles from '../enrollmentPlanStyles';

export default function EnrollmentCriticalIllness(props) {
  const componentStyles = {
    selectionSummaryPoints: {
      display: 'flex',
      flexDirection: 'row',
      margin: '0px 0px',
      fontSize: '15px',
      fontWeight: '500',
      padding: '0px',
      justifyContent: 'space-between',
      alignItems: 'center',
      color: 'text.lightGray',
      fontFamily: 'Archivo',
    },
    collapseSectionSummary: {
      marginBottom: '15px',
    },
  };

  let UsdFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  let UsdFormatterDec = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  let results = props.results;
  let criticalIllnessPlan = results?.criticalIllnessPlan;
  let ciHelptext = criticalIllnessPlan?.helpText;
  let recommendations = props.recommendations;

  let nonDeclineMemberOptions = criticalIllnessPlan?.memberOptions.filter(
    (option) => option.isDecline === false
  );
  // let memberDecline = criticalIllnessPlan?.memberOptions.filter(
  //   (option) => option.isDecline === true
  // )[0].memberOptionId;
  let nonDeclineSpouseOptions = criticalIllnessPlan?.spouseOptions.filter(
    (option) => option.isDecline === false
  );
  // let spouseDecline = criticalIllnessPlan?.spouseOptions.filter(
  //   (option) => option.isDecline === true
  // )[0].memberAndSpouseOptionId;
  let nonDeclineDependentOptions = criticalIllnessPlan?.dependentOptions.filter(
    (option) => option.isDecline === false
  );
  // let dependentDecline = criticalIllnessPlan?.dependentOptions.filter(
  //   (option) => option.isDecline === true
  // )[0].memberAndDependentsOptionId;

  const [memberDecline, setMemberDeclineOption] = useState(0);
  const [spouseDecline, setSpouseDeclineOption] = useState(0);
  const [dependentDecline, setDependentDeclineOption] = useState(0);

  useEffect(() => {
    setMemberDeclineOption(
      criticalIllnessPlan?.memberOptions.filter(
        (option) => option.isDecline === true
      )[0].memberOptionId
    );
    setSpouseDeclineOption(
      criticalIllnessPlan?.spouseOptions.filter(
        (option) => option.isDecline === true
      )[0].memberAndSpouseOptionId
    );
    setDependentDeclineOption(
      criticalIllnessPlan?.dependentOptions.filter(
        (option) => option.isDecline === true
      )[0].memberAndDependentsOptionId
    );
  }, [
    criticalIllnessPlan?.memberOptions,
    criticalIllnessPlan?.spouseOptions,
    criticalIllnessPlan?.dependentOptions,
  ]);
  // const storeLocalDecline = (bool) => {
  //   let storedDecline = localStorage.setItem("ciDecline", bool);

  //   if (bool){
  //     props.setCiSelectionMade(true)
  //     setCheckboxes({
  //       ...checkboxes,
  //       member: false
  //     })
  //   } else if (!bool && !checkboxes.member){
  //     // props.setCiSelectionMade(false)
  //   }
  // };

  // const getLocalDecline = () => {
  //   let storedDecline = localStorage.getItem("ciDecline");
  //   if (storedDecline) {
  //     //get the opposite, cause we'll be setting checkboxes.member
  //     let _declinedCi = !Boolean(storedDecline);
  //     setCheckboxes({
  //       ...checkboxes,
  //       member: _declinedCi,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   getLocalDecline();
  // }, []);

  //Alert
  const [displayAlert, setDisplayAlert] = useState(false);

  //member
  const [memberSlider, setMemberSlider] = useState(
    nonDeclineMemberOptions?.length - 1
  );
  // const memberValuetext = (value) => `${UsdFormatter.format(criticalIllnessPlan?.memberOptions[value].benefitAmount)}`

  //spouse
  const [spouseSlider, setSpouseSlider] = useState(
    nonDeclineSpouseOptions?.length - 1
  );
  // const spouseValuetext = (value) => `${UsdFormatter.format(nonDeclineSpouseOptions[value].benefitAmount)}`

  //Variable and states to handle capping the spouse/dependent coverage
  // let spouseMax =
  //   criticalIllnessPlan?.memberOptions[memberSlider]?.benefitAmount *
  //   criticalIllnessPlan?.spousePercentageCap;
  let _spouseMax = 0;
  const [spouseMax, setSpouseMax] = useState(0);

  const [spouseMaxIndex, setSpouseMaxIndex] = useState(
    nonDeclineSpouseOptions?.length - 1
  );

  //dependent
  const [dependentMax, setDependentMax] = useState(
    results?.criticalIllnessPlan.dependentMaximumAmount
  );
  const [dependentMaxIndex, setDependentMaxIndex] = useState(
    nonDeclineDependentOptions?.length - 1
  );

  //dep slider if more than one plan option exists
  const [dependentSlider, setDependentSlider] = useState(
    nonDeclineDependentOptions ? nonDeclineDependentOptions?.length - 1 : 0
  );

  //Changes in our member slider have effects on our spouse and dependent
  useEffect(() => {
    _spouseMax =
      nonDeclineMemberOptions[memberSlider]?.benefitAmount *
      criticalIllnessPlan.spousePercentageCap;
    let currentSpouse = nonDeclineSpouseOptions[spouseSlider]?.benefitAmount;
    // let currentSpouse = 0;

    //Matching the capped coverage in the options arr, then setting the states accordingly
    if (_spouseMax <= criticalIllnessPlan?.spouseMaximumAmount) {
      let count = 0;
      // for later, we could optimize this further by reversing the loop and breaking when we find the first match
      for (let option of nonDeclineSpouseOptions) {
        if (option.benefitAmount <= _spouseMax) {
          setSpouseMax(_spouseMax);
          setSpouseMaxIndex(count);
          if (_spouseMax < currentSpouse) {
            setSpouseSlider(count);
          }
        }
        count += 1;
      }
    } else {
      //THIS IS WHERE THE SPOUSE SLIDER IS BEING MAXED
      setSpouseMaxIndex(nonDeclineSpouseOptions?.length - 1);
      // setSpouseSlider(results?.criticalIllnessPlan.spouseOptions.length-1)
    }

    //DEPENDENT
    let _dependentMax =
      nonDeclineMemberOptions[memberSlider]?.benefitAmount *
      criticalIllnessPlan.dependentPercentageCap;
    let dependentEnabled =
      _dependentMax <= criticalIllnessPlan?.dependentMaximumAmount;

    if (!dependentEnabled) setDependentMax(0);
    // let _dependentMax = 0;
    let currentDep = nonDeclineDependentOptions[dependentSlider]?.benefitAmount;
    // let currentDep = 0;

    //Matching the capped coverage in the options arr, then setting the states accordingly
    if (_dependentMax <= criticalIllnessPlan?.dependentMaximumAmount) {
      let count = 0;
      for (let option of nonDeclineDependentOptions) {
        if (option.benefitAmount == _dependentMax) {
          setDependentMaxIndex(count);
          if (_dependentMax < currentDep) {
            setDependentSlider(count);
          }
        }
        count += 1;
      }
    } else {
      //THIS IS WHERE THE SPOUSE SLIDER IS BEING MAXED
      setDependentMaxIndex(nonDeclineDependentOptions?.length - 1);
    }
  }, [memberSlider, spouseSlider, dependentSlider]);

  //Selection handler, state and effects
  const [checkboxes, setCheckboxes] = useState(
    props?.results?.criticalIllnessPlan?.memberIsDeclined
      ? {
          member: 'decline', //check if declined in db - set all to decline if so
          spouse: 'decline',
          dependent: 'decline',
        }
      : {
          member: 'truthy', //This truthy value lets us display as if member was selected, but still requires user interaction
          spouse: null,
          dependent: null,
        }
  );

  //handle decline selections
  const [memberLocalDecline, setMemberLocalDecline] = useState(false);
  const [spouseLocalDecline, setSpouseLocalDecline] = useState(false);
  const [dependentLocalDecline, setDependentLocalDecline] = useState(false);

  //set decline states and checkboxes from saved info in db
  useEffect(() => {
    //Member
    let _checkboxes = { ...checkboxes };
    if (props?.results?.criticalIllnessPlan?.memberIsDeclined) {
      setMemberLocalDecline(true);
      // setCheckboxes({
      //   ...checkboxes,
      //   member: 'decline',
      //   spouse: 'decline',
      //   dependent: 'decline',
      // });
      checkboxes.member = 'decline';
      checkboxes.spouse = 'decline';
      checkboxes.dependent = 'decline';
    }

    //Spouse
    if (props?.results?.criticalIllnessPlan?.spouseIsDeclined) {
      setSpouseLocalDecline(true);
      // setCheckboxes({
      //   ...checkboxes,
      //   spouse: 'decline',
      // });
      checkboxes.spouse = 'decline';
    }

    //Dependent
    if (props?.results?.criticalIllnessPlan?.dependentIsDeclined) {
      setDependentLocalDecline(true);
      // setCheckboxes({
      //   ...checkboxes,
      //   dependent: 'decline',
      // });
      checkboxes.dependent = 'decline';
    }
    setCheckboxes(_checkboxes);
  }, [
    props?.results?.criticalIllnessPlan?.memberIsDeclined,
    props?.results?.criticalIllnessPlan?.spouseIsDeclined,
    props?.results?.criticalIllnessPlan?.dependentIsDeclined,
  ]);

  const handleCiDeclineSelection = async (e, person) => {
    //member
    if (person == 'member') {
      //update all values for ci selections
      props.setSelections({
        ...props.selections,
        ci: [memberDecline, spouseDecline, dependentDecline],
        ciVal: [0, 0, 0],
      });

      await props.saveCi(
        criticalIllnessPlan?.planId,
        memberDecline,
        spouseDecline,
        dependentDecline
      );

      //decline all
      setMemberLocalDecline(true);
      setSpouseLocalDecline(true);
      setDependentLocalDecline(true);

      setCheckboxes({
        ...checkboxes,
        member: 'decline',
        spouse: 'decline',
        dependent: 'decline',
      });

      // potential - set member and spouse sliders to lowest amounts on decline member
      // setMemberSlider(0)
      // setSpouseSlider(0)
    }

    //new object copy from selections in state to be used to update spouse and deps
    let _updateSelections = {
      ...props?.selections,
      ciVal: [...props?.selections?.ciVal],
      ci: [...props?.selections?.ci],
    };

    //spouse
    if (person == 'spouse') {
      setSpouseLocalDecline(true);
      //set checkbox value to decline
      setCheckboxes({
        ...checkboxes,
        spouse: 'decline',
      });

      //update the values of the new object for spouse only which is at index 1
      _updateSelections.ciVal[1] = 0;
      _updateSelections.ci[1] = spouseDecline;

      //update selections in state
      props.setSelections({
        ...props.selections,
        ..._updateSelections,
      });
      await props.saveCi(
        criticalIllnessPlan?.planId,
        _updateSelections.ci[0],
        _updateSelections.ci[1],
        _updateSelections.ci[2]
      );
    }

    //dependent
    if (person == 'dependent') {
      setDependentLocalDecline(true);
      //set checkbox value to decline
      setCheckboxes({
        ...checkboxes,
        dependent: 'decline',
      });

      //update the values of the new object for dependent only which is at index 2
      _updateSelections.ciVal[2] = 0;
      _updateSelections.ci[2] = dependentDecline;
      //update selections in state
      props.setSelections({
        ...props.selections,
        ..._updateSelections,
      });
      await props.saveCi(
        criticalIllnessPlan?.planId,
        _updateSelections.ci[0],
        _updateSelections.ci[1],
        _updateSelections.ci[2]
      );
    }
  };

  const ciSelectionHandler = async (e, bool) => {
    let checkedBool = Boolean(bool);
    let value = parseFloat(e.target.getAttribute('data-amount'));
    // let coverage = parseInt(e.target.getAttribute('data-coverage'));
    let coverage = parseInt(e.target.getAttribute('data-optionId'));
    // let optionId = parseInt(e.target.getAttribute('data-optionId'));
    let person = e.target.getAttribute('data-person');
    let ciIndex = parseInt(e.target.name);

    //make sure decline option is false for whoever is selected
    if (person == 'member') {
      setMemberLocalDecline(false);
    }

    if (person == 'spouse') {
      setSpouseLocalDecline(false);
    }

    if (person == 'dependent') {
      setDependentLocalDecline(false);
    }

    //Member needs to be selected for spouse and dependent to be avail
    if (person == 'member' && !value) {
      setCheckboxes({
        member: false,
        spouse: false,
        dependent: false,
      });
      return; //exit and trigger useEffect above
    } else {
      setCheckboxes({
        ...checkboxes,
        [person]: checkedBool,
      });
    }

    if (bool) {
      // if (person == 'dependent') {
      //   if (dependentMax == 0) {
      //     value = 0;
      //     coverage = 0;
      //   } else coverage = dependentMax;
      // }

      let _ciVal = props.selections.ciVal.map((x) => x);
      let _ci = props.selections.ci.map((x) => x);
      _ciVal[ciIndex] = value;
      _ci[ciIndex] = coverage;

      props.setSelections({
        ...props.selections,
        ciVal: _ciVal,
        ci: _ci,
      });
      await props.saveCi(criticalIllnessPlan?.planId, _ci[0], _ci[1], _ci[2]);
    } else {
      // let _ciVal = props.selections.ciVal.map((x) => x);
      // let _ci = props.selections.ci.map((x) => x);
      // _ciVal[ciIndex] = 0;
      // _ci[ciIndex] = 0;
      // props.setSelections({
      //   ...props.selections,
      //   ciVal: _ciVal,
      //   ci: _ci,
      // });
    }
    // await props.saveCi(criticalIllnessPlan?.planId, _ci[0], _ci[1], _ci[2]);
  };

  // console.log('ci selections', props.selections.ci, props.selections.ciVal);
  // console.log(
  //   'ci options',
  //   props.results?.criticalIllnessPlan?.memberOptions,
  //   props.results?.criticalIllnessPlan?.spouseOptions,
  //   props.results?.criticalIllnessPlan?.dependentOptions
  // );

  //As the sliders slide, if they're checked, let the value ride
  useEffect(() => {
    let _ciVal = props.selections.ciVal.map((x) => x);
    let _ci = props.selections.ci.map((x) => x);

    //member
    if (checkboxes.member === true && checkboxes.member !== 'decline') {
      _ciVal[0] = nonDeclineMemberOptions[memberSlider]?.memberPremiumAmount;
      _ci[0] = nonDeclineMemberOptions[memberSlider]?.memberOptionId;
    }
    //spouse
    if (checkboxes.spouse && checkboxes.spouse !== 'decline') {
      _ciVal[1] =
        nonDeclineSpouseOptions[spouseSlider]?.memberAndSpousePremiumAmount;
      _ci[1] = nonDeclineSpouseOptions[spouseSlider]?.memberAndSpouseOptionId;
    }
    //dependent
    if (checkboxes.dependent && checkboxes.dependent !== 'decline') {
      _ciVal[2] =
        nonDeclineDependentOptions[
          dependentSlider
        ]?.memberAndDependentsPremiumAmount;
      _ci[2] =
        nonDeclineDependentOptions[
          dependentSlider
        ]?.memberAndDependentsOptionId;
    }

    if (
      checkboxes.dependent &&
      checkboxes.dependent !== 'decline' &&
      dependentMax == 0
    ) {
      _ciVal[2] = 0;
      _ci[2] = dependentDecline;
    } else if (
      (!checkboxes.dependent || checkboxes.dependent === 'decline') &&
      dependentMax == 0
    ) {
      _ciVal[2] = 0;
      _ci[2] = dependentDecline;
    }
    props.setSelections({
      ...props.selections,
      ciVal: _ciVal,
      ci: _ci,
    });
    // props.saveCi(results.criticalIllnessPlan?.planId, _ci[0], _ci[1], _ci[2]);
  }, [memberSlider, spouseSlider, dependentSlider, dependentMax]);

  /////NEXT ENABLE - Check that if member, spouse, and dependent ci option was selected, even if decline - false allows next button enable, true will disable it
  useEffect(() => {
    //if member has been selected and not declined, also check that spouse and dependent selections or declines are made
    if (checkboxes.member != 'truthy' && checkboxes.member != 'decline') {
      if (checkboxes.spouse && checkboxes.dependent) {
        props.setCiSelected(true); //important for decline all in EnrollmentPlans.js
        props.setCiSelectionMade(true);

        //save selections to db when required member, spouse and dependent selections/ changes are made
        // clear setTimeout above if it is already running from a prev save
        // setSaveTick((saveTick) => saveTick + 1);
      } else {
        props.setCiSelected(false); //important for decline all in EnrollmentPlans.js
        props.setCiSelectionMade(false);
        // clear setTimeout above if it is already running from a prev save
        // setSaveTick((saveTick) => saveTick + 1);
      }
    }

    //if member decline option is selected, do not need to check spouse and dep selections
    if (checkboxes.member == 'decline') {
      props.setCiSelected(true);
      props.setCiSelectionMade(true);
    }
  }, [
    props?.selections?.ciVal[0],
    props?.selections?.ciVal[1],
    props?.selections?.ciVal[2],
    checkboxes.member,
    checkboxes.spouse,
    checkboxes.dependent,
  ]);

  //Slider handlers

  const handleSliderComplete = (e, sliderIndex, personType) => {
    //update selection in db with new slider option only when slider is released
    let _e = e;
    switch (personType) {
      case 'member':
        _e.target.setAttribute(
          'data-amount',
          nonDeclineMemberOptions[sliderIndex]?.memberPremiumAmount
        );
        _e.target.setAttribute(
          'data-coverage',
          nonDeclineMemberOptions[sliderIndex]?.benefitAmount
        );
        _e.target.setAttribute(
          'data-optionId',
          nonDeclineMemberOptions[sliderIndex]?.memberOptionId
        );
        _e.target.setAttribute('data-person', 'member');
        // _e.target.setAttribute('name', '0');
        _e.target.name = '0';
        break;
      case 'spouse':
        _e.target.setAttribute(
          'data-amount',
          nonDeclineSpouseOptions[sliderIndex]?.memberAndSpousePremiumAmount
        );
        _e.target.setAttribute(
          'data-coverage',
          nonDeclineSpouseOptions[sliderIndex]?.benefitAmount
        );
        _e.target.setAttribute(
          'data-optionId',
          nonDeclineSpouseOptions[sliderIndex]?.memberAndSpouseOptionId
        );
        _e.target.setAttribute('data-person', 'spouse');
        // _e.target.setAttribute('name', '1');
        _e.target.name = '1';

        break;
      case 'dependent':
        _e.target.setAttribute(
          'data-amount',
          nonDeclineDependentOptions[sliderIndex]
            ?.memberAndDependentsPremiumAmount
        );
        _e.target.setAttribute(
          'data-coverage',
          nonDeclineDependentOptions[sliderIndex]?.benefitAmount
        );
        _e.target.setAttribute(
          'data-optionId',
          nonDeclineDependentOptions[sliderIndex]?.memberAndDependentsOptionId
        );
        _e.target.setAttribute('data-person', 'dependent');
        // _e.target.setAttribute('name', '2');
        _e.target.name = '2';
        break;
      default:
        break;
    }

    ciSelectionHandler(_e, true);
  };

  //MEMBER
  const memberSliderChange = (e) => {
    if (checkboxes.member != true) {
      setCheckboxes({
        ...checkboxes,
        member: true,
      });
    }
    setMemberSlider(e.target.value);
  };

  //SPOUSE
  const spouseSliderChange = (e) => {
    if (e.target.value <= spouseMaxIndex) setSpouseSlider(e.target.value);
    else setSpouseSlider(spouseMaxIndex);
  };

  //DEPENDENT
  const dependentSliderChange = (e) => {
    if (e.target.value <= dependentMaxIndex) {
      setDependentSlider(e.target.value);
    } else setDependentSlider(dependentMaxIndex);
  };

  const [expanded, setExpanded] = useState(true);
  const handleExpandSection = () => {
    setExpanded(!expanded);
  };

  // useEffect(() => {
  //     if (props.scrollClose) {
  //         setExpanded(false)
  //     }
  // },[props.scrollClose])
  // useEffect(() => {
  //     if (!expanded) {
  //         // document.getElementById('ci-calc-wrapper').scrollIntoView()
  //     }
  // }, [expanded])

  const [currRec, setCurrRec] = useState(0);
  const [currSpouseRec, setCurrSpouseRec] = useState(0);
  const [currDepRec, setCurrDepRec] = useState(false);

  let marks =
    0 < spouseMaxIndex && spouseMaxIndex != nonDeclineSpouseOptions.length - 1
      ? [
          // { value: 0, label: "$" + results?.criticalIllnessPlan.spouseOptions[0].benefitAmount / 1000 + "K" },
          {
            value: spouseMaxIndex,
            label:
              '$' +
              nonDeclineSpouseOptions[spouseMaxIndex]?.benefitAmount / 1000 +
              'K',
          },
          // { value: results?.criticalIllnessPlan.spouseOptions.length - 1, label: "$" + results?.criticalIllnessPlan.spouseOptions[results?.criticalIllnessPlan.spouseOptions.length - 1].benefitAmount / 1000 + "K" },
        ]
      : [
          // { value: 0, label: "$" + results?.criticalIllnessPlan.spouseOptions[0].benefitAmount / 1000 + "K" },
          // { value: results?.criticalIllnessPlan.spouseOptions.length - 1, label: "$" + results?.criticalIllnessPlan.spouseOptions[results?.criticalIllnessPlan.spouseOptions.length - 1].benefitAmount / 1000 + "K" },
        ];

  let depMarks =
    0 < dependentMaxIndex &&
    dependentMaxIndex != nonDeclineDependentOptions.length - 1
      ? [
          // { value: 0, label: "$" + results?.criticalIllnessPlan.spouseOptions[0].benefitAmount / 1000 + "K" },
          {
            value: dependentMaxIndex,
            label:
              '$' +
              nonDeclineDependentOptions[dependentMaxIndex]?.benefitAmount /
                1000 +
              'K',
          },
          // { value: results?.criticalIllnessPlan.spouseOptions.length - 1, label: "$" + results?.criticalIllnessPlan.spouseOptions[results?.criticalIllnessPlan.spouseOptions.length - 1].benefitAmount / 1000 + "K" },
        ]
      : [
          // { value: 0, label: "$" + results?.criticalIllnessPlan.spouseOptions[0].benefitAmount / 1000 + "K" },
          // { value: results?.criticalIllnessPlan.spouseOptions.length - 1, label: "$" + results?.criticalIllnessPlan.spouseOptions[results?.criticalIllnessPlan.spouseOptions.length - 1].benefitAmount / 1000 + "K" },
        ];

  useEffect(() => {
    let previousChoices = false;
    for (let val of props.selections.ci) {
      if (val != 0) previousChoices = true;
    }
    let recommendedSpousePosition = checkSpouseSliderAtRec();
    let recommendedMemberPosition = checkMemberSliderAtRec();
    let recommendedDepPosition = checkDependentSliderAtRec();
    if (!previousChoices) {
      //set sliders to recommended position
      setSpouseSlider(recommendedSpousePosition);
      setMemberSlider(recommendedMemberPosition);
      setDependentSlider(recommendedDepPosition);
    } else {
      //Member
      let dbMemberPosition = nonDeclineMemberOptions?.length - 1;
      let memberCheck = false;
      for (let i = 0; i < nonDeclineMemberOptions?.length; i++) {
        if (nonDeclineMemberOptions[i].isSelected) {
          dbMemberPosition = i;
          memberCheck = true;
          setMemberSlider(dbMemberPosition);
        }
      }
      //Spouse
      let dbSpousePosition = nonDeclineSpouseOptions?.length - 1;
      let spouseCheck = false;
      for (let i = 0; i < nonDeclineSpouseOptions?.length; i++) {
        if (nonDeclineSpouseOptions[i].isSelected) {
          dbSpousePosition = i;
          spouseCheck = true;
          setSpouseSlider(dbSpousePosition);
        }
      }
      //Dependent
      let dbDependentPosition = nonDeclineDependentOptions?.length - 1;
      let depCheck = false;
      for (let i = 0; i < nonDeclineDependentOptions?.length; i++) {
        if (nonDeclineDependentOptions[i].isSelected) {
          dbDependentPosition = i;
          depCheck = true;
          setDependentSlider(dbDependentPosition);
        }
      }

      //check for stored declines in db too and set checkboxes
      setCheckboxes({
        member: props?.results?.criticalIllnessPlan?.memberIsDeclined
          ? 'decline'
          : memberCheck,
        spouse: props?.results?.criticalIllnessPlan?.spouseIsDeclined
          ? 'decline'
          : spouseCheck,
        dependent: props?.results?.criticalIllnessPlan?.dependentIsDeclined
          ? 'decline'
          : depCheck,
      });
    }

    //set recommendations to display
    setCurrRec(nonDeclineMemberOptions[recommendedMemberPosition]);
    setCurrSpouseRec(nonDeclineSpouseOptions[recommendedSpousePosition]);
    setCurrDepRec(nonDeclineDependentOptions[recommendedDepPosition]);
  }, [props?.recommendations, props?.results?.criticalIllnessPlan]);

  //MEMBER rec slider position
  const checkMemberSliderAtRec = () => {
    let recommendationPosition = 0;
    if (nonDeclineMemberOptions?.length > 1) {
      recommendations.Lowest
        ? (recommendationPosition = 0)
        : //taking out the second lowest
          //!recommendations.Lowest && recommendations.ShowSpouse && recommendations.ShowDependent ? recommendationPosition = (results?.criticalIllnessPlan.memberOptions.length - 2) :
          (recommendationPosition = nonDeclineMemberOptions?.length - 1);
    }
    return recommendationPosition;
  };

  //Spouse rec slider position
  const checkSpouseSliderAtRec = () => {
    let recommendationPosition = 0;
    if (nonDeclineSpouseOptions?.length > 1) {
      recommendations.SpouseLowest
        ? (recommendationPosition = 0)
        : //taking out second lowest
          //recommendations.SpouseSecondHighest ? recommendationPosition = (results?.criticalIllnessPlan.spouseOptions.length - 2):
          (recommendationPosition = nonDeclineSpouseOptions?.length - 1);
    }
    return recommendationPosition;
  };

  //Dep rec slider position
  const checkDependentSliderAtRec = () => {
    let recommendationPosition = 0;
    if (nonDeclineDependentOptions?.length > 1) {
      recommendations.DependentLowest
        ? (recommendationPosition = 0)
        : (recommendationPosition = nonDeclineDependentOptions?.length - 1);
    }
    return recommendationPosition;
  };

  return (
    <>
      <Box sx={enrollmentPlanStyles.planContainer(expanded)}>
        <Typography
          sx={enrollmentPlanStyles.planTitle}
          onClick={handleExpandSection}
        >
          Critical Illness
        </Typography>

        <Box
          onClick={handleExpandSection}
          sx={enrollmentPlanStyles.expandAndCollapseButton}
        >
          <ExpandAndCollapseIcon isExpanded={expanded} />
        </Box>
      </Box>

      <Collapse
        sx={enrollmentPlanStyles.collapseSection}
        in={expanded}
        unmountOnExit
      >
        <Box sx={enrollmentPlanStyles.planExplanationInnerHtmlContainer}>
          <span
            className="plan-explanation-innerHTML"
            dangerouslySetInnerHTML={{ __html: ciHelptext }}
          ></span>
        </Box>
        <RadioGroup value={checkboxes.member}>
          {/* MEMBER */}
          <EnrollmentCiMember
            memberSlider={memberSlider}
            setMemberSlider={setMemberSlider}
            memberSliderChange={memberSliderChange}
            handleSliderComplete={handleSliderComplete}
            checkboxes={checkboxes}
            setCheckboxes={setCheckboxes}
            results={results}
            selections={props.selections}
            plan={criticalIllnessPlan}
            dummyDisplay={props.dummyDisplay}
            dummyFontColor={props.dummyFontColor}
            currRec={currRec}
            calculated={props.calculated}
            memberLocalDecline={memberLocalDecline}
            ciSelectionHandler={ciSelectionHandler}
            handleCiDeclineSelection={handleCiDeclineSelection}
            memberOptions={nonDeclineMemberOptions}
            declineOption={memberDecline}
            dataUHTest="MemberCITrue_radio"
          />

          {/* SPOUSE */}
          {nonDeclineSpouseOptions?.length > 0 && (
            <EnrollmentCiSpouse
              spouse={props.spouse}
              setSpouse={props.setSpouse}
              genderTypes={props?.genderTypes}
              spouseSlider={spouseSlider}
              setSpouseSlider={setSpouseSlider}
              spouseSliderChange={spouseSliderChange}
              handleSliderComplete={handleSliderComplete}
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              results={results}
              selections={props.selections}
              plan={criticalIllnessPlan}
              recommendations={recommendations}
              dummyDisplay={props.dummyDisplay}
              dummyFontColor={props.dummyFontColor}
              displayAlert={displayAlert}
              ciSelectionHandler={ciSelectionHandler}
              marks={marks}
              currSpouseRec={currSpouseRec}
              spouseMaxIndex={spouseMaxIndex}
              spouseMax={spouseMax}
              // medBreakpointHit={props?.medBreakpointHit}
              spouseLocalDecline={spouseLocalDecline}
              setSpouseLocalDecline={setSpouseLocalDecline}
              handleCiDeclineSelection={handleCiDeclineSelection}
              spouseOptions={nonDeclineSpouseOptions}
              declineOption={spouseDecline}
              dataUHTest="SpouseCITrue_radio"
            />
          )}

          {/* DEPENDENT */}
          {nonDeclineDependentOptions?.length > 0 && (
            <EnrollmentCiDependent
              checkboxes={checkboxes}
              setCheckboxes={setCheckboxes}
              results={results}
              selections={props.selections}
              plan={criticalIllnessPlan}
              recommendations={recommendations}
              currDepRec={currDepRec}
              dummyDisplay={props.dummyDisplay}
              dummyFontColor={props.dummyFontColor}
              displayAlert={displayAlert}
              ciSelectionHandler={ciSelectionHandler}
              marks={depMarks}
              expanded={expanded}
              dependentMax={dependentMax}
              dependents={props.dependents}
              setDependents={props.setDependents}
              genderTypes={props?.genderTypes}
              // medBreakpointHit={props?.medBreakpointHit}
              dependentLocalDecline={dependentLocalDecline}
              setDependentLocalDecline={setDependentLocalDecline}
              handleCiDeclineSelection={handleCiDeclineSelection}
              dependentSlider={dependentSlider}
              setDependentSlider={setDependentSlider}
              dependentSliderChange={dependentSliderChange}
              handleSliderComplete={handleSliderComplete}
              dependentMaxIndex={dependentMaxIndex}
              dependentOptions={nonDeclineDependentOptions}
              declineOption={dependentDecline}
              dataUHTest="DependentCITrue_radio"
            />
          )}
        </RadioGroup>
      </Collapse>
      {!expanded && (
        <Box sx={componentStyles.collapseSectionSummary}>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.member === true && checkboxes.member !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Member:{' '}
                  {UsdFormatter.format(
                    nonDeclineMemberOptions[memberSlider]?.benefitAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.ciVal[0])}
                </Box>
              </>
            )}
            {(!checkboxes.member ||
              checkboxes.member === 'truthy' ||
              checkboxes.member === 'decline') && (
              <>
                <Typography>
                  Member: <em>not elected</em>
                </Typography>
                <Typography style={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.spouse && checkboxes.spouse !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Spouse:{' '}
                  {UsdFormatter.format(
                    nonDeclineSpouseOptions[spouseSlider]?.benefitAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.ciVal[1])}
                </Box>
              </>
            )}
            {(!checkboxes.spouse || checkboxes.spouse === 'decline') && (
              <>
                <Typography>
                  Spouse: <em>not elected</em>
                </Typography>
                <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
          <Box sx={componentStyles.selectionSummaryPoints}>
            {checkboxes.dependent && checkboxes.dependent !== 'decline' && (
              <>
                <Box>
                  <RadioButtonCheckedIcon
                    sx={enrollmentPlanStyles.radioButtionIconCollapse}
                  />
                  Dependent(s):{' '}
                  {UsdFormatter.format(
                    nonDeclineDependentOptions[dependentSlider]?.benefitAmount
                  )}
                </Box>

                <Box sx={enrollmentPlanStyles.electedOptionCollapsePlusSymbol}>
                  + {UsdFormatterDec.format(props.selections.ciVal[2])}
                </Box>
              </>
            )}
            {(!checkboxes.dependent || checkboxes.dependent === 'decline') && (
              <>
                <Typography>
                  Dependent(s): <em>not elected</em>
                </Typography>
                <Typography sx={enrollmentPlanStyles.notElectedZeroAmount}>
                  $0.00
                </Typography>
              </>
            )}
          </Box>
        </Box>
      )}

      <Divider sx={enrollmentPlanStyles.endSectionDivider} />
    </>
  );
}
