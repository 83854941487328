import axios from 'axios';
import Settings from '../settings';
import { baseService } from './baseService';

class externalService extends baseService {
  sendContactEmail(contactData) {
    const url = Settings.API_URL + 'external/sendcontactemail';
    const emailData = {
      FirstName: contactData.firstName,
      LastName: contactData.lastName,
      EmailAddress: contactData.email,
      Message: contactData.message,
      PhoneNumber: contactData.phone,
      Reason: contactData.reason,
    };
    console.log(emailData);
    return this.post(url, emailData);
  }

  sendPayload(payload) {
    const url = Settings.API_URL + 'claims/sendclaim';
    return this.post(url, payload);
  }
}

const externalServices = new externalService();

export default externalServices;
