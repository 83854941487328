import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

//Assets

//Muicons

//MuiComponents
import PortalPlanSummary from './PortalPlanSummary';

//Our services

export default function PortalPlanContainer(props) {
  const [planList, setPlanList] = useState([]);
  const buildOrderedPlanList = () => {
    let results = props.enrollment;
    //Build array of nodes with sort order, then map over to display
    let unorderedList = [
      // {plan: ReactNode, order: int}
    ];
    if (results?.membershipPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Union Membership"
            key={results.membershipPlan.planId}
            plan={results.membershipPlan}
            planId={results.membershipPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.membershipPlan.sortOrder,
        planId: results.membershipPlan.planId,
      });
    }
    if (results?.stdPlan && !results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Short-Term Disability"
            key={results.stdPlan.planId}
            plan={results.stdPlan}
            planId={results.stdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.stdPlan && results.stdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Short-Term Disability"
            key={results.stdPlan.planId}
            plan={results.stdPlan}
            planId={results.stdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.stdPlan.sortOrder,
        planId: results.stdPlan.planId,
      });
    }
    if (results?.ltdPlan && !results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Long-Term Disability"
            key={results.ltdPlan.planId}
            plan={results.ltdPlan}
            planId={results.ltdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.ltdPlan && results.ltdPlan?.isIncremental) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Long-Term Disability"
            key={results.ltdPlan.planId}
            plan={results.ltdPlan}
            planId={results.ltdPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.ltdPlan.sortOrder,
        planId: results.ltdPlan.planId,
      });
    }
    if (results?.accidentPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Accident"
            key={results.accidentPlan.planId}
            plan={results.accidentPlan}
            planId={results.accidentPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.accidentPlan.sortOrder,
        planId: results.accidentPlan.planId,
      });
    }
    if (results?.lifeADDPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Life and AD&D Insurance"
            key={results.lifeADDPlan.planId}
            plan={results.lifeADDPlan}
            planId={results.lifeADDPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.lifeADDPlan.sortOrder,
        planId: results.lifeADDPlan.planId,
      });
    }
    if (results?.cancerPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Cancer"
            key={results.cancerPlan.planId}
            plan={results.cancerPlan}
            planId={results.cancerPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.cancerPlan.sortOrder,
        planId: results.cancerPlan.planId,
      });
    }
    if (results?.addPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="AD&D"
            key={results.addPlan.planId}
            plan={results.addPlan}
            planId={results.addPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.addPlan.sortOrder,
        planId: results.addPlan.planId,
      });
    }

    if (results?.hospitalPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Hospital Indemnity"
            key={results.hospitalPlan.planId}
            plan={results.hospitalPlan}
            planId={results.hospitalPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.hospitalPlan.sortOrder,
        planId: results.hospitalPlan.planId,
      });
    }
    if (results?.criticalIllnessPlan) {
      unorderedList.push({
        plan: (
          <PortalPlanSummary
            groupPlan={results}
            title="Critical Illness"
            key={results.criticalIllnessPlan.planId}
            plan={results.criticalIllnessPlan}
            planId={results.criticalIllnessPlan.planId}
            expanded={expanded}
            setExpanded={setExpanded}
          />
        ),
        order: results.criticalIllnessPlan.sortOrder,
        planId: results.criticalIllnessPlan.planId,
      });
    }

    let orderedList = unorderedList.sort((a, b) => a.order - b.order);
    setPlanList(orderedList);
  };
  useEffect(() => {
    if (props.enrollment) buildOrderedPlanList();
  }, [props.enrollment]);
  const [expanded, setExpanded] = useState({});

  return <>{planList.map((planObj) => planObj.plan)}</>;
}
