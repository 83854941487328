import React, { useState, useEffect } from 'react';

//services
// import enrollmentService from '../../../../../../../../services/enrollment-service';
import activeCoverageService from '../../../../../../../../services/activeCoverage-service';

import {
  Drawer,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Button,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
//utils
import {
  formatUTCDateToObjWithTimeZone,
  formatDateObjMMDDYYYYDisplay,
  formatUTCToShortDate,
  formatCurrency as utilsFormatCurrency,
  formatUTCToMonthDay,
} from '../../../../../../../utils';

const styles = {
  container: {
    backgroundColor: '#FEF6E7',
    border: '1px solid #F9DBAF',
    padding: '10px',
    width: '500px',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  title: {
    fontWeight: 'bold',
    color: 'rgba(148, 67, 1, 0.80)',
    fontSize: '16px',
    marginLeft: 1,
    paddingTop: '5px',
  },
  message: {
    color: 'rgba(148, 67, 1, 0.80)',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    letterSpacing: '0.17px',
    maxWidth: '400px',
    wordBreak: 'break-word',
    marginLeft: 6,
  },
  details: {
    color: 'rgba(148, 67, 1, 0.80)',
    marginLeft: 6,
  },
  button: {
    backgroundColor: 'transparent',
    color: 'rgba(148, 67, 1, 0.80)',
    textTransform: 'uppercase',
    fontSize: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.46px',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  iconButton: {
    padding: 0,
    color: 'rgba(148, 67, 1, 0.80)',
  },
  warningHeaderContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  warningHeaderInnerContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  changesMade: {
    fontWeight: 'medium',
    fontSize: '14px',
  },
  changeDescription: {
    marginLeft: 2,
    fontSize: '14px',
  },
  boldText: {
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

//The current implementation assumes the drawer focuses on one line of coverage at a time.

// Custom hook for fetching coverage data from active coverage service API this is a custom hook that fetches the active coverage data for a member and filters it by planId.
// It also processes the data to add additional properties like hasChanges, isClaimInProgress, claimId, isCancelled, etc.
export const useActiveCoverage = (memberId, planId) => {
  const [coverage, setCoverage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCoverage = async () => {
      if (!memberId) {
        console.warn('No memberId provided');
        return;
      }

      try {
        const response =
          await activeCoverageService.getActiveCoveragebyMemberId(memberId);
        const data = Array.isArray(response) ? response : [response];

        // Filter by planId
        const processedCoverage = data
          .filter((item) => item.planId === planId)
          .map((item) => ({
            ...item,
            hasChanges:
              Boolean(item.isModified) ||
              Boolean(item.isCancelled) ||
              (item.futurePremiumAmount || 0) !== 0 ||
              (item.futureBenefitAmount || 0) !== 0 ||
              (item.futureBeginDate &&
                item.futureBeginDate !== '0001-01-01T00:00:00+00:00' &&
                item.futureBeginDate !== item.effectiveStartDate),
            isClaimInProgress: Boolean(item.isClaimInProgress),
            claimId: item.claimId || null,
            isCancelled: Boolean(item.isCancelled),
          }));

        setCoverage(processedCoverage);
      } catch (err) {
        console.error('Error fetching coverage data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCoverage();
  }, [memberId, planId]);

  return { coverage, loading, error };
};

// Constants for warning types
export const WARNING_TYPES = {
  CLAIM: 'claim',
  PENDING: 'pending',
  MODIFICATION: 'modification',
  CANCELLATION: 'cancellation',
};

// Helper functions
const formatCurrency = (amount) =>
  !amount || isNaN(amount) ? '$0.00' : utilsFormatCurrency(amount);

const formatBenefit = (amount, isPercentage = false) =>
  !amount || isNaN(amount)
    ? ''
    : isPercentage
    ? `${Number(amount).toFixed(0)}% Monthly Benefit`
    : `$${Number(amount).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })} Monthly Benefit`;

// wanting to dig a bit more into using utils but this was very specific to the format needed for the date
const formatDate = (dateString) => {
  return !dateString ? 'a future date' : formatUTCToMonthDay(dateString);
};

// very specific format for month/day above shows as Jan 1st needs to show as 10/1 - figma
const formatMonthDay = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date)) return 'a future date';
  const month = date.getUTCMonth() + 1; // months are zero-indexed
  const day = date.getUTCDate();
  return `${month}/${day}`;
};

// Warning messages configuration
export const warningMessages = {
  [WARNING_TYPES.CLAIM]: (data) => ({
    title: `${data?.displayName || 'Coverage'} Claim in Progress`,
    message:
      'This line of coverage is currently under claim. The member will not be charged during this time. This plan cannot be changed or canceled.',
    buttonLabel: 'Go to Claim',
    iconColor: 'rgba(230, 81, 0, 1)',
    link: data?.claimLink,
  }),
  [WARNING_TYPES.PENDING]: (data) => ({
    title: 'Coverage Changes Pending',
    message: `Changes in coverage have been scheduled to go into effect on ${formatMonthDay(
      data?.futureBeginDate
    )}. The new premium amount will be ${formatCurrency(
      data?.futurePremiumAmount
    )}/mo.`,
    details: (
      <Box>
        <Typography variant="body2" sx={styles.changesMade}>
          Changes made:
        </Typography>
        <Typography variant="body2" sx={styles.changeDescription}>
          • Benefit Amount{' '}
          {data.futureBenefitAmount > data.benefitAmount
            ? 'increased'
            : 'decreased'}{' '}
          from{' '}
          <Typography component="span" sx={styles.boldText}>
            {data.desc}
          </Typography>{' '}
          to{' '}
          <Typography component="span" sx={styles.boldText}>
            {formatBenefit(data.futureBenefitAmount)}
          </Typography>
          .
        </Typography>
      </Box>
    ),
    iconColor: 'rgba(148, 67, 1, 0.80)',
  }),
  [WARNING_TYPES.CANCELLATION]: (data) => ({
    title: `${data?.displayName || 'Coverage'} Changes Pending`,
    message: `Changes in coverage have been scheduled to go into effect on ${formatMonthDay(
      data?.futureBeginDate
    )}. The new premium amount will be ${formatCurrency(
      data?.futurePremiumAmount
    )}/mo.`,
    details: (
      <Box>
        <Typography variant="body2" sx={styles.changesMade}>
          Changes made:
        </Typography>
        <Typography
          variant="body2"
          sx={{ ...styles.boldText, ...styles.changeDescription }}
        >
          • CANCELLATION
        </Typography>
      </Box>
    ),
    iconColor: 'rgba(148, 67, 1, 0.80)',
  }),
};

// Warning Component
export const WarningComponent = ({
  title,
  message,
  details,
  buttonLabel,
  onButtonClick,
  handleMenuClick,
  iconColor,
}) => (
  <Box sx={styles.container}>
    <Box sx={styles.warningHeaderContainer}>
      <Box sx={styles.warningHeaderInnerContainer}>
        <InfoOutlinedIcon sx={{ color: iconColor, marginLeft: 1 }} />
        <Typography sx={styles.title}>{title}</Typography>
      </Box>
      {buttonLabel ? (
        <Button onClick={onButtonClick} sx={styles.button}>
          {buttonLabel}
        </Button>
      ) : (
        <IconButton onClick={handleMenuClick} sx={styles.iconButton}>
          <MoreVertIcon />
        </IconButton>
      )}
    </Box>
    <Typography sx={styles.message}>{message}</Typography>
    {details && <Typography sx={styles.details}>{details}</Typography>}
  </Box>
);

// Pending Changes Warning Component
export const PendingChangesWarning = ({
  memberId,
  planId,
  initialWarningType = WARNING_TYPES.CLAIM,
  // onClaimClick, a future implementation
  onModifyClick,
  onCancelChanges,
}) => {
  const { coverage, loading, error } = useActiveCoverage(memberId, planId);
  const [warningType, setWarningType] = useState(initialWarningType);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  useEffect(() => {
    if (!coverage || loading || error) return;

    console.log('📊 Coverage data:', coverage);

    const hasChanges = coverage.some((item) => item.hasChanges);
    if (!hasChanges) {
      setWarningType(null);
      return;
    }

    const newWarningType = coverage.some((item) => item.isClaimInProgress)
      ? WARNING_TYPES.CLAIM
      : coverage.some((item) => item.isCancelled)
      ? WARNING_TYPES.CANCELLATION
      : WARNING_TYPES.PENDING;

    setWarningType(newWarningType);
    console.log('⚠️ Warning type:', newWarningType);
  }, [coverage, loading, error]);

  if (!coverage || !warningType) return null;

  const handleMenuClick = (event) => setMenuAnchorEl(event.currentTarget);
  const handleMenuClose = () => setMenuAnchorEl(null);

  const warningData = coverage.find(
    (item) =>
      item.isCancelled ||
      (item.futureBeginDate &&
        item.futureBeginDate !== '0001-01-01T00:00:00+00:00' &&
        new Date(item.futureBeginDate) > new Date())
  );

  const currentMessage =
    warningType &&
    warningData &&
    typeof warningMessages[warningType] === 'function'
      ? warningMessages[warningType](warningData)
      : null;

  if (!currentMessage) return null;

  // go to claim button functionality -- need to test more with claims in progress
  const onClaimClick = () => {
    if (currentMessage.claimLink) {
      window.location.href = currentMessage.claimLink;
    }
  };

  return (
    <>
      <WarningComponent
        title={currentMessage.title}
        message={currentMessage.message}
        details={currentMessage.details}
        buttonLabel={currentMessage.buttonLabel}
        iconColor={currentMessage.iconColor}
        onButtonClick={
          warningType === WARNING_TYPES.CLAIM ? onClaimClick : undefined
        }
        handleMenuClick={handleMenuClick}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
      >
        {/* this is a future implementation */}
        <MenuItem onClick={onModifyClick}>Modify Changes</MenuItem>
        <MenuItem onClick={onCancelChanges}>Cancel Changes</MenuItem>
      </Menu>
    </>
  );
};

export default PendingChangesWarning;
